import React, { useState, useEffect } from "react";
import axios from "axios";
import configuration from "../Configuration";
import "../assets/styles/style.css";
import { formatDate } from "../utils/date";
import { formatearRut, formatearRutCeros } from "../utils/validationRut";
import FormaPagoHelper from "../utils/formaPago.Helper";
import cuentaBancariaHelper from "../utils/cuentaBancaria.Helper";
import tipoCuentaUsoHelper from "../utils/tipoCuentaUso.Helper";
import Alert from "../utils/alert";
import { oacento } from "../utils/caracteresUTF8";

const FormComprobante = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [datos, setDatos] = useState({});
	const [isInTramite, setIsInTramite] = useState(false);
	const [datosCuenta, setdatosCuenta] = useState([]);
	const [instituciones, setinstituciones] = useState({});

	const Persona = {
		idPersona: localStorage.getItem("idPersona"),
	};

	useEffect(() => {
		axios
			.get(`${configuration.API_URL}/Bancos/traerBancos`, {
				headers: configuration.JSON_HEADER,
			})
			.then((response) => {
				let data = response.data;
				if (response.status === 200) {
					if (data.ok) {
						var array = new Array(2);
						data.resultado.map(
							(datos, i) =>
								(array[i] = {
									codigo: datos.codBanco,
									descripcion: datos.descripcion,
								})
						);
						setinstituciones(array);
					}
				}
			})
			.catch((e) => {
				console.error(e);
			});
		const ObtenerDatos = async () => {
			setIsLoading(true);
			axios({
				method: "GET",
				url: `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${Persona.idPersona}`,
				headers: configuration.JSON_HEADER,
			})
				.then((response) => {
					let resultado = response.data.resultado;

					setDatos({
						numero: `${resultado.folioSolicitud}${resultado.correlAfpFecha}`,
						fecha: formatDate(resultado.createDate),
						nombreAfiliado: `${resultado.nombreAfi} ${resultado.apPaternoAfi} ${resultado.apMaternoAfi}`,
						rutAfiliado: formatearRut(formatearRutCeros(resultado.idPersona)),
						nombreMandatario: resultado.idMandatario
							? `${resultado.nombreMan} ${resultado.apPaternoMan} ${resultado.apMaternoMan}`
							: "",
						rutMandatario: resultado.idMandatario
							? formatearRut(formatearRutCeros(resultado.idMandatario))
							: "",
						formaPago: resultado.formaPago,
						idCliente: resultado.idCliente,
						numeroCuenta: resultado.numeroCuenta,
						iniciaMandatario: resultado.iniciaMandatario,
						folioSolicitud: resultado.folioSolicitud,
					});
					ObtenerDatosCuenta(resultado.idCliente);
					setIsLoading(false);
				})
				.catch((e) => {
					console.error(e);
					setIsLoading(false);
				});
		};

		const ObtenerDatosCuenta = async (idCliente) => {
			setIsLoading(true);
			await axios
				.get(
					`${configuration.API_URL}/FormaPago/ObtenerFormasPagoList` +
						"?idCliente=" +
						idCliente,
					{
						headers: configuration.JSON_HEADER,
					}
				)
				.then((response) => {
					let resultado = response.data.resultado;
					let array = [];

					if (response.data.ok) {
						resultado.map(
							(cuenta, i) =>
								(array[i] = {
									formaPago: FormaPagoHelper.getFormaPago(cuenta.tipoViaPago),
									tipoCuenta: cuenta.tipoCuentaDeposito,
									numeroCuenta: cuenta.numCuentaDeposito,
									tipoCuentaUso: cuenta.tipoCuentaUso,
									Banco: cuenta.codInstFinancDeposito,
								})
						);
						setdatosCuenta(array);
					}

					setIsLoading(false);
				})
				.catch((e) => {
					console.error(e);
					setIsLoading(false);
				});
		};
		const ObtenerTieneTramite = async () => {
			setIsInTramite(false);
		};

		ObtenerDatos();
		ObtenerTieneTramite();
	}, []);

	return (
		<form>
			<div className="form-group">
				<strong htmlFor="">Número de solicitud</strong>
				<br />
				{datos.numero}
			</div>
			<div className="form-group">
				<strong htmlFor="">Fecha de solicitud</strong>
				<br />
				{datos.fecha}
			</div>
			<div className="form-group">
				<strong htmlFor="">Nombre Afiliado o Pensionado</strong>
				<br />
				{datos.nombreAfiliado}
			</div>
			<div className="form-group">
				<strong htmlFor="">RUT</strong>
				<br />
				{datos.rutAfiliado}
			</div>
			{datos.iniciaMandatario ? (
				<>
					<div className="form-group">
						<strong htmlFor="">Nombre Mandatario</strong>
						<br /> {datos.nombreMandatario}
					</div>
					<div className="form-group">
						<strong htmlFor="">RUT</strong>
						<br />
						{datos.rutMandatario}
					</div>
				</>
			) : (
				<span></span>
			)}
			{datosCuenta.map((cuenta, index) => (
				<div key={index}>
					<div className="form-group">
						<strong htmlFor="">
							{tipoCuentaUsoHelper.getTipoCuenta(cuenta.tipoCuentaUso)}
						</strong>
						<br />
					</div>
					<div className="form-group">
						<strong htmlFor="">Forma de Pago</strong>
						<br />
						{cuenta.formaPago === undefined ||
						cuenta.formaPago === "Deposito Bancario"
							? `dep${oacento}sito Bancario: ` +
							  instituciones.find((x) => x.codigo === cuenta.Banco).descripcion
							: cuenta.formaPago}
					</div>

					{cuenta.formaPago === "Deposito Bancario" ? (
						<>
							<div className="form-group">
								<strong htmlFor="">Tipo de cuenta</strong>
								<br />
								{cuentaBancariaHelper.getCuenta(cuenta.tipoCuenta)}
							</div>
							<div className="form-group">
								<strong htmlFor="">Número de cuenta</strong>
								<br />
								{cuenta.numeroCuenta}
							</div>
						</>
					) : (
						<span></span>
					)}
				</div>
			))}
			<div className="form-group">
				<Alert
					image="success"
					className="alert-custom-dark"
					message="Tu solicitud ya ha sido ingresada. Te enviaremos una copia con tu número de solicitud a tu correo electrónico."
				/>
			</div>
			<div className="form-group">
				{isInTramite ? (
					<>
						<Alert
							image="warning"
							className="alert-secondary"
							message="Según nuestros registros mantienes una solicitud de trámite de pensión de vejez en curso, por lo que la aprobación de tu solicitud de retiro de ahorros por enfermedad terminal, dará término al trámite de pensión anterior."
						/>
					</>
				) : (
					<span></span>
				)}
			</div>
		</form>
	);
};

export default FormComprobante;
