import { genericGet } from "../../../utils/genericApiCall";

export async function obtenerComunas() {
    const url_endpoint = '/Comuna/ObtenerTodo';
    const message_info = 'Obteniendo Regiones, Ciudad y Comunas...';
    const message_error = 'Error al obtener datos de region, ciudad y comunas...';

    return await genericGet({
        url_endpoint: url_endpoint, 
        message_info: message_info, 
        message_error: message_error
    });

}
