import React, { useState } from "react";
import Helmet from "react-helmet";
import PaetModal from "../components/PaetModal";
import Alert from "../utils/alert";
import Swal from "sweetalert2";
import { object } from "yup";

export default function Paet() {
	const [modalShow, setModalShow] = useState(false);
	const handleShow = () => setModalShow(true);
	const handleClose = () => setModalShow(false);

	return (
		<div className="container home">
			<Helmet>
				<title>
					Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos
					Terminales | AFP Modelo
				</title>
				<meta name="description" content={``} />
				<meta name="robots" content="noindex, follow" />
			</Helmet>
			<section>
				<div className="textoIzquierda textoTitulo-white">
					Retiro de fondos con AFP Modelo
				</div>
				<div className="textoIzquierda textoSecundario-white">
					INFÓRMATE Y HAZ EL RETIRO DE TUS FONDOS
				</div>
				<div className="row w-100 container-padre mt-5">
					<div className="bloque form">
						<div className="card formulario">
							<div className="paet-head">
								Retiro de Pensión Anticipada por Enfermedad Terminal (PAET)
							</div>
							<div className="paet-body">
								<span className="textoSecundario mb-2 p-0">
									Instrucciones de ingreso de solicitud:
								</span>
								<br />
								<br />
								<span className="mb-2">
									Antes de ingresar su solicitud, usted o su representante, debe
									obtener de parte de su médico tratante y del establecimiento
									de salud público o privado que corresponda, un certificado
									médico que acredite su condición de salud, el que deberá
									adjuntarse a la solicitud. Su médico tratante deberá llenar
									directamente el certificado en el siguiente link:
									<a href="https://certificadoset.portalafp.cl/">
										{" "}
										Sistema para Certificados de Enfermos Terminales
									</a>
								</span>
								<br />
								<span className="mb-2">
									(Para conocer un ejemplo del certificado médico{" "}
									<a
										target="_blank"
										href="https://modelo.blob.core.windows.net/retiros10porciento/Certificado_Medico.pdf"
									>
										Haz click aquí
									</a>
									)
								</span>
								<br />
								<br />
								<span className="mb-2">
									Su médico tratante, deberá enviarlo al establecimiento de
									salud público o privado que corresponda, donde haya recibido
									atenciones respecto de su enfermedad, para completar los datos
									correspondientes, firmar y timbrar por el director médico o su
									equivalente, el que deberá entregárselo por los medios que a
									usted le resulten más convenientes.
								</span>
								<br />
								<br />
								<span>
									El certificado, debidamente llenado, firmado y timbrado,
									deberá adjuntarse a la solicitud que presente usted o su
									representante, por esta vía o por cualquiera de los canales de
									servicio de la AFP. Además, deberá adjuntar la autorización
									para que el Consejo Médico pueda acceder a la revisión de la
									ficha clínica (Disponible para su llenado y envío en este
									link:
									<a
										target="_blank"
										href="https://modelo.blob.core.windows.net/retiros10porciento/Autorizacion_Consejo_Medico.pdf"
									>
										descargar autorización para revisión de ficha por consejo
										médico
									</a>
									).Adicionalmente, se le recomienda adjuntar la mayor cantidad
									de antecedentes médicos sobre su condición de salud principal.
								</span>
							</div>
							<div className="paet-foot">
								<div className=" justify-content-center d-flex">
									<button
										type="submit"
										className="btn-custom btn-lg btn-custom-primary"
										onClick={handleShow}
									>
										Solicitar pensión PAET{" "}
									</button>
								</div>
								<div className="justify-content-center d-flex">
									<button
										className="btn-custom btn-lg btn-custom-default d-none"
										type="button"
										onClick={function () {
											window.location = "/consultar";
										}}
									>
										Ver estado solicitud
									</button>
								</div>
								<div>
									<Alert
										image="warning"
										className="alert-secondary"
										message={[
											"Este portal es ",
											<strong key="s">
												exclusivo para los Afiliados, Pensionados AFP Modelo.
											</strong>,
											" Si no sabes a qué AFP estás afiliado, ",
											<a
												key="a"
												href="https://www.spensiones.cl/apps/certificados/formConsultaAfiliacion.php"
												target="blank_"
												className="green-a"
											>
												haz click aquí
											</a>,
											".",
										]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PaetModal show={modalShow} onHide={handleClose} />
			</section>
		</div>
	);
}
