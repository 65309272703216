import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import MaskedInput from "react-text-mask"
import { aacento, oacento, uacento } from "../utils/caracteresUTF8"
import { handleLoginSolicitud } from "../utils/handleSubmit/formLoginSolicitud"
import { handleSubmitFormNumeroSerie } from "../utils/handleSubmit/formNumeroSerie"
import { initialValuesFormNumeroSerie } from "../utils/initialValues/formNumeroSerie"
import { rutMask } from "../utils/inputMask"
import {  validarFormSolicitud, validarRutEjecutivo  } from "../utils/validationSchemaForm"
import ButtonLoading from "./commons/buttonLoading";
import ButtonSubmit from "./commons/buttonSubmit";
import { useHistory } from "react-router-dom";
export const FormSolicitud = () => {
  
    const history = useHistory()

    const initial = {
        rut:"",
        solicitud:"", 
    }

    const handleSubmitFormNumeroSerie = (field) => {
        handleLoginSolicitud({
            rut:field.rut,
            solicitud:field.solicitud,
            history:history
        })
    }
    return(
        <Formik 
        initialValues={initial} 
        validationSchema={validarFormSolicitud} 
        onSubmit={handleSubmitFormNumeroSerie}>
        {(formik) => (
            <Form>
                <span>RUT del afiliado, pensionado o beneficiario de pensi{oacento}n de sobrevivencia</span>
                <Field name="rut">
                    {({ field, meta }) => (
                        <div className="form-group input-wrapper">
                            <MaskedInput
                                {...field}
                                mask={rutMask}
                                type="text"
                                className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                name="rut"
                                aria-describedby="rutAyuda"
                                placeholder=""
                                autoComplete="off" />
                            <label htmlFor="rut" className="control-label">
                                RUT
                            </label>
                            <small id="rutAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                {meta.touched && meta.error ? meta.error : "Ej. 12.345.678-9."}
                            </small>
                        </div>
                    )}
                </Field>
               
                <span>N{uacento}mero de solicitud o tr{aacento}mite</span>
                
                <Field name="solicitud">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <input
                                    {...field}
                                    type="text"
                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                    name="solicitud"
                                    aria-describedby="solicitudAyuda"
                                    placeholder=""
                                    autoComplete="off"/>
                                <label htmlFor="solicitud" className="control-label">
                                    Numero de Serie
                                </label>
                                <small id="solicitudAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                    {meta.touched && meta.error ? meta.error : "El número de tu solicitud. Ej: 1711034202103."}
                                </small>
                            </div>
                        )}
                    </Field>

                <div className="justify-content-center d-flex mt-4">
                    <button disabled={!(formik.isValid && formik.dirty)}  className="btn-custom btn-lg btn-custom-primary" type="submit"> Continuar </button>
                </div>
            </Form>
        )}
    </Formik>
    )
}