import React from "react";
import Helmet from "react-helmet";
import FormComprobante from "../../components/eld/FormComprobante";
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

export default function comprobante() {
    function printDocument() {

        const input = document.getElementById('pdfdiv');
        const pdf = new jsPDF();
        if (pdf) {
            domtoimage.toPng(input).then(imgData => {
                pdf.addImage(imgData, 'PNG', 10, 10);
                pdf.save('download.pdf');
            });
        }
    }
    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div id="pdfdiv" className="">
                                        <div className="card-title textoTitulo">
                                            Formulario Selección Modalidad con Excedente de Libre disposición (ELD)
                                        </div>
                                        <span className="text-green">
                                            ¡Tu selección ha sido ingresada!
                                        </span>
                                        <div className="card-body">
                                            <FormComprobante />                                            
                                        </div>
                                    </div>
                                    <div className="comprobante-botones">
                                    <div className="">
                                        <div className="col justify-content-center d-flex">                                           
                                            <button
                                                type="button"
                                                className="btn-custom btn-lg btn-custom-primary"
                                                onClick={printDocument}>
                                                Descargar comprobante
                                            </button>
                                        </div>
                                    </div>    
                                        <div className="justify-content-center d-flex">
                                            <button
                                                className="btn-custom btn-lg btn-custom-default"
                                                type="button"
                                                onClick={()=>{ window.location.href = '/eld'; }}>
                                                    Volver al inicio
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}