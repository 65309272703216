import React from "react";
import { Helmet } from "react-helmet";
import { Formik, Form, Field } from "formik";

import { aacento, eacento, iacento, oacento, uacento, enhe, interrogacion } from '../utils/caracteresUTF8';

export default function Demo() {

    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">

                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title h5">Formulario de Retiro de
                                        Pensión Anticipada por
                                        Enfermedad Terminal</div>

                                    <span >Comienza tu solicitud aquí.</span>

                                    <div className="card-body">
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}