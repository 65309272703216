import React from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "./assets/styles/globals.css";
import "./assets/styles/index.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Index from "./pages/index";
import Demo from "./pages/demo";
import Mandatario from "./pages/mandatario";
import Resumen from "./pages/resumen";
import Comprobante from "./pages/comprobante";
import ClaveSeguridad from "./pages/claveSeguridad";
import DatosAfiliados from "./pages/datosAfiliados";
import TagManager from "react-gtm-module";
import DatosBeneficiarios from "./pages/datosBeneficiarios";
import FormaPago from "./pages/formaPago";
import FormaPagoAlternativa from "./pages/formaPagoAlternativa";
import DatosCuentas from "./pages/datosCuentas";
import Landing from "./pages/landing";
import Paet from "./pages/paet";
import Consultar from "./pages/consultar";
import ComprobanteEstadoSolicitud from './pages/comprobanteEstadoSolicitud';
import IndexELD from "./pages/eld/index";
import ModalidadPagoELD from "./pages/eld/modalidadPago";
import ComprobanteELD from "./pages/eld/comprobante";
import ResumenLD from "./pages/eld/resumen";
import Documentos from "./pages/documentos";
import ScrollToTop from "./utils/useRouterScroll";
import { Login } from "./pages/doc/login";
import FormaPagodos from "./pages/doc/newDocumentos"
import ComprobanteV2 from "./pages/doc/comprobantev2";
import comprobante from "./pages/doc/comprobante";
const tagManagerArgs = {
    gtmId: 'GTM-TBL58B3' 
}

TagManager.initialize(tagManagerArgs)

const App = function () {
    
    return (
        <>
            <Header />
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path='/' exact component ={Paet} />
                    <Route path='/paet' exact component ={Paet} />
                    <Route path='/index' exact component ={Index} />
                    <Route path='/demo' exact component ={Demo} />
                    <Route path='/consultar' exact component ={Consultar} />                    
                    <Route path='/mandatario' exact component={Mandatario} />
                    <Route path='/resumen' exact component={Resumen} />
                    <Route path='/claveSeguridad' exact component={ClaveSeguridad} />
                    <Route path='/datosAfiliados' exact component={DatosAfiliados} />
                    <Route path='/documentos' exact component={Documentos} />
                    <Route path='/beneficiarios' exact component={DatosBeneficiarios} />
                    <Route path='/cuentas' exact component={DatosCuentas} />
                    <Route path='/comprobante' exact component={Comprobante} />
                    <Route path='/formaPago' exact component={FormaPago} />
                    <Route path='/formaPagoAlternativa' exact component={FormaPagoAlternativa} />
                    <Route path='/comprobanteEstadoSolicitud' exact component={ComprobanteEstadoSolicitud} />
                    <Route path='/eld/' exact component={IndexELD} />
                    <Route path='/eld/modalidadPago' exact component={ModalidadPagoELD} />
                    <Route path='/eld/comprobante' exact component={ComprobanteELD} />
                    <Route path='/eld/resumen' exact component={ResumenLD} />
                    <Route path='/doc/login' exact component={Login} />
                    <Route path='/doc/documentos' exact component={FormaPagodos} />
                    <Route path='/doc/resumen' exact component={ComprobanteV2} />
                    <Route path='/doc/comprobante' exact component={comprobante} />
                </Switch>
            </Router>
            <Footer />
        </>
    );
}

export default App;