import React from "react";
import { Modal, Button } from "react-bootstrap";

const  BeneficiarioModal =(props) => {
    return (
        <div>
            <Modal
                {...props}
                dialogClassName="modal-50w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ¿Quiénes son beneficiarios?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><span className="font-weight-bold">Cónyuge sobreviviente:</span> registrar matrimonio con el afiliado a lo menos seis meses antes de su fallecimiento; si estaba pensionado, deben ser al menos tres años. Estos requisitos no aplican si la cónyuge se encontraba embarazada o si quedaran hijos comunes.</p>
                    <p><span className="font-weight-bold">Los hijos solteros deben cumplir uno de los siguientes requisitos:</span></p>
                    <p>- Menores de 18 años.</p>
                    <p>- Mayores de 18 años y menores de 24, en calidad de estudiantes de cursos regulares de enseñanza básica, media o superior.La calidad de estudiante debe existir antes de la fecha de fallecimiento del causante o adquirida antes de los 24 años de edad.</p >
                    <p>- Ser declarado inválido por una Comisión Médica, cualquiera sea su edad antes de cumplir 18 o 24 años de edad, según corresponda.</p>
                <p><span className="font-weight-bold">Padre de hijos de filiación no matrimonial:</span> a la fecha del fallecimiento deben ser solteros o viudos y vivir a expensas del afiliado.</p>
                    <p><span className="font-weight-bold">Padres del afiliado:</span> deben ser cargar familiar reconocidas y solo serán beneficiarios a falta de todas las personas indicadas en los números anteriores.</p>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BeneficiarioModal;