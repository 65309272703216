import React from "react";
import "../assets/styles/footer.css";
import phone from "../assets/svg/phone.svg";

const Footer = () => {
	return (
		<footer>
			<div className="footer container">
				<div id="logo" />
				<span className="mensaje">Estamos para ayudarte</span>
				<div id="links">
					<div className="unlink">
						<span id="iconley" className="imagenlink"></span>
						<span className="etiquetalink">
							<a
								href="https://modelo.blob.core.windows.net/satelite/Ley-21309_01-FEB-2021.pdf"
								target="blank_"
							>
								Ley N°21.309
							</a>
						</span>
					</div>
					<div className="unlink">
						<span id="iconpreguntas" className="imagenlink"></span>
						<span className="etiquetalink">
							<a href="http://www.afpmodelo.cl/AFP/retiro/retiro-anticipado.aspx">
								Preguntas Frecuentes
							</a>
						</span>
					</div>
					<div className="unlink">
						<span id="iconcontactenos" className="imagenlink"></span>
						<span className="etiquetalink">
							<a href="http://www.afpmodelo.cl/AFP/Canales-de-atencion/Contactanos.aspx">
								Contáctanos
							</a>{" "}
						</span>
					</div>
				</div>
				<div className="box">
					<div className="row phoneParent">
						<a className="col-12 text-left" href="tel:800025001">
							<span className="fonoconsultas">Fono de consultas</span>
							<br />
							<span className="numero">
								{" "}
								<img src={phone} /> 800 025 001
							</span>
						</a>
					</div>
				</div>
				<div className="cc">
					<p> © 2021 AFP Modelo. Todos los Derechos Reservados.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
