import Alert from "../../utils/alert";
import MaskedInput from "react-text-mask";
import React, { useEffect, useState} from "react";
import { Field, Form, Formik } from "formik";
import { dineroMask } from "../../utils/inputMask";
import { convertToUF, convertUFToCLP, sueldoFormateador } from "../../utils/helpers";
import { validationSchemaModalidadELD } from "../../utils/validationSchemaForm";
import { Collapse } from 'react-collapse';
import { initialValues } from "../../utils/initialValues/formModalidadPago";
import { handleSubmit } from "../../utils/handleSubmit/formModalidadPago";
import { obtenerDecisionPago } from "../../services/api/solicitud/index";
import { obtenerIndicadores } from "../../services/api/bancos/index";
import { SaveLog } from "../../services/api/valida/index";

const FormModalidadPago = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [pensionBasicaSolidaria, setPensionBasicaSolidaria] = useState(0);
    const [indicadores, setIndicadores] = useState({UF: 0, UTM: 0});
    const [montosELD, setMontosELD] = useState({ CLP: 0, UF: 0 });
    const [montosRT, setMontosRT] = useState({ CLP: 0, UF: 0 });
    const [dataForm, setDataForm] = useState({
        tipoOpcionTributariaEld: "",
        seqBenefCalc: "",
        idcliente: "",
        numTramitePension: "",
        SaldoDisponible: { UF: 0, CLP: 0 },
        ItemOne : {
            ExcedenteLibreDisposicion: { UF: 0, CLP: 0 },
            RentaTemporal: { UF: 0, CLP: 0 }
        },
        ItemTwo: {
            RentaTemporal: { UF: 0, CLP: 0 }
        },
        ItemThree: {
            ExcedenteLibreDisposicion: { UF: 0, CLP: 0 },
            RentaTemporal: { UF: 0, CLP: 0 }
        },
        ItemThreeUTM: {
            ExcedenteLibreDisposicion: { UF: 0, CLP: 0 },
            RentaTemporal: { UF: 0, CLP: 0 }
        },
        UTM800 : false
    });
    
    useEffect(async () => {
        let valorUF = 0;
        let valorUTM = 0;

        let obtener_indicadores = await obtenerIndicadores(localStorage.getItem("idPersona"));
        let data = {
            Url: "https://turetiroconmodelo.cl/eld/modalidadpago",
            Method: "obtenerIndicadores",
            Endpoint: `/Bancos/obtenerIndicadores`,
            Request: JSON.stringify({ }),
            Response: JSON.stringify(obtener_indicadores)
        };
    
        await SaveLog(data);
        if(obtener_indicadores.data.ok){
            valorUF = await obtener_indicadores.data.resultado.uf;
            valorUTM = await obtener_indicadores.data.resultado.utm;

            setIndicadores({UF: valorUF, UTM: valorUTM});
        }

        let obtener_decision_pago = await obtenerDecisionPago(localStorage.getItem("idPersona"));        
        const resultado = obtener_decision_pago.data.resultado;
        data = {
            Url: "https://turetiroconmodelo.cl/eld/modalidadpago",
            Method: "obtenerDecisionPago",
            Endpoint: `/Solicitud/TraerDecisionPago?idPersona=${localStorage.getItem("idPersona")}`,
            Request: JSON.stringify({idPersona:localStorage.getItem("idPersona") }),
            Response: JSON.stringify(obtener_decision_pago)
        };
    
        await SaveLog(data);
        const renta_basica_solidaria = resultado.valMlRtConEld;
        setPensionBasicaSolidaria(renta_basica_solidaria);

        const cuota_muortoria_pesos = 15/(5.71) * obtener_indicadores.data.resultado.uf;
        const cuota_muortoria_uf = 15/(5.71);
        const maximo_disponible_pesos = resultado.valMlEld + (12 * renta_basica_solidaria) + cuota_muortoria_pesos;

        const maximo_disponible_uf = resultado.valUfEld + (12 * resultado.valUfRtConEld) + cuota_muortoria_uf;

        setDataForm({
            tipoOpcionTributariaEld: resultado.tipoOpcionTributariaEld,
            seqBenefCalc: resultado.seqBenefCalc,
            idcliente: resultado.idcliente,
            numTramitePension: resultado.numTramitePension,
            SaldoDisponible:{ 
                UF: Intl.NumberFormat().format(maximo_disponible_uf), 
                CLP: maximo_disponible_pesos
                //CLP: convertUFToCLP(resultado.valUfEld, valorUF)
            },
            ItemOne : {
                ExcedenteLibreDisposicion: { 
                    UF: Intl.NumberFormat().format(resultado.valUfEld), 
                    CLP: resultado.valMlEld
                    //CLP: convertUFToCLP(resultado.valUfEld, valorUF)
                },
                RentaTemporal: { 
                    UF: Intl.NumberFormat().format(resultado.valUfRtConEld),
                    //CLP: resultado.valMlRtConEld 
                    CLP: renta_basica_solidaria  //convertUFToCLP(resultado.valUfRtConEld, valorUF)
                }
            },
            ItemTwo: {
                RentaTemporal: { 
                    UF: Intl.NumberFormat().format(resultado.valUfRtSinEld),
                    CLP: resultado.valMlRtSinEld
                    //CLP: convertUFToCLP(resultado.valUfRtSinEld, valorUF)
                }
            },
            ItemThree: {
                ExcedenteLibreDisposicion: { 
                    UF: Intl.NumberFormat().format(resultado.valUfEld),
                    CLP: resultado.valMlEld 
                    //CLP: convertUFToCLP(resultado.valUfEld, valorUF)
                },
                RentaTemporal: { 
                    UF: Intl.NumberFormat().format(resultado.valUfRtConEld),
                    CLP: resultado.valMlRtConEld 
                    //CLP: convertUFToCLP(resultado.valUfRtConEld, valorUF)
                }
            },
            ItemThreeUTM: {
                ExcedenteLibreDisposicion: { 
                    UF: Intl.NumberFormat().format(resultado.valUfEld800Utm), 
                    CLP: resultado.valMlEld800Utm
                    //CLP: convertUFToCLP(resultado.valUfEld800Utm, valorUF)
                },
                RentaTemporal: { 
                    UF: Intl.NumberFormat().format(resultado.valUfRtConEld800Utm), 
                    CLP: resultado.valMlRtConEld800Utm 
                    //CLP: convertUFToCLP(resultado.valUfRtConEld800Utm, valorUF)
                }
            },
            UTM800: resultado.valUfEld800Utm > 0 ? true : false
        });
        
        localStorage.setItem("PBS", resultado.valMlRtConEld);
        //localStorage.setItem("ELDMaximo", parseInt(convertUFToCLP(resultado.valUfEld, valorUF)));
        //localStorage.setItem("RTMaximo", parseInt(convertUFToCLP(resultado.valUfRtSinEld, valorUF)));

        localStorage.setItem("ELDMaximo", resultado.valMlEld);
        localStorage.setItem("RTMaximo", resultado.valMlRtSinEld);
    }, []);
    
    const render800UTM = (formik) => {
        let UTM800=true;
        if(UTM800){
            return (
                <div className="custom-accordion">
                    <div className="accordion-header" id="headingThree">
                        <Field name="radioButton">
                            {({ field }) => (
                                <div className="form-check">
                                    <label className="form-check-label mt-auto">
                                        <input {...field}
                                            type="radio"
                                            name="radioButton"
                                            className="form-check-input"
                                            value="3" />
                                        <small>Excedente Libre Disposición </small>
                                    </label>
                                </div>
                            )}
                        </Field>
                    </div>
                    <Collapse isOpened={formik.values.radioButton === "3"}>
                        <div className="accordion-body">
                            <h6>Deseo solicitar el excedente libre disposición y  renta temporal ajustado al tope de 800 UTM (No afecto a impuesto).</h6>
                            <div className="mt-5 text-center">
                                <p className="eld-title">Excedente Libre Disposición</p>
                                <p className="eld-monto-uf">{dataForm.ItemThreeUTM.ExcedenteLibreDisposicion.UF} UF</p>
                                <p className="eld-monto-peso">{sueldoFormateador(dataForm.ItemThreeUTM.ExcedenteLibreDisposicion.CLP)} aproximados</p>
                                <small>Este monto será pagado en 1 sola cuota 5 días hábiles después de su selección. </small>
                                <h2>+</h2>
                                <p className="eld-title">Renta Temporal</p>
                                <p className="eld-monto-uf">{dataForm.ItemThreeUTM.RentaTemporal.UF} UF</p>
                                <p className="eld-monto-peso">{sueldoFormateador(dataForm.ItemThreeUTM.RentaTemporal.CLP)} aproximados</p>
                                <small>Este es el monto que mensualmente recibirás como pago, durante un período de 12 meses.</small>
                            </div>
                            <div className="mt-4">
                                <Alert 
                                    message="La selección de esta opción se ajusta al tope de 800 UTM quedando este excedente libre disposición no afecto a impuesto." 
                                    className="alert-secondary" 
                                    image="warning" />
                            </div>
                        </div>
                    </Collapse>
                </div>
            );
        }
    }

    const onSubmit = (values) => {
        handleSubmit({
            values: values,
            dataForm: dataForm,
            montosELD: montosELD,
            montosRT: montosRT,
            changeLoading: setIsLoading
        });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchemaModalidadELD} onSubmit={onSubmit}>
            {(formik) => (
                <Form>
                    <div className="eld-body">
                        <div id="accordion">
                            <div className="custom-accordion">
                                <div className="accordion-header">
                                    <div className="text-center">
                                        <p className="">Saldo disponible:</p>
                                        <p className="eld-monto-uf eld-font-42">{dataForm.SaldoDisponible.UF} UF</p>
                                        <p className="eld-monto-peso">{sueldoFormateador(dataForm.SaldoDisponible.CLP)} aproximados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="mt-4 eld-title">Usted puede configurar el pago con las siguientes opciones:</h5>
                        <h6 className="mt-3 eld-subtitle">En esta sección podrás seleccionar la modalidad de pago para tus Excedentes de Libre Disposición (ELD). Al seleccionar cada modalidad podrás ver en que consiste cada método de pago.</h6>
                        <div id="accordion" className="mt-4">
                            <div className="custom-accordion">
                                <div className="accordion-header" id="headingOne">
                                    <Field name="radioButton">
                                        {({ field }) => (
                                            <div className="form-check">
                                                <label className="form-check-label mt-auto">
                                                    <input {...field}
                                                        type="radio"
                                                        name="radioButton"
                                                        className="form-check-input"
                                                        value="1"
                                                    />
                                                    <small>Excedente Libre Disposición Máximo</small>
                                                </label>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Collapse isOpened={formik.values.radioButton === "1"}>
                                    <div>
                                        <div className="accordion-body">
                                            <h6>Deseo solicitar el máximo excedente libre disposición y ajustar mi renta temporal al monto de la pensión básica solidaria ({sueldoFormateador(pensionBasicaSolidaria)}).</h6>
                                            <div className="mt-5 text-center">
                                                <p className="eld-title">Excedente Libre Disposición Máximo</p>
                                                <p className="eld-monto-uf">{dataForm.ItemOne.ExcedenteLibreDisposicion.UF} UF</p>
                                                <p className="eld-monto-peso">{sueldoFormateador(dataForm.ItemOne.ExcedenteLibreDisposicion.CLP)} aproximados</p>
                                                <small>Este monto será pagado en 1 sola cuota 5 días hábiles después de su selección.</small>
                                                <h2>+</h2>
                                                <p className="eld-title">Renta Temporal</p>
                                                <p className="eld-monto-uf">{dataForm.ItemOne.RentaTemporal.UF} UF</p>
                                                <p className="eld-monto-peso">{sueldoFormateador(dataForm.ItemOne.RentaTemporal.CLP)} aproximados</p>
                                                <small>Este es el monto que mensualmente recibirás como pago, durante un período de 12 meses.</small>
                                            </div>
                                            <div className="mt-4">
                                                <Alert
                                                    message="La selección del monto máximo del excedente libre disposición, en caso de ser mayor a 800 UTM producirá el pago de impuestos por la diferencia que exceda a este monto."
                                                    className="alert-secondary" 
                                                    image="warning" />
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="custom-accordion">
                                <div className="accordion-header" id="headingTwo">
                                    <Field name="radioButton">
                                        {({ field }) => (
                                            <div className="form-check">
                                                <label className="form-check-label mt-auto">
                                                    <input {...field}
                                                        type="radio"
                                                        name="radioButton"
                                                        className="form-check-input"
                                                        value="2"
                                                    />
                                                    <small>Renta Temporal Máxima</small>
                                                </label>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Collapse isOpened={formik.values.radioButton === "2"}>
                                    <div>
                                        <div className="accordion-body">
                                            <h6>Deseo solicitar la máxima renta temporal con excedente libre disposición $0.</h6>
                                            <div className="mt-5 text-center">
                                                <p className="eld-title">Renta Temporal Máxima</p>
                                                <p className="eld-monto-uf">{dataForm.ItemTwo.RentaTemporal.UF} UF</p>
                                                <p className="eld-monto-peso">{sueldoFormateador(dataForm.ItemTwo.RentaTemporal.CLP)} aproximados</p>
                                                <small>Este es el monto que mensualmente recibirás como pago, durante un período de 12 meses.</small>
                                            </div>
                                            <div className="mt-4">
                                                <Alert 
                                                    message="En esta opción, el monto del excedente se destina a mejorar la renta temporal." 
                                                    className="alert-secondary" 
                                                    image="warning" />
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            {render800UTM(formik)}
                            <div className="custom-accordion">
                                <div className="accordion-header" id="headingFour">
                                    <Field name="radioButton">
                                        {({ field }) => (
                                            <div className="form-check">
                                                <label className="form-check-label mt-auto">
                                                    <input {...field}
                                                        type="radio"
                                                        name="radioButton"
                                                        className="form-check-input"
                                                        value="4"
                                                    />
                                                    <small>Fijar el Monto del Excedente Libre Disposición</small>
                                                </label>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Collapse isOpened={formik.values.radioButton === "4"}>
                                    <div>
                                        <div className="accordion-body">
                                            <h6>Deseo solicitar el monto indicado del Excedente Libre Disposición, y la diferencia destinarla a la renta temporal al monto que corresponda.</h6>
                                            <h5 className="eld-title mt-4">Usted puede fijar el monto del Excedente Libre Disposición</h5>
                                            <div className="mt-3">
                                                <span>Indique el monto en pesos: </span>
                                                <Field name="montoLibreDisposicion">
                                                    {({ field, meta }) => (
                                                        <div className="form-group input-wrapper">
                                                            <MaskedInput {...field}
                                                                type="text"
                                                                mask={dineroMask}
                                                                className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                                name="montoLibreDisposicion"
                                                                autoComplete="off"
                                                                aria-describedby="montoLibreDisposicionHelp" 
                                                                placeholder="$0"
                                                                value={montosELD.CLP}
                                                                onChange = {(e)=> { 
                                                                    formik.values.montoLibreDisposicion = e.target.value;
                                                                    let uf = convertToUF(e.target.value, indicadores.UF, 2);
                                                                    setMontosELD({CLP: e.target.value, UF: uf === "NaN" ? 0: uf});
                                                                }} />
                                                            <small id="montoLibreDisposicionHelp" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}> {meta.touched && meta.error ? meta.error : "El monto no debe superar al excedente máximo disponible."}</small>
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="mt-5 text-center">
                                                    <p className="eld-title">Monto Excedente Libre Disposición</p>
                                                    <p className="eld-monto-uf">{montosELD.UF} UF</p>
                                                    <p className="eld-monto-peso">(aproximadas)</p>
                                                    <small>Este monto será pagado en 1 sola cuota 5 días hábiles después de su selección.</small>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Alert 
                                                    message="En esta opción, el monto del excedente no solicitado se destina a mejorar la renta temporal." 
                                                    className="alert-secondary" 
                                                    image="warning" />
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="custom-accordion">
                                <div className="accordion-header" id="headingFive">
                                    <Field name="radioButton">
                                        {({ field }) => (
                                            <div className="form-check">
                                                <label className="form-check-label mt-auto">
                                                    <input {...field}
                                                        type="radio"
                                                        name="radioButton"
                                                        className="form-check-input"
                                                        value="5"
                                                    />
                                                    <small>Fijar el Monto de la Renta Temporal</small>
                                                </label>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Collapse isOpened={formik.values.radioButton === "5"}>
                                    <div>
                                        <div className="accordion-body">
                                            <h6>Deseo solicitar el monto indicado del Excedente Libre Disposición, y la diferencia destinarla a la renta temporal al monto que corresponda.</h6>
                                            <h5 className="eld-title mt-4">Usted puede fijar el monto de la Renta Temporal</h5>
                                            <div className="mt-3">
                                                <span>Indique el monto en pesos: </span>
                                                <Field name="montoRentaTemporal">
                                                    {({ field, meta }) => (
                                                        <div className="form-group input-wrapper">
                                                            <MaskedInput {...field}
                                                                type="text"
                                                                mask={dineroMask}
                                                                className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                                name="montoRentaTemporal"
                                                                autoComplete="off"
                                                                aria-describedby="montoRentaTemporalHelp" 
                                                                placeholder="$0"
                                                                value={montosRT.CLP}
                                                                onChange = {(e)=> {
                                                                    formik.values.montoRentaTemporal = e.target.value;
                                                                    
                                                                    let uf = convertToUF(e.target.value, indicadores.UF, 2);
                                                                    setMontosRT({CLP: e.target.value, UF: uf === "NaN" ? 0: uf});
                                                                }}/>
                                                            <small id="montoRentaTemporalHelp" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}> {meta.touched && meta.error ? meta.error : "El monto no debe ser menor a $169.649 ni mayor a tu renta temporal máxima."}</small>
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="mt-5 text-center">
                                                    <p className="eld-title">Monto Renta Temporal</p>
                                                    <p className="eld-monto-uf">{montosRT.UF} UF</p>
                                                    <p className="eld-monto-peso">(aproximadas)</p>
                                                    <small>Este es el monto que mensualmente recibirás como pago, durante un período de 12 meses.</small>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Alert 
                                                    message="En esta opción, el monto del excedente no solicitado se destina a mejorar la renta temporal." 
                                                    className="alert-secondary" 
                                                    image="warning" />
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        {isLoading
                            ? <>
                                <button className="btn-custom btn-lg btn-custom-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </>
                            :
                            <button className="btn-custom btn-lg btn-custom-primary" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                                Continuar
                            </button>
                        }                        
                    </div>
                    <div className="row">
                        <div className="col justify-content-center d-flex blue-a ">
                            <a  onClick={()=> { window.location = "eld/index"  }}>Volver al paso anterior</a>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default FormModalidadPago;
