import { validaFolio, SaveLog } from "../../services/api/valida/index";
import { obtenerDecisionPago } from "../../services/api/solicitud/index";
import { convertDniToID } from "../../utils/helpers";
import Swal from "sweetalert2";
import configuration from "../../Configuration";

const MySwal = Swal.mixin({
	customClass: {
		cancelButton: "btn btn-cerrar",
		confirmButton: "btn-custom btn-lg btn-custom-primary",
		title: "font-weight-bold text-left",
		footer: "swal-footer",
	},
	allowOutsideClick: false,
	buttonsStyling: false,
});

export const handleSubmit = async ({ values, changeLoading }) => {
	changeLoading(true);
	localStorage.clear();

	let result = { title: "", message: "" };

	const idPersona = convertDniToID(values.rut);
	const valida_folio = await validaFolio({
		rut: idPersona,
		folio: values.numeroSolicitud,
	});

	if (valida_folio.data.resultado.valido) {
		const desicion_pago = await obtenerDecisionPago(idPersona);

		let data = {
			Url: configuration.RED_URL,
			Method: "obtenerDecisionPago",
			Endpoint: `/Solicitud/TraerDecisionPago?idPersona=${idPersona}`,
			Request: JSON.stringify({ idPersona: idPersona }),
			Response: JSON.stringify(desicion_pago),
		};

		await SaveLog(data);

		if (desicion_pago.data.resultado.codigoResp === "0") {
			localStorage.setItem("idPersona", idPersona);
			window.location = "/eld/ModalidadPago";
		} else if (desicion_pago.data.resultado.codigoResp === "100") {
			result.title = "¡No pudimos validar los datos ingresados!";
			result.message =
				"Los datos ingresados no coinciden con nuestros registros de solicitud de pensión PAET. Por favor, verifica que sean correctos.";
		} else if (desicion_pago.data.resultado.codigoResp === "300") {
			result.title = "No se ha seleccionado modalidad de pago.";
			result.message = "No Existe una opción de Pago Ingresada.";
		} else if (desicion_pago.data.resultado.codigoResp === "400") {
			result.title = "Ya se ha seleccionado tu modalidad de pago.";
			result.message =
				"Según nuestros registros este número de solicitud ya ha seleccionado la modalidad de pago de Excedente de Libre Disposición (ELD).";
		} else if (desicion_pago.data.resultado.codigoResp === "500") {
			result.title = "Monto muy bajo";
			result.message = "El monto a Pago es menor o igual a la Pensión Basica solidaria.";
		} else if (desicion_pago.data.resultado.codigoResp === "600") {
			result.title = "Tu solicitud PAET ha sido rechazada.";
			result.message =
				"Los motivos han sido enviados a tu correo electrónico o teléfono de contacto . Si tienes dudas sobre estos puedes contactarnos al 800 025 001.";
		} else if (desicion_pago.data.resultado.codigoResp === "700") {
			result.title = "Tu solicitud PAET aún esta en revisión.";
			result.message =
				"La solicitud de Pensión Anticipada por Enfermedad Terminal aún esta en proceso de revisión. Espera la confirmación de parte de nuestros ejecutivos.";
		} else if (desicion_pago.data.resultado.codigoResp === "800") {
			result.title = "¡No pudimos validar los datos ingresados!";
			result.message =
				"Los datos ingresados no coinciden con nuestros registros de solicitud de pensión PAET. Por favor, verifica que sean correctos.";
		} else if (desicion_pago.data.resultado.codigoResp === "900") {
			result.title = "Solicitud fuera de plazo.";
			result.message =
				"La fecha para optar a una modalidad de pago ha vencido. El pago se realizará en la modalidad de Renta Temporal Máxima.";
		}
	} else {
		result.title = "¡No pudimos validar los datos ingresados!";
		result.message =
			"Los datos ingresados no coinciden con nuestros registros de solicitud de pensión PAET. Por favor, verifica que sean correctos.";
	}

	if (result.title !== "") {
		MySwal.fire({
			title: result.title,
			icon: "error",
			text: result.message,
			confirmButtonText: "OK",
		}).then((result) => {
			if (result.value) {
				changeLoading(false);
			}
		});
	}
};
