import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import configuration from "../Configuration";
import { formatearRut, formatearRutCeros } from "../utils/validationRut";
import FormaPagoHelper from "../utils/formaPago.Helper";
import cuentaBancariaHelper from "../utils/cuentaBancaria.Helper";
import tipoCuentaUsoHelper from "../utils/tipoCuentaUso.Helper";
import { oacento } from "../utils/caracteresUTF8";
import { traerBancos } from "../services/api/bancos";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";

const FormResumen = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [datos, setDatos] = useState({});
	const [datosCuenta, setdatosCuenta] = useState([]);
	const [instituciones, setInstituciones] = useState(null);

	let history = useHistory();

	const Persona = {
		idPersona: localStorage.getItem("idPersona"),
		informante: localStorage.getItem("informante"),
	};

	useEffect(async () => {
		const lista_bancos = await traerBancos();

		if (lista_bancos.status === 200 && lista_bancos.data.ok) {
			const array = [];

			lista_bancos.data.resultado.map((datos) => {
				array.push({ codigo: datos.codBanco, descripcion: datos.descripcion });
			});

			setInstituciones(array);
		}

		const ObtenerDatos = async () => {
			await axios({
				method: "GET",
				url: `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${Persona.idPersona}`,
				headers: configuration.JSON_HEADER,
			})
				.then((response) => {
					let resultado = response.data.resultado;

					setDatos({
						nombreAfiliado: `${resultado.nombreAfi} ${resultado.apPaternoAfi} ${resultado.apMaternoAfi}`,
						rutAfiliado: formatearRut(formatearRutCeros(resultado.idPersona)),
						nombreMandatario: `${resultado.nombreMan} ${resultado.apPaternoMan} ${resultado.apMaternoMan}`,
						rutMandatario: resultado.idMandatario
							? formatearRut(formatearRutCeros(resultado.idMandatario))
							: "",
						formaPago: resultado.formaPago,
						idCliente: resultado.idCliente,
						iniciaMandatario: resultado.iniciaMandatario,
						folioSolicitud: resultado.folioSolicitud,
					});
					ObtenerDatosCuenta(resultado.idCliente);
				})
				.catch((e) => {
					console.error(e);
					setIsLoading(false);
				});
		};

		const ObtenerDatosCuenta = async (idCliente) => {
			await axios
				.get(
					`${configuration.API_URL}/FormaPago/ObtenerFormasPagoList` +
						"?idCliente=" +
						idCliente,
					{
						headers: configuration.JSON_HEADER,
					}
				)
				.then((response) => {
					let resultado = response.data.resultado;
					let array = [];

					if (response.data.ok) {
						resultado = resultado.reverse();

						resultado.map(
							(cuenta, i) =>
								(array[i] = {
									idReceptorPago: formatearRut(
										formatearRutCeros(cuenta.idReceptorPago)
									),
									formaPago: FormaPagoHelper.getFormaPago(cuenta.tipoViaPago),
									tipoCuenta: cuenta.tipoCuentaDeposito,
									numeroCuenta: cuenta.numCuentaDeposito,
									tipoCuentaUso: cuenta.tipoCuentaUso,
									Banco: cuenta.codInstFinancDeposito,
								})
						);

						setdatosCuenta(array);
					}
				})
				.catch((e) => {
					console.error(e);
					setIsLoading(false);
				});
		};
		ObtenerDatos();
	}, []);

	const disable = (e) => {
		return false;
	};

	const onSubmit = (e) => {
		setIsLoading(true);

		axios
			.post(
				`${configuration.API_URL}/Solicitud/enviarTramiteAFPNet?numeroFolio=` +
					datos.folioSolicitud,
				{ headers: configuration.JSON_HEADER }
			)
			.then((response) => {
				setIsLoading(false);
				if (response.status !== 200) {
					console.error("NOK");
				} else if (response.status === 200) {
					var resultado = JSON.parse(response.data.mensaje);

					if (resultado.codigo === "0") {
						window.location.href = "/comprobante";
					} else {
						Swal.fire({
							title: "Atención",
							html: "<center>" + resultado.descripcion + "</center>",
							icon: "warning",
							allowOutsideClick: false,
						});
					}
				}
			})
			.catch((e) => {
				console.error(e);
			});
	};

	return (
		<Formik onSubmit={onSubmit}>
			{(formik) => (
				<Form>
					<div className="form-group">
						<strong htmlFor="">Nombre Afiliado o Pensionado</strong>
						<br />
						{datos.nombreAfiliado}
					</div>
					<div className="form-group">
						<strong htmlFor="">RUT Afiliado o Pensionado</strong>
						<br />
						{datos.rutAfiliado}
					</div>

					{datos.iniciaMandatario ? (
						<>
							<div className="form-group">
								<strong htmlFor="">Nombre Mandatario</strong>
								<br /> {datos.nombreMandatario}
							</div>
							<div className="form-group">
								<strong htmlFor="">RUT Mandatario</strong>
								<br />
								{datos.rutMandatario}
							</div>
						</>
					) : (
						<span></span>
					)}
					{datosCuenta.map((cuenta, index) => (
						<div className="mt-4" key={index}>
							<div className="form-group mt-4 ">
								<strong className="title">
									{tipoCuentaUsoHelper.getTipoCuenta(cuenta.tipoCuentaUso)}
								</strong>
								<br />
							</div>
							<div className="form-group">
								<strong>RUT</strong>
								<br />
								{cuenta.formaPago === `Deposito Bancario` ||
								cuenta.formaPago === undefined
									? cuenta.idReceptorPago
									: Persona.informante === "A"
									? datos.rutAfiliado
									: datos.rutMandatario}
							</div>
							<div className="form-group">
								<strong htmlFor="">Forma de Pago</strong>
								<br />
								{cuenta.formaPago === undefined ||
								cuenta.formaPago === "Deposito Bancario"
									? `Dep${oacento}sito Bancario: ` +
									  instituciones.find((x) => x.codigo === cuenta.Banco)
											.descripcion
									: cuenta.formaPago}
							</div>

							{cuenta.formaPago === "Deposito Bancario" ||
							cuenta.formaPago === undefined ? (
								<>
									<div className="form-group">
										<strong htmlFor="">Tipo de cuenta</strong>
										<br />
										{cuenta.Banco === 12 ? (
											"CuentaRUT"
										) : (
											<>{cuentaBancariaHelper.getCuenta(cuenta.tipoCuenta)}</>
										)}
									</div>
									<div className="form-group">
										<strong htmlFor="">Número de cuenta</strong>
										<br />
										{cuenta.numeroCuenta}
									</div>
								</>
							) : (
								<span></span>
							)}
						</div>
					))}

					<div className="mt-4">
						<div className="row ">
							<div className="col justify-content-center d-flex">
								{isLoading ? (
									<>
										<button
											type="button"
											className="btn-custom btn-lg btn-custom-primary"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
										</button>
									</>
								) : (
									<button
										type="button"
										id="continuar"
										className="btn-custom btn-lg btn-custom-primary"
										disabled={disable(formik)}
										onClick={onSubmit}
									>
										Confirmar envio solicitud
									</button>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col justify-content-center d-flex">
								<button
									className="btn-custom btn-lg btn-custom-default"
									type="button"
									onClick={() => {
										window.location.href = "/";
									}}
								>
									Cancelar solicitud
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col justify-content-center d-flex">
								<a
									className="volver"
									onClick={() => {
										localStorage.setItem("alternativa", "0");
										history.goBack();
									}}
								>
									Volver al paso anterior
								</a>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormResumen;
