import React from "react";
import { Helmet } from "react-helmet";
import FormSeguridad from "../components/FormClaveSeguridad";
import Alert from "../utils/alert";

export default function claveSeguridad() {

    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className=" bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal</div>
                                        <span className="textoSecundario">Comienza tu solicitud aquí.</span>
                                        <div className="card-body">
                                            <FormSeguridad />
                                            <Alert
                                            image="warning" 
                                            className="alert-secondary" 
                                            message={["Este portal es ", <strong key="s">exclusivo para los Afiliados, Pensionados AFP Modelo.</strong>, " Si no sabes a qué AFP estás afiliado, ", <a key="a" href="https://www.spensiones.cl/apps/certificados/formConsultaAfiliacion.php" target="blank_" className="green-a">haz click aquí</a>, "."]} />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}