import { cleanRut } from "../cleanInputMask";
import { ingresoEjecutivo } from "../../services/api/valida/index";

export const handleIngresoEjecutivo = async ({ values, history }) => {
	const body = {
		idNumeroFolio: parseInt(localStorage.getItem("numFolio")),
		rutEjecutivo: cleanRut(values.rutEjecutivo),
		idTipoIngresoCanal: parseInt(values.idTipoIngresoCanal),
		fecIngSolicitud: "2021-06-30T19:24:11.692Z",
	};

	const validaIngreso = await ingresoEjecutivo(body);

	if (validaIngreso.status === 200) {
		if (localStorage.getItem("informante") === "A") {
			history.push(`/datosAfiliados`);
		} else if (localStorage.getItem("informante") === "M") {
			history.push(`/mandatario`);
		} else {
			history.push(`/index`);
		}
	}
};
