import axios from "axios";
import configuration from "../Configuration";
import React, { useState } from "react";
import "../assets/styles/style.css";
import logo from "../assets/svg/exclamacion_amarillo.svg";
import MaskedInput from "react-text-mask";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { celularMaskNew } from "../utils/inputMask";
import { validationSchemaClaveError } from "../utils/validationSchemaForm";
import { useHistory } from "react-router-dom";
import { removeCodigoArea, cleanDigitos } from "../utils/cleanInputMask";

const ModalAgregarDatosContacto = (props) => {
	let history = useHistory();

	const initialValues = {
		nombre: "",
		celular: "",
		correo: "",
	};

	const [isLoading, setIsLoading] = useState(false);
	return (
		<div>
			<Modal
				{...props}
				dialogClassName="modal-50w"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						<div className="row">
							<div className="col-2">
								<img src={logo} width="62" height="62" alt="alert img" />
							</div>
							<div className="col-10 text-left">
								No has declarado ningún beneficiario.
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchemaClaveError}
						onSubmit={(values, { resetForm }) => {
							const body = {
								idNumSol: parseInt(localStorage.getItem("folioSolicitud")),
								nombreCont: values.nombre,
								telefonoCont: removeCodigoArea(cleanDigitos(values.celular)),
								emailCont: values.correo,
							};

							axios
								.post(
									`${configuration.API_URL}/ContactoExternos/IngresarDatosContactoTerceroBeneficiario`,
									body,
									{ headers: configuration.JSON_HEADER }
								)
								.then((response) => {
									history.push("/cuentas");
								})
								.catch((e) => {
									console.error(e);
								});
						}}
					>
						{(formik) => (
							<Form>
								<div className="form-group input-wrapper">
									<div className="">
										<br />
										Puedes continuar con la solicitud sin agregar beneficiarios,
										pero de todas formas debes registrar los datos de contacto
										de un tercero para poder comunicarnos en caso de ser
										necesario.
									</div>
									<br />
									<div className="">
										<span className="font-weight-bold">
											Ingresa los datos de contacto:
										</span>
									</div>
									<br />
									<Field name="nombre">
										{({ field, meta }) => (
											<div className="form-group input-wrapper">
												<span>Nombre completo</span>
												<input
													{...field}
													type="text"
													className={`form-control form-control-lg ${
														meta.touched
															? meta.error
																? "is-invalid"
																: "is-valid"
															: ""
													}`}
													id="nombre"
													name="nombre"
													aria-describedby="nombreAyuda"
													autoComplete="off"
												/>
												<small
													id="nombreAyuda"
													className={`form-text ${
														meta.touched && meta.error ? "is-invalid" : ""
													}`}
												>
													{meta.touched && meta.error
														? meta.error
														: "Ej: Alexander Perez Soto."}
												</small>
											</div>
										)}
									</Field>
									<Field name="celular">
										{({ field, meta }) => (
											<div className="form-group input-wrapper">
												<span>Telefóno de contacto principal</span>
												<MaskedInput
													{...field}
													mask={celularMaskNew}
													type="text"
													className={`form-control form-control-lg ${
														meta.touched
															? meta.error
																? "is-invalid"
																: "is-valid"
															: ""
													}`}
													id="celular"
													name="celular"
													aria-describedby="celularAyuda"
													placeholder={`(+56) _ ____ ____`}
													autoComplete="off"
												/>
												<small
													id="celularAyuda"
													className={`form-text ${
														meta.touched && meta.error ? "is-invalid" : ""
													}`}
												>
													{meta.touched && meta.error
														? meta.error
														: "Ingresa 9 dígitos. Ej: 9-9123-4567 o 22-1234567."}
												</small>
											</div>
										)}
									</Field>
									<Field name="correo">
										{({ field, meta }) => (
											<div className="form-group input-wrapper">
												<span>Correo electrónico</span>
												<input
													{...field}
													type="text"
													className={`form-control form-control-lg ${
														meta.touched
															? meta.error
																? "is-invalid"
																: "is-valid"
															: ""
													}`}
													id="correo"
													name="correo"
													placeholder=""
													autoComplete="off"
													aria-describedby="correoAyuda"
												/>
												<small
													id="correoAyuda"
													className={`form-text ${
														meta.touched && meta.error ? "is-invalid" : ""
													}`}
												>
													{meta.touched && meta.error
														? meta.error
														: "Ej: aumentatusueldo@afpmodelo.cl"}
												</small>
											</div>
										)}
									</Field>
								</div>
								<div className="d-flex justify-content-center">
									<div className="col justify-content-center d-flex">
										{isLoading ? (
											<>
												{" "}
												<button
													type="button"
													className="btn-custom btn-lg btn-custom-primary"
													disabled
												>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"
													></span>
												</button>{" "}
											</>
										) : (
											<button
												className="btn-custom btn-lg btn-custom-primary"
												type="submit"
												disabled={!(formik.isValid && formik.dirty)}
											>
												Continuar
											</button>
										)}
									</div>
								</div>
								<div>
									<Button className="btn-cerrar" onClick={props.onHide}>
										Cerrar
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ModalAgregarDatosContacto;
