import React from "react";
import Helmet from "react-helmet";
import FormConsultar from "../components/FormConsultar";

export default function consultar() {
    return (
            <div className="container home">
                <Helmet>
                    <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                    <meta name="description" content={``} />
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <section>
                    <div className="textoIzquierda textoTitulo-white">
                        Retiro de fondos con AFP Modelo
                    </div>
                    <div className="textoIzquierda textoSecundario-white">
                        INFÓRMATE Y HAZ EL RETIRO DE TUS FONDOS
                    </div>
                    <div className="row w-100 container-padre mt-5">
                        <div className="bloque form">
                            <div className="card formulario">
                                <div className="paet-head">
                                    Estado de Pensión Anticipada por Enfermedad Terminal (PAET)
                                </div>
                                <div className="paet-body">
                                    Para ver el estado de tu solicitud debes ingresar el RUT del afiliado y el número de la solicitud que enviamos al correo electrónico ingresado.
                                </div>
                                <div className="paet-body">
                                    <FormConsultar />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </section>
            </div>
    )
}