import { genericGet } from "../../../utils/genericApiCall";

export async function obtenerTipoAfiliadoPensionado() {
    const url_endpoint = '/TipoAfiliadoPensionado/ObtenerTodo';
    const message_info = 'Obteniendo tipo afiliado o pensionado...';
    const message_error = 'Error al obtener tipo afiliado o pensionado...';

    return await genericGet({
        url_endpoint: url_endpoint,
        message_info: message_info,
        message_error: message_error
    });
}