import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { iacento } from "../utils/caracteresUTF8";
import { validationSchemaClaveError } from "../utils/validationSchemaForm";
import { useHistory } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { celularMaskNew  } from "../utils/inputMask";
import "../assets/styles/claveSeguridadModal.css";
import Swal from "sweetalert2";
import logo from "../assets/svg/error.svg";
export default function TerminosModal(props) {

    const history = useHistory();

    const initialValues = {
        nombre: '',
        celular: '',
        correo: '',
    }

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            <Modal {...props} dialogClassName="modal-50w" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="row">                        
                            <div className="col-2">
                                <img src={logo} width="62" height="62" />
                            </div>                    
                            <div className="col-10 text-left">
                                ¡No hemos podido validar tu identidad!
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} validationSchema={validationSchemaClaveError} onSubmit={(values, { resetForm }) => {                          
                        Swal.fire({
                            title: 'Tus datos fueron registrados exitosamente.',
                            icon: 'success',
                            text: 'Te contactaremos a la brevedad para que puedas iniciar tu retiro lo más pronto posible, por ahora, te redigiremos al inicio del sitio.',
                            'confirmButtonText': 'Continuar',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.value) {
                                history.push(`/`);
                            }
                        });
                    }}>
                        {(formik) => (
                            <Form>                                
                                <div className="form-group input-wrapper">
                                    <div className=""><br/>
                                        No se pudieron validar los datos ingresados. Puedes ingresar tu solicitud llamando al <a className="blue-a" href="tel:800025001">800 025 001</a>.
                                    </div><br/>
                                    <div className="">
                                        <span className="font-weight-bold">
                                            Si quieres que te contactemos env{iacento}anos tus datos
                                        </span>
                                    </div><br/>
                                    <Field name="nombre">
                                        {({ field, meta }) => (                                        
                                            <div className="form-group input-wrapper">
                                                <span>Nombre completo</span>
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                    id="nombre"
                                                    name="nombre"
                                                    aria-describedby="nombreAyuda"
                                                    autoComplete="off"/>
                                                <small id="nombreAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                                    {meta.touched && meta.error? meta.error : "Ej: Alexander Perez Soto."}
                                                </small>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="celular">
                                        {({ field, meta }) => (
                                            <div className="form-group input-wrapper">
                                                <span>Telefóno de contacto principal</span>
                                                <MaskedInput
                                                    {...field}
                                                    mask={celularMaskNew}
                                                    type="text"
                                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                    id="celular"
                                                    name="celular"
                                                    aria-describedby="celularAyuda"
                                                    placeholder={`(+56) _ ____ ____`}
                                                    autoComplete="off"/>
                                                <small id="celularAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                                    {meta.touched && meta.error ? meta.error : "Ingresa 9 dígitos. Ej: 9-9123-4567 o 22-1234567."}
                                                </small>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="correo">
                                        {({ field, meta }) => (
                                            <div className="form-group input-wrapper">
                                                <span>Correo electrónico</span>
                                                <input {...field}
                                                    type="text"
                                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                    id="correo"
                                                    name="correo"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    aria-describedby="correoAyuda" />
                                                <small id="correoAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                                    {meta.touched && meta.error ? meta.error : "Ej: aumentatusueldo@afpmodelo.cl"}
                                                </small>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="col justify-content-center d-flex">
                                        {isLoading
                                            ? <>
                                                <button type="button" className="btn-custom btn-lg btn-custom-primary" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button> 
                                            </>
                                            :
                                            <button className="btn-custom btn-lg btn-custom-primary" type="submit"  disabled={!(formik.isValid && formik.dirty)}>
                                                Enviar mis datos
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col justify-content-center d-flex">
                            <a onClick={props.onHide}>Cerrar</a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}