import React from "react";
import { Helmet } from "react-helmet";
import PagoBancaria from "../components/FormFormaPago";

export default function formaPagoAlternativa() {

    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal</div>
                                    <span className="textoSecundario">Ingresa la forma de pago alternativa</span>
                                    <div className="card-body">
                                        <p>Esta cuenta será utilizada solo en caso de que no podamos realizar el depósito en tu primera opción de pago.</p>
                                        <PagoBancaria />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}