import React from "react";
import { Helmet } from "react-helmet";
import FormCuentas from "../components/FormCuentas";

const datosCuentas= () => {
    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal</div>
                                    <span className="textoSecundario">Información sobre cuentas adicionales a retirar</span>
                                    <div className="card-body card-header-margin">
                                        <FormCuentas />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default datosCuentas;