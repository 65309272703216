export const cleanDigitos = (value) => {
    const regex = /[^\d]/g;

    if(value !== null)
    {
        return value.replace(regex, '');
    }
    return value;
}

export const cleanRut = (value) => {
    const regex = /\./g;

    if(value !== null)
    {
        return value.replace(regex, '');
    }
    return value;
}

export const convertirRutAId = (value) => {
    const concatenarCeros = value.length === 9 ? '000000' : '000000';
    const auxIdBeneficiario = value.split('-');

    let aux ='';
    if(auxIdBeneficiario[1] !== undefined){
        aux =  concatenarCeros+auxIdBeneficiario[0]+auxIdBeneficiario[1];
    } else {
        aux =  concatenarCeros+auxIdBeneficiario[0];
    }

    if(aux.length === 15) {
        return aux;
    } else if(aux.length === 14){
        return '0'+aux;
    }
}

export const removeCodigoArea = (value) => {

    if(value.length == 9){
        return value;
    }
    else{
        return  value.substring(value.length - 9, value.length);
    }
}

export const convertirIdARut = (value) => {
    
    if(value !== null)
    {
        return value[6] === '0' ? value.substr(7, 9) : value.substr(6, 9);
    }
    return null;
}

export const convertToID = (value) => {
    const identifier = value.replace(/\./g, '');
    const concatenate = identifier.length === 9 ? '000000' : '000000';
    const auxSplit = identifier.split('-');

    let aux = "";
    if(auxSplit[1] !== undefined){
        aux =  concatenate + auxSplit[0] + auxSplit[1];
    } else {
        aux =  concatenate  +auxSplit[0];
    }

    if(aux.length === 15) {
        return aux;
    } else if(aux.length === 14){
        return "0" + aux;
    }
}