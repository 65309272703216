import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import DocumentoModal from "./DocumentoModal";
import axios from "axios";
import configuration from "../Configuration";
import Swal from "sweetalert2";
import { Formik, Form, Field, useFormikContext } from "formik";
import { celularMaskNew, rutMask } from "../utils/inputMask";
import { validationSchemaMandatario } from "../utils/validationSchemaForm";
import { useHistory } from "react-router-dom";
import {
	removeCodigoArea,
	cleanDigitos,
	convertirRutAId,
	cleanRut,
	convertirIdARut,
} from "../utils/cleanInputMask";
import Alert from "../utils/alert";
import { formatearRut } from "../utils/validationRut";
import { formatCelular } from "../utils/helpers";

const Logger = () => {
	const { setTouched, setValues } = useFormikContext();
	useEffect(() => {
		const idCliente = localStorage.getItem("idPersona");
		const obtenerDatosMandatario = async () => {
			const urlObtenerSolicitud = `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${idCliente}`;

			await axios
				.get(urlObtenerSolicitud)
				.then((response) => {
					const data = response.data.resultado;

					localStorage.setItem("folioSolicitud", data.folioSolicitud);

					let rut = cleanRut(data.idMandatario);
					rut = convertirIdARut(rut);
					rut = formatearRut(rut);

					if (rut === null) {
						rut = "";
					}

					const formBeneficiario = {
						rut: rut,
						nombres: data.nombreMan === null ? "" : data.nombreMan,
						apellidoPaterno:
							data.apPaternoMan === null ? "" : data.apPaternoMan,
						apellidoMaterno:
							data.apMaternoMan === null ? "" : data.apMaternoMan,
						telefonoPrincipal:
							data.celularMan === null
								? ""
								: formatCelular(data.celularMan.substr(2, 9).trim()),
						telefonoSecundario:
							data.telefonoMan === null
								? ""
								: formatCelular(data.telefonoMan.substr(2, 9).trim()),
						correo: data.emailMan === null ? "" : data.emailMan,
						correoConfirmar: data.emailMan === null ? "" : data.emailMan,
						termsOfService: false,
						termsOfService2: false,
						numeroserie: data.numDocumentoMan,
						numeroserieCompare: data.numDocumentoMan,
					};

					const formMandatario_touched = {
						rut: true,
						nombres: true,
						apellidoPaterno: true,
						apellidoMaterno: true,
						telefonoPrincipal: true,
						telefonoSecundario: false,
						correo: true,
						correoConfirmar: false,
						termsOfService: false,
						termsOfService2: false,
						numeroserie: true,
						numeroserieCompare: false,
					};

					setValues(formBeneficiario, true);
					setTouched(formMandatario_touched);
				})
				.catch((e) => {
					console.error(e);
				});
		};

		obtenerDatosMandatario();
	}, []);

	return null;
};

const FormMandatario = () => {
	const MySwal = Swal.mixin({
		customClass: {
			confirmButton: "btn-custom btn-lg btn-custom-primary",
			title: "font-weight-bold text-left",
			footer: "swal-footer",
			allowOutsideClick: false,
			reverseButtons: true,
		},
		buttonsStyling: false,
	});

	let history = useHistory();

	const initialValues = {
		rut: "",
		nombres: "",
		apellidoPaterno: "",
		apellidoMaterno: "",
		telefonoPrincipal: "",
		telefonoSecundario: "",
		correo: "",
		correoConfirmar: "",
		termsOfService: false,
		termsOfService2: false,
		numeroserie: "",
		numeroserieCompare: "",
	};

	const Persona = {
		idPersona: localStorage.getItem("idPersona"),
	};

	const disable = (e) => {
		if (e.isValid && e.dirty) {
			// Subio Mandato notariado vigente.
			if (typeOne === 1) {
				return false;
			}

			// Sube Mandato Especial Simple y Declaración Jurada.
			if (typeTwo === 2 && typeThree === 3) {
				return false;
			}

			// No sube ningun documento.
			if (typeOne === 4 && typeTwo === 4 && typeThree === 4) {
				return false;
			}

			return true;
		}

		return true;
	};

	const [isLoading, setIsLoading] = useState(false);

	const [fileOne, setFileOne] = useState(null);
	const [fileTwo, setFileTwo] = useState(null);
	const [fileThree, setFileThree] = useState(null);

	const [typeOne, setTypeOne] = useState(0);
	const [typeTwo, setTypeTwo] = useState(0);
	const [typeThree, setTypeThree] = useState(0);

	const [headingOneClass, setHeadingOneClass] = useState("");
	const [headingTwoClass, setHeadingTwoClass] = useState("");
	const [headingThreeClass, setHeadingThreeClass] = useState("");

	const [modalShow, setModalShow] = useState(false);
	const handleClose = () => setModalShow(false);
	const handleShow = () => setModalShow(true);

	const [isChecked, setIsChecked] = useState(null);

	const handleFileOne = (event) => {
		setFileOne(event.target.files[0]);
		setIsChecked(true);
		setTypeOne(1);
	};

	const handleFileTwo = (event) => {
		setFileTwo(event.target.files[0]);
		setIsChecked(true);
		setTypeTwo(2);
	};

	const handleFileThree = (event) => {
		setFileThree(event.target.files[0]);
		setIsChecked(true);
		setTypeThree(3);
	};

	const onValueChange = (event) => {
		setTypeOne(0);
		setTypeTwo(0);
		setTypeThree(0);

		if (event.target.value === "1") {
			setHeadingOneClass("show");
			setHeadingTwoClass("");
			setHeadingThreeClass("");
			setIsChecked(false);
		} else if (event.target.value === "2") {
			setHeadingOneClass("");
			setHeadingTwoClass("show");
			setHeadingThreeClass("");
			setIsChecked(false);
		} else if (event.target.value === "3") {
			setHeadingOneClass("");
			setHeadingTwoClass("");
			setHeadingThreeClass("show");
			setIsChecked(true);

			setTypeOne(4);
			setTypeTwo(4);
			setTypeThree(4);
		}

		setFileOne(null);
		setFileTwo(null);
		setFileThree(null);

		document.getElementById("fileOne").value = null;
		document.getElementById("fileTwo").value = null;
		document.getElementById("fileThree").value = null;
	};

	const uploadFile = (e) => {
		axios({
			method: "POST",
			url: `${configuration.API_URL}/UploadFiles/enviardocumento`,
			data: e,
		})
			.then(() => {})
			.catch((e) => {
				console.error(e);
			});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemaMandatario}
			onSubmit={(values) => {
				setIsLoading(true);

				axios({
					method: "GET",
					url: `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${Persona.idPersona}`,
					headers: configuration.JSON_HEADER,
				})
					.then((response) => {
						if (response.data.ok) {
							let datos = response.data.resultado;
							datos.idMandatario = convertirRutAId(cleanRut(values.rut));
							datos.folioSolicitud = response.data.resultado.folioSolicitud;
							datos.idPersona = Persona.idPersona;
							datos.numDocumentoMan = values.numeroserie;
							datos.nombreMan = values.nombres;
							datos.apMaternoMan = values.apellidoMaterno;
							datos.apPaternoMan = values.apellidoPaterno;
							datos.emailMan = values.correo;
							datos.celularMan = removeCodigoArea(
								cleanDigitos(values.telefonoPrincipal)
							);
							datos.telefonoMan = removeCodigoArea(
								cleanDigitos(values.telefonoSecundario)
							);
							datos.codRegionMan = datos.codRegionAfi;
							datos.ciudadMan = datos.ciudadAfi;
							datos.codComunaMan = datos.codComunaAfi;

							axios({
								method: "PUT",
								url: `${configuration.API_URL}/Solicitud/actualizarSolicitud`,
								data: datos,
								headers: configuration.JSON_HEADER,
							})
								.then((response) => {
									if (response.data.ok) {
										const archivo = new FormData();
										archivo.append(
											"FolioSolicitud",
											response.data.resultado.folioSolicitud
										);
										archivo.append("IdPersona", Persona.idPersona);

										if (typeOne === 1) {
											archivo.append("nombreArchivo", fileOne.name);
											archivo.append("formFile", fileOne);
											archivo.append("TipoDocumento", typeOne);

											uploadFile(archivo);
										}

										if (typeTwo === 2) {
											archivo.append("nombreArchivo", fileTwo.name);
											archivo.append("formFile", fileTwo);
											archivo.append("tipoDocumento", typeTwo);

											uploadFile(archivo);
										}

										if (typeTwo === 3) {
											archivo.append("nombreArchivo", fileThree.name);
											archivo.append("formFile", fileThree);
											archivo.append("tipoDocumento", typeThree);

											uploadFile(archivo);
										}

										setIsLoading(false);

										MySwal.fire({
											title: "¡Datos de mandatario ingresados correctamente!",
											icon: "success",
											text: "Serás redirigido a un formulario donde debes registrar los datos de beneficiarios.",
											confirmButtonText: "Continuar",
										}).then((result) => {
											if (result.value) {
												history.push("/datosAfiliados");
											}
										});
									}
								})
								.catch((e) => {
									console.error(e);
									setIsLoading(false);
								});
						} else {
							MySwal.fire({
								title: "",
								icon: "warning",
								text: "No se ha encontrado su solicitud, intentelo más tarde.",
								confirmButtonText: "OK",
							}).then((result) => {
								if (result.value) {
									setIsLoading(false);
								}
							});
						}
					})
					.catch((e) => {
						console.error(e);
						setIsLoading(false);
					});
			}}
		>
			{(formik) => (
				<Form>
					<Logger />
					<Field name="rut">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="rut" className="control-labelx">
									RUT Mandatario
								</label>
								<MaskedInput
									{...field}
									mask={rutMask}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="rut"
									name="rut"
									aria-describedby="rutAyuda"
									placeholder=""
									autoComplete="off"
								/>
								<small
									id="rutAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej. 12.345.678-9."}
								</small>
							</div>
						)}
					</Field>

					<span>
						Número de serie o de documento{" "}
						<a onClick={handleShow} className="text-decoration-none align-svg">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="#0d6efd"
								className="bi bi-question-circle-fill"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
							</svg>
						</a>
					</span>

					<Field name="numeroserie">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									name="numeroserie"
									aria-describedby="numeroserieAyuda"
									placeholder=""
									autoComplete="off"
								/>
								<label htmlFor="numeroserie" className="control-label">
									Numero de Serie
								</label>
								<small
									id="numeroserieAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej: A0000000000 o 000.000.000 dependiendo de tu cédula de ID."}
								</small>
							</div>
						)}
					</Field>

					<span>Confirmar número de serie o documento </span>

					<Field name="numeroserieCompare">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									name="numeroserieCompare"
									aria-describedby="numeroserieCompareAyuda"
									placeholder=""
									autoComplete="off"
								/>
								<label htmlFor="numeroserieCompare" className="control-label">
									Numero de Serie
								</label>
								<small
									id="numeroserieCompareAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej: A0000000000 o 000.000.000 dependiendo de tu cédula de ID."}
								</small>
							</div>
						)}
					</Field>
					<Field name="nombres">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="nombres" className="control-labelx">
									Nombres
								</label>
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="nombres"
									name="nombres"
									placeholder=""
									autoComplete="off"
									aria-describedby="nombresAyuda"
								/>
								<small
									id="nombresAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Indícanos tu nombre o si tienes más de uno. Ej: Ana Antonia."}
								</small>
							</div>
						)}
					</Field>
					<Field name="apellidoPaterno">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="apellidoPaterno" className="control-labelx">
									Apellido Paterno
								</label>
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="apellidoPaterno"
									name="apellidoPaterno"
									placeholder=""
									autoComplete="off"
									aria-describedby="apellidoPaternoAyuda"
								/>
								<small
									id="apellidoPaternoAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Indícanos tu apellido paterno. Ej: Soto."}
								</small>
							</div>
						)}
					</Field>
					<Field name="apellidoMaterno">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="apellidoMaterno" className="control-labelx">
									Apellido Materno (opcional)
								</label>
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="apellidoMaterno"
									name="apellidoMaterno"
									placeholder=""
									autoComplete="off"
									aria-describedby="apellidoMaternoAyuda"
								/>
								<small
									id="apellidoMaternoAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Indícanos tu apellido materno. Ej: Modelo."}
								</small>
							</div>
						)}
					</Field>
					<Field name="telefonoPrincipal">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="telefonoPrincipal" className="control-labelx">
									Teléfono contacto principal
								</label>
								<MaskedInput
									{...field}
									mask={celularMaskNew}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="telefonoPrincipal"
									name="telefonoPrincipal"
									placeholder=""
									autoComplete="off"
									aria-describedby="telefonoPrincipalAyuda"
								/>
								<small
									id="telefonoPrincipalAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Debes ingresar 9 dígitos. Ej: (+56) 9 1234 5678 o (+56) 2 1234 5678."}
								</small>
							</div>
						)}
					</Field>
					<Field name="telefonoSecundario">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="telefonoSecundario" className="control-labelx">
									Teléfono de contacto secundario (opcional)
								</label>
								<MaskedInput
									{...field}
									mask={celularMaskNew}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="telefonoSecundario"
									name="telefonoSecundario"
									placeholder=""
									autoComplete="off"
									aria-describedby="telefonoSecundarioAyuda"
								/>
								<small
									id="telefonoSecundarioAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Debes ingresar 9 dígitos. Ej: (+56) 9 1234 5678 o (+56) 2 1234 5678."}
								</small>
							</div>
						)}
					</Field>
					<Field name="correo">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="correo" className="control-labelx">
									Correo electrónico
								</label>
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="correo"
									name="correo"
									placeholder=""
									autoComplete="off"
									aria-describedby="correoAyuda"
								/>
								<small
									id="correoAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej: aumentatusueldo@afpmodelo.cl"}
								</small>
							</div>
						)}
					</Field>
					<Field name="correoConfirmar">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<label htmlFor="correoConfirmar" className="control-labelx">
									Confirmar correo electrónico
								</label>
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									id="correoConfirmar"
									name="correoConfirmar"
									placeholder=""
									autoComplete="off"
									aria-describedby="correoConfirmarAyuda"
								/>
								<small
									id="correoConfirmarAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej: aumentatusueldo@afpmodelo.cl"}
								</small>
							</div>
						)}
					</Field>
					<p>
						Selecciona una de estas opciones para identificarte como Mandatario:
					</p>
					<div id="accordion">
						<div className="custom-accordion">
							<div className="accordion-header" id="headingOne">
								<Field name="radioGroup">
									{() => (
										<div className="form-check">
											<label className="form-check-label mt-auto">
												<input
													type="radio"
													name="radioGroup"
													className="form-check-input"
													value="1"
													onChange={onValueChange}
												/>
												<small className="">Mandato notariado vigente.</small>
											</label>
										</div>
									)}
								</Field>
							</div>
							<div
								id="collapseOne"
								className={`collapse ${headingOneClass}`}
								aria-labelledby="headingOne"
								data-parent="#accordion"
							>
								<h5 className="custom-label-input">Subir documento</h5>
								<div className="accordion-body">
									<div className="row">
										<small>El documento debe estar en formato PDF.</small>
										<input
											type="file"
											id="fileOne"
											name="fileOne"
											onChange={handleFileOne}
											className="file-input"
											accept=".pdf"
										/>
									</div>
									<div className="">
										<a
											className="delete-file"
											onClick={() => {
												document.getElementById("fileOne").value = null;
												setFileOne(null);
												setTypeOne(0);
												setIsChecked(false);
											}}
										>
											<i>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													className="bi bi-trash"
													viewBox="0 0 16 16"
												>
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path
														fillRule="evenodd"
														d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
													/>
												</svg>
											</i>{" "}
											Eliminar
										</a>
									</div>
									<Alert
										image="warning"
										className="alert-secondary"
										message="Por favor adjunta un documento vigente."
									/>
									<div className="row">
										<Field name="termsOfService">
											{({ field, meta }) => (
												<div className="form-check">
													<label
														className="form-check-label mt-auto"
														htmlFor="termsOfService"
													>
														<input
															{...field}
															type="checkbox"
															className="form-check-input"
															id="termsOfService"
															name="termsOfService"
															onChange={() => {
																/*if(file !== null && file !== ""){
                                                                //setIsChecked(document.getElementById("termsOfService").checked);
                                                                setIsChecked(true);
                                                            } else {
                                                                setIsChecked(false);
                                                            }*/
															}}
														/>
														<small
															className={`form-text ${
																meta.touched && meta.error ? "is-invalid" : ""
															}`}
														>
															{meta.touched && meta.error
																? meta.error
																: "Estoy facultado por mandato para realizar el cobro del beneficio (El mandato debe indicar expresamente que está facultado para hacer el cobro del beneficio). "}
														</small>
													</label>
												</div>
											)}
										</Field>
									</div>
								</div>
							</div>
						</div>
						<div className="custom-accordion">
							<div className="accordion-header" id="headingTwo">
								<Field name="radioGroup">
									{() => (
										<div className="form-check">
											<label className="form-check-label mt-auto">
												<input
													type="radio"
													name="radioGroup"
													className="form-check-input"
													value="2"
													onChange={onValueChange}
												/>
												<small className="">
													Mandato Especial Simple y Declaración Jurada.
												</small>
											</label>
										</div>
									)}
								</Field>
							</div>
							<div
								id="collapseTwo"
								className={`collapse ${headingTwoClass}`}
								aria-labelledby="headingTwo"
								data-parent="#accordion"
							>
								<h5 className="custom-label-input">
									Subir Mandato Especial Simple
								</h5>
								<div className="accordion-body">
									<div className="row">
										<small>El documento debe estar en formato PDF.</small>
										<input
											type="file"
											id="fileTwo"
											name="fileTwo"
											onChange={handleFileTwo}
											className="file-input"
											accept=".pdf"
										/>
									</div>
									<div>
										<a
											className="delete-file"
											onClick={() => {
												document.getElementById("fileTwo").value = null;
												setFileTwo(null);
												setTypeTwo(0);
												setIsChecked(false);
											}}
										>
											<i>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													className="bi bi-trash"
													viewBox="0 0 16 16"
												>
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path
														fillRule="evenodd"
														d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
													/>
												</svg>
											</i>{" "}
											Eliminar
										</a>
									</div>
									<div>
										<a
											href="https://modelo.blob.core.windows.net/satelite/mandato_simple.pdf"
											target="blank_"
										>
											Haz click para descargar el formato del Mandato simple.
										</a>
									</div>
								</div>
								<h5 className="custom-label-input">Subir Declaración Jurada</h5>
								<div className="accordion-body">
									<div className="row">
										<small>El documento debe estar en formato PDF.</small>
										<input
											type="file"
											id="fileThree"
											name="fileThree"
											onChange={handleFileThree}
											className="file-input"
										/>
									</div>
									<div>
										<a
											className="delete-file"
											onClick={() => {
												document.getElementById("fileThree").value = null;
												setFileThree(null);
												setTypeThree(0);
												setIsChecked(false);
											}}
										>
											<i>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													className="bi bi-trash"
													viewBox="0 0 16 16"
												>
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path
														fillRule="evenodd"
														d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
													/>
												</svg>
											</i>{" "}
											Eliminar
										</a>
									</div>
									<div>
										<a
											href="https://modelo.blob.core.windows.net/satelite/declaracion_jurada.pdf"
											target="blank_"
										>
											Haz click para descargar el formato de la Declaración
											jurada.
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="custom-accordion">
							<div className="accordion-header" id="headingThree">
								<Field name="radioGroup">
									{() => (
										<div className="form-check">
											<label className="form-check-label mt-auto">
												<input
													type="radio"
													name="radioGroup"
													className="form-check-input"
													value="3"
													onChange={onValueChange}
												/>
												<small className="">
													AFP cuenta con Mandato notariado vigente.
												</small>
											</label>
										</div>
									)}
								</Field>
							</div>
							<div
								id="collapseThree"
								className={`collapse ${headingThreeClass}`}
								aria-labelledby="headingThree"
								data-parent="#accordion"
							>
								<div className="accordion-body">
									<Alert
										image="warning"
										className="alert-secondary"
										message="En caso que el mandato no se encuentre vigente, deberás actualizarlo."
									/>
									<Field name="termsOfService2">
										{({ field, meta }) => (
											<div className="form-check">
												<label
													className="form-check-label mt-auto"
													htmlFor="termsOfService2"
												>
													<input
														type="checkbox"
														className="form-check-input"
														id="termsOfService2"
														name="termsOfService2"
														{...field}
														onChange={() => {
															//setIsChecked(document.getElementById("termsOfService2").checked);
															setIsChecked(true);
														}}
													/>
													<small
														className={`form-text ${
															meta.touched && meta.error ? "is-invalid" : ""
														}`}
													>
														{meta.touched && meta.error
															? meta.error
															: "Estoy facultado por mandato para realizar el cobro del beneficio (El mandato debe indicar expresamente que está facultado para hacer el cobro del beneficio). "}
													</small>
												</label>
											</div>
										)}
									</Field>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row">
						<div className="col justify-content-center d-flex">
							{isLoading ? (
								<>
									<button
										type="button"
										className="btn-custom btn-lg btn-custom-primary"
										disabled
									>
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
									</button>
								</>
							) : (
								<button
									type="submit"
									id="continuar"
									className="btn-custom btn-lg btn-custom-primary"
									disabled={disable(formik)}
								>
									Continuar
								</button>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center d-flex blue-a ">
							<a
								onClick={() => {
									history.goBack();
								}}
							>
								Volver al paso anterior
							</a>
						</div>
					</div>

					<DocumentoModal show={modalShow} onHide={handleClose} />
				</Form>
			)}
		</Formik>
	);
};

export default FormMandatario;
