import { Field, Form, Formik } from "formik";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import documento from "../assets/img/NumeroDocumento.png";
import { handleIngresoEjecutivo } from "../utils/handleSubmit/formEjecutivo";
import { rutMask } from "../utils/inputMask";
import { validarRutEjecutivo } from "../utils/validationSchemaForm";

/* eslint react/prop-types: 0 */
export default function ModalEjecutivo(props) {
  const history = useHistory();

  const initialValues = {
    rutEjecutivo: "",
    idTipoIngresoCanal: "",
   
  };
  const handleSubmit = (fields) => {

    handleIngresoEjecutivo({
      values:fields,
      history:history
    })
  };
  return (
    <div>
      <Modal
        {...props}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Estás ingresando esta solicitud como ejecutivo de AFP Modelo.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Por favor, completa los siguientes datos:</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validarRutEjecutivo}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, setValues, isValid, dirty }) => (
              <Form>
                <span>RUT ejecutivo*</span>
                <Field name="rutEjecutivo">
                  {({ field, meta }) => (
                    <div className="form-group input-wrapper">
                      <MaskedInput
                        {...field}
                        mask={rutMask}
                        type="text"
                        className={`form-control form-control-lg ${
                          meta.touched
                            ? meta.error
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        name="rutEjecutivo"
                        aria-describedby="rutAyuda"
                        placeholder=""
                        autoComplete="off"
                      />

                      <small
                        id="rutEjecutivoAyuda"
                        className={`form-text ${
                          meta.touched && meta.error ? "is-invalid" : ""
                        }`}
                      >
                        {meta.touched && meta.error
                          ? meta.error
                          : "Ej. 12.345.678-9."}
                      </small>
                    </div>
                  )}
                </Field>{" "}
                <Field name="idTipoIngresoCanal">
                  {({ field, meta }) => (
                    
                    <div className="form-group input-wrapper select">
                      <small className="cuentasapv.0.checkcuentasapv small-formCuentas">
                        Canal de ingreso de la solicitud:
                      </small>
                      <div className="flex-align form-group input-wrapper">
                        <div className="form-check form-check-inline">
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            name="idTipoIngresoCanal"
                            id="inlineRadio1"
                            value="2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Sucursal.
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            name="idTipoIngresoCanal"
                            id="inlineRadio2"
                            value="3"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Contact Center.
                          </label>
                        </div>
                      </div>
                    </div>
                  
                  )}
                </Field>
                <Modal.Footer>
                  <Button
                    type="submit"
                    id="continuar"
                    className="btn-custom btn-lg btn-custom-primary"
                    disabled={!(isValid && dirty)}
                  >
                    Continuar con la solicitud
                  </Button>
               
                
                    <Button className="btn-cerrar" onClick={props.onHide}>Volver atrás</Button>
                  
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
