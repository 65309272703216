import "../assets/styles/claveSeguridad.css";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "react-text-mask";
import configuration from "../Configuration";
import PreDataFormBeneficiarios from "./FormPreData/formBeneficiarios";
import ModalAgregarDatosContacto from "./ModalAgregarDatosContacto";
import { validationSchemaBeneficiarios } from "../utils/validationSchemaForm";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { celularMaskNew, fechaMask, rutMask } from "../utils/inputMask";
import { Formik, Form, Field, FieldArray } from "formik";
import { initialValuesFormBeneficiarios } from "../utils/initialValues/formBeneficiarios";
import { handleSubmitFormBeneficiario } from "../utils/handleSubmit/formBeneficiarios";

const FormBeneficiarios = () => {
	let history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [parentescos, setParentescos] = useState();
	const [solicitud, setSolicitud] = useState({});
	const idCliente = localStorage.getItem("idCliente");
	const idPersona = localStorage.getItem("idPersona");
	const [nBeneficiarios, setNBeneficiarios] = useState(0);
	const [modalShow, setModalShow] = useState(false);
	const handleShow = () => setModalShow(true);
	const handleClose = () => setModalShow(false);

	useEffect(() => {
		const obtenerParentescos = async () => {
			const urlObtenerParentescos = `${configuration.API_URL}/Parentesco/ListarParentescos`;

			axios
				.get(urlObtenerParentescos)
				.then((response) => {
					setParentescos(response.data.resultado);
				})
				.catch((e) => {
					console.error("Error al obtener parentescos...");
					console.error(e);
				});
		};

		obtenerParentescos();
	}, []);

	useEffect(() => {
		const nBen_string = localStorage.getItem("nBeneficiarios");
		setNBeneficiarios(parseInt(nBen_string));
	}, [localStorage.getItem("nBeneficiarios")]);

	useEffect(() => {
		const idPersona = localStorage.getItem("idPersona");

		const obtenerDatosSolicitud = async () => {
			const urlObtenerSolicitudCliente = `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${idPersona}`;

			const datos_solititud = await axios
				.get(urlObtenerSolicitudCliente)
				.then((response) => {
					let data = response.data;

					if (data.resultado.length === 0) {
						console.info("sin datos...");
					}

					return response.data.resultado;
				})
				.catch((e) => {
					console.error(e);
				});
			setSolicitud(datos_solititud);
		};

		obtenerDatosSolicitud();
	}, []);

	function agregarBeneficiario(e, values, setValues) {
		const beneficiarios = [...values.beneficiarios];

		beneficiarios.push({
			rut: "",
			nombres: "",
			apellidoPaterno: "",
			apellidoMaterno: "",
			parentesco: "",
			hijoInvalido: "",
			fechaNacimiento: "",
			genero: "",
			telefonoContacto: "",
			correoElectronico: "",
		});
		setValues({ ...values, beneficiarios });
		setNBeneficiarios(nBeneficiarios + 1);
	}

	function eliminarBeneficiario(e, values, setValues, index) {
		Swal.fire({
			title: "¿Estas seguro de eliminar a este beneficiario?",
			text: "Si eliminas a este beneficiario, este ya no aparecerá en tu solicitud de pensión. ",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#72B500",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar.",
			cancelButtonText: `No, cancelar.`,
			allowOutsideClick: false,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const beneficiarios = [...values.beneficiarios];
				const idBeneficiario = beneficiarios[index].idBeneficiario;
				const url_delete = `${configuration.API_URL}/Beneficiarios/EliminarBeneficiario?idBeneficiario=${idBeneficiario}`;

				axios.delete(url_delete).then((response) => {
					setNBeneficiarios(nBeneficiarios - 1);
					beneficiarios.splice(index, 1);
					setValues({ ...values, beneficiarios });

					Swal.fire({
						title: "Beneficiario eliminado",
						text: "El beneficiario fue eliminado correctamente.",
						icon: "success",
						allowOutsideClick: false,
					});
				});
			}
		});
	}

	function volver() {
		history.goBack();
	}

	function handleSubmit(fields) {
		if (fields.beneficiarios.length !== 0) {
			setModalShow(false);
			handleSubmitFormBeneficiario({
				fields: fields,
				changeLoading: setIsLoading,
				solicitud: solicitud,
				history: history,
				idPersona: idPersona,
				idCliente: idCliente,
			});
		}
	}

	return (
		<>
			<Formik
				initialValues={initialValuesFormBeneficiarios}
				validationSchema={validationSchemaBeneficiarios}
				onSubmit={handleSubmit}
			>
				{({ errors, values, touched, setValues, isValid, dirty }) => (
					<Form>
						<PreDataFormBeneficiarios />

						<div className="form-group input-wrapper">
							<span className="label-checkbox">
								<strong>
									Selecciona esta opción en caso de no tener beneficiarios:
								</strong>
							</span>
							<Field name="checkBeneficiario">
								{({ field, meta }) => (
									<div className="form-check">
										<label
											className="form-check-label mt-4"
											htmlFor="checkBeneficiario"
										>
											<input
												type="checkbox"
												name="checkBeneficiario"
												{...field}
												className="form-check-input"
												autoComplete="off"
											/>
											<small className="checkBeneficiario small-formCuentas">
												Confirmo continuar con mi solicitud sin registrar
												beneficiarios.
											</small>
										</label>
										<small
											id="checkBeneficiarioAyuda"
											className={`form-text ${
												meta.touched && meta.error ? "is-invalid" : ""
											}`}
										>
											{meta.touched && meta.error ? meta.error : ""}
										</small>
									</div>
								)}
							</Field>
							{values.checkBeneficiario === false && (
								<>
									<FieldArray name="beneficiarios">
										{() =>
											values.beneficiarios.map((beneficiario, i) => {
												const beneficiarioErrors =
													(errors.beneficiarios?.length &&
														errors.beneficiarios[i]) ||
													{};
												const beneficiarioTouched =
													(touched.beneficiarios?.length &&
														touched.beneficiarios[i]) ||
													{};
												return (
													<div key={i} className="card mt-3">
														<div className="card-body">
															<h5 className="textoBeneficiario">
																Beneficiario {i + 1}
															</h5>
															<span>RUT</span>
															<Field name={`beneficiarios.${i}.rut`}>
																{({ field }) => (
																	<div className="form-group input-wrapper">
																		<MaskedInput
																			{...field}
																			mask={rutMask}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.rut
																					? beneficiarioErrors.rut
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.rut`}
																			aria-describedby={`beneficiarios.${i}.rutAyuda`}
																			placeholder=""
																			autoComplete="off"
																		/>
																		<label
																			htmlFor="rut"
																			className="control-label"
																		>
																			RUT
																		</label>
																		<small
																			id={`beneficiarios.${i}.rutAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.rut &&
																				beneficiarioErrors.rut
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.rut &&
																			beneficiarioErrors.rut
																				? beneficiarioErrors.rut
																				: "Ej. 12.345.678-9."}
																		</small>
																	</div>
																)}
															</Field>

															<span>Nombres</span>
															<Field name={`beneficiarios.${i}.nombres`}>
																{({ field }) => (
																	<div className="form-group input-wrapper">
																		<input
																			{...field}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.nombres
																					? beneficiarioErrors.nombres
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.nombres`}
																			aria-describedby={`beneficiarios.${i}.nombresAyuda`}
																			placeholder=""
																			autoComplete="off"
																		/>
																		<label
																			htmlFor={`beneficiarios.${i}.nombres`}
																			className="control-label"
																		>
																			Indícanos tu nombre o si tienes más de
																			uno. Ej: Ana Antonia.
																		</label>
																		<small
																			id={`beneficiarios.${i}.nombresAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.nombres &&
																				beneficiarioErrors.nombres
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.nombres &&
																			beneficiarioErrors.nombres
																				? beneficiarioErrors.nombres
																				: "Indícanos tu nombre o si tienes más de uno. Ej: Ana Antonia.."}
																		</small>
																	</div>
																)}
															</Field>

															<span>Apellido Paterno </span>
															<Field
																name={`beneficiarios.${i}.apellidoPaterno`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper">
																		<input
																			{...field}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.apellidoPaterno
																					? beneficiarioErrors.apellidoPaterno
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.apellidoPaterno`}
																			aria-describedby={`beneficiarios.${i}.apellidoPaternoAyuda`}
																			placeholder=""
																			autoComplete="off"
																		/>
																		<label
																			htmlFor={`beneficiarios.${i}.apellidoPaterno`}
																			className="control-label"
																		>
																			Indícanos tu apellido paterno. Ej: Soto.
																		</label>
																		<small
																			id={`beneficiarios.${i}.apellidoPaternoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.apellidoPaterno &&
																				beneficiarioErrors.apellidoPaterno
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.apellidoPaterno &&
																			beneficiarioErrors.apellidoPaterno
																				? beneficiarioErrors.apellidoPaterno
																				: "Indícanos tu apellido paterno. Ej: Soto."}
																		</small>
																	</div>
																)}
															</Field>
															<span>Apellido Materno (Opcional) </span>
															<Field
																name={`beneficiarios.${i}.apellidoMaterno`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper">
																		<input
																			{...field}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.apellidoMaterno
																					? beneficiarioErrors.apellidoMaterno
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.apellidoMaterno`}
																			aria-describedby={`beneficiarios.${i}.apellidoMaternoAyuda`}
																			placeholder=""
																			autoComplete="off"
																		/>
																		<label
																			htmlFor={`beneficiarios.${i}.apellidoMaterno`}
																			className="control-label"
																		>
																			Indícanos tu apellido materno. Ej: Tapia.
																		</label>
																		<small
																			id={`beneficiarios.${i}.apellidoMaternoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.apellidoMaterno &&
																				beneficiarioErrors.apellidoMaterno
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.apellidoMaterno &&
																			beneficiarioErrors.apellidoMaterno
																				? beneficiarioErrors.apellidoMaterno
																				: "Indícanos tu apellido materno. Ej: Tapia."}
																		</small>
																	</div>
																)}
															</Field>
															<span>Teléfono de contacto</span>
															<Field
																name={`beneficiarios.${i}.telefonoContacto`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper">
																		<MaskedInput
																			{...field}
																			type="text"
																			mask={celularMaskNew}
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.telefonoContacto
																					? beneficiarioErrors.telefonoContacto
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.telefonoContacto`}
																			aria-describedby={`beneficiarios.${i}.telefonoContactoAyuda`}
																			placeholder="(+56) _ ____ ____"
																			autoComplete="off"
																		/>
																		<label
																			htmlFor={`beneficiarios.${i}.telefonoContacto`}
																			className="control-label"
																		>
																			Ingresa 9 dígitos. Ej: 9-9123-4567 o
																			22-1234567
																		</label>
																		<small
																			id={`beneficiarios.${i}.telefonoContactoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.telefonoContacto &&
																				beneficiarioErrors.telefonoContacto
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.telefonoContacto &&
																			beneficiarioErrors.telefonoContacto
																				? beneficiarioErrors.telefonoContacto
																				: "Ingresa 9 dígitos. Ej: 9-9123-4567 o 22-1234567."}
																		</small>
																	</div>
																)}
															</Field>
															<span>Correo electrónico</span>
															<Field
																name={`beneficiarios.${i}.correoElectronico`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper">
																		<input
																			{...field}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.correoElectronico
																					? beneficiarioErrors.correoElectronico
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.correoElectronico`}
																			aria-describedby={`beneficiarios.${i}.correoElectronicoAyuda`}
																			placeholder=""
																			autoComplete="off"
																		/>
																		<label
																			htmlFor={`beneficiarios.${i}.correoElectronico`}
																			className="control-label"
																		>
																			Ej: aumentatusueldo@afpmodelo.cl
																		</label>
																		<small
																			id={`beneficiarios.${i}.correoElectronicoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.correoElectronico &&
																				beneficiarioErrors.correoElectronico
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.correoElectronico &&
																			beneficiarioErrors.correoElectronico
																				? beneficiarioErrors.correoElectronico
																				: "Ej: aumentatusueldo@afpmodelo.cl"}
																		</small>
																	</div>
																)}
															</Field>

															<Field name={`beneficiarios.${i}.parentesco`}>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper select">
																		<label
																			htmlFor={`beneficiarios.${i}.parentesco`}
																		>
																			Indique el parentesco:
																			<select
																				{...field}
																				className="form-control form-control-lg"
																				id={`beneficiarios.${i}.parentesco`}
																				name={`beneficiarios.${i}.parentesco`}
																			>
																				<option value="-1">
																					Seleccione un parentesco
																				</option>
																				{parentescos &&
																					parentescos.map(
																						(parentesco, index) => (
																							<option
																								key={index}
																								value={parentesco.codParentesco}
																							>
																								{
																									parentesco.descripcionParentesco
																								}
																							</option>
																						)
																					)}
																			</select>
																		</label>
																		<small
																			id={`beneficiarios.${i}.parentescoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.parentesco
																					? beneficiarioErrors.parentesco
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.parentesco &&
																			beneficiarioErrors.parentesco
																				? beneficiarioErrors.parentesco
																				: ""}
																		</small>
																	</div>
																)}
															</Field>
															<Field name={`beneficiarios.${i}.hijoInvalido`}>
																{({ field, meta }) => (
																	<div className="form-check mb-3">
																		<label
																			className="form-check-label"
																			htmlFor={`beneficiarios.${i}.hijoInvalido`}
																		>
																			<input
																				type="checkbox"
																				name={`beneficiarios.${i}.hijoInvalido`}
																				{...field}
																				className="form-check-input"
																				autoComplete="off"
																			/>
																			<small>
																				Hijo con invalidez total o parcial
																				dictaminada por una comisión médica.
																			</small>
																		</label>
																	</div>
																)}
															</Field>
															<span>Fecha de nacimiento.</span>
															<Field
																name={`beneficiarios.${i}.fechaNacimiento`}
															>
																{({ field }) => (
																	<div className="form-group input-wrapper">
																		<MaskedInput
																			{...field}
																			mask={fechaMask}
																			type="text"
																			className={`form-control form-control-lg ${
																				beneficiarioTouched.fechaNacimiento
																					? beneficiarioErrors.fechaNacimiento
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																			name={`beneficiarios.${i}.fechaNacimiento`}
																			aria-describedby={`beneficiarios.${i}.fechaNacimientoAyuda`}
																			placeholder="dd/mm/yyyy"
																			autoComplete="off"
																		/>
																		<label
																			htmlFor="fechaNacimiento"
																			className="control-label"
																		>
																			RUT
																		</label>
																		<small
																			id={`beneficiarios.${i}.fechaNacimientoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.fechaNacimiento &&
																				beneficiarioErrors.fechaNacimiento
																					? "is-invalid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.fechaNacimiento &&
																			beneficiarioErrors.fechaNacimiento
																				? beneficiarioErrors.fechaNacimiento
																				: "Ej: 01/12/1991."}
																		</small>
																	</div>
																)}
															</Field>
															<Field name={`beneficiarios.${i}.genero`}>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper select">
																		<label
																			htmlFor={`beneficiarios.${i}.genero`}
																		>
																			Indique el género:
																			<select
																				{...field}
																				className="form-control form-control-lg"
																				id={`beneficiarios.${i}.genero`}
																				name={`beneficiarios.${i}.genero`}
																			>
																				{" "}
																				{/* eslint-disable-line */}
																				<option value="99">
																					Seleccione un género
																				</option>
																				<option value="0">Masculino</option>
																				<option value="1">Femenino</option>
																			</select>
																		</label>
																		<small
																			id={`beneficiarios.${i}.generoAyuda`}
																			className={`form-text ${
																				beneficiarioTouched.genero
																					? beneficiarioErrors.genero
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																		>
																			{beneficiarioTouched.genero &&
																			beneficiarioErrors.genero
																				? beneficiarioErrors.genero
																				: ""}
																		</small>
																	</div>
																)}
															</Field>
															<div>
																<a
																	className="delete-file"
																	onClick={(e) =>
																		eliminarBeneficiario(
																			e,
																			values,
																			setValues,
																			i
																		)
																	}
																>
																	<i>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="16"
																			height="16"
																			fill="currentColor"
																			className="bi bi-trash"
																			viewBox="0 0 16 16"
																		>
																			<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
																			<path
																				fillRule="evenodd"
																				d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
																			/>
																		</svg>
																	</i>{" "}
																	Eliminar
																</a>
															</div>
														</div>
													</div>
												);
											})
										}
									</FieldArray>
									<div className="mt-4 text-center">
										<a
											onClick={(e) => agregarBeneficiario(e, values, setValues)}
											className="text-decoration-none"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="18"
												fill="#0B7AC7"
												className="bi bi-person-plus-fill"
												viewBox="0 0 16 16"
											>
												<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
												<path
													fillRule="evenodd"
													d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
												></path>
											</svg>
											<span className="blue">Agregar Beneficiario</span>
										</a>
									</div>
								</>
							)}
						</div>
						<div className="row">
							<div className="col justify-content-center d-flex">
								{isLoading ? (
									<>
										<button
											className="btn-custom btn-lg btn-custom-primary"
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
										</button>
									</>
								) : (
									<button
										onClick={() => {
											setModalShow(true);
										}}
										type="submit"
										className="btn-custom btn-lg btn-custom-primary"
										disabled={
											!((isValid && dirty) || values.checkBeneficiario === true)
										}
									>
										Continuar
									</button>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col justify-content-center d-flex blue-a">
								<a onClick={volver}>Volver al paso anterior</a>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<ModalAgregarDatosContacto show={modalShow} onHide={handleClose} />
		</>
	);
};

export default FormBeneficiarios;
