import {DateTime} from "luxon";

export const formatDate = (string) => {
    var options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
};

export const convertDate = (value) => {
    const auxFecha = value.split('/');
    return `${auxFecha[2]}-${auxFecha[1]}-${auxFecha[0]}T00:00:00.000Z`;
}

export function getToday (){
    var today = new Date();
    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
}

export const clearPreloadedDate =(fecha) => {
    let newDate = fecha.split('T');
    let date = newDate[0].split('-');

    return `${date[2]}/${date[1]}/${date[0]}`;
}

export const validDate = (fecha) => {
    let dt_inferior = DateTime.fromISO('1900-01-01');
    let dt_superior = DateTime.now();
    
    if(fecha !== undefined)
    {
        let aux = fecha.split('/');
        let demo = `${aux[2]}-${aux[1]}-${aux[0]}`;
        let dt_verificar = DateTime.fromISO(demo);
        return DateTime.fromISO(demo).isValid && dt_verificar > dt_inferior && dt_verificar <dt_superior;
    }

    return null;
}

export const validDateFinLicencia = (fechaInicioLicencia, fechaFinLicencia) => {

    let aux = fechaInicioLicencia.split('/');
    let demo = `${aux[2]}-${aux[1]}-${aux[0]}`;
    let dt_inicio_licencia = DateTime.fromISO(demo);

    let aux2 = fechaFinLicencia.split('/');
    let demo2 = `${aux2[2]}-${aux2[1]}-${aux2[0]}`;
    let dt_fin_licencia = DateTime.fromISO(demo2);

    return DateTime.fromISO(demo).isValid && DateTime.fromISO(demo2).isValid && dt_fin_licencia > dt_inicio_licencia;
}

export const validDateCeseFunciones = (fecha) =>{
    let dt_superior = DateTime.fromISO('2030-12-31');
    let dt_inferior = DateTime.now();

    if(fecha !== undefined)
    {
        let aux = fecha.split('/');
        let demo = `${aux[2]}-${aux[1]}-${aux[0]}`;
        let dt_verificar = DateTime.fromISO(demo);
        return DateTime.fromISO(demo).isValid && dt_verificar >= dt_inferior && dt_verificar <dt_superior;
    }

    return null;
}

