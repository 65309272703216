import { validationSchemaPaet } from "../utils/validationSchemaForm";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import MaskedInput from "react-text-mask";
import { rutMask } from "../utils/inputMask";
import React, { useState } from "react";
import Swal from "sweetalert2";
import configuration from "../Configuration";
import { oacento } from "../utils/caracteresUTF8";
import { cleanRut, convertirRutAId } from "../utils/cleanInputMask";

const FormConsultar = () => {
	const [isLoading, setIsLoading] = useState(false);

	const MySwal = Swal.mixin({
		customClass: {
			confirmButton: "btn-custom btn-lg btn-custom-primary",
			title: "font-weight-bold text-left",
			footer: "swal-footer",
		},
		buttonsStyling: false,
	});

	const initialValues = {
		rut: "",
		numerosolicitud: "",
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemaPaet}
			onSubmit={(values) => {
				setIsLoading(true);

				let rut = cleanRut(values.rut);
				let id = convertirRutAId(rut);

				axios({
					method: "GET",
					url: `${configuration.API_URL}/ConsultaFolio/DatosAfiliado?rutceros=${id}`,
					headers: configuration.JSON_HEADER,
				})
					.then((response) => {
						if (response.data.ok) {
							let tieneTramite =
								response.data.resultado.afiliado.indTieneTramite === "S";

							if (!tieneTramite) {
								MySwal.fire({
									title: "¡No pudimos validar los datos ingresados!",
									icon: "error",
									text: "Los datos ingresados no coinciden con nuestros registros de solicitud de pensión PAET. Por favor, verifica que sean correctos o hayas ingresado previamente una solicitud.",
									confirmButtonText: "Aceptar",
								}).then((result) => {
									setIsLoading(false);
								});
							} else {
								localStorage.setItem("idPersona", id);
								window.location = "comprobanteEstadoSolicitud";
								setIsLoading(false);
							}
						}
					})
					.catch((e) => {
						console.error(e);
					});
			}}
		>
			{(formik) => (
				<Form>
					<span className="label-text">
						RUT del afiliado, pensionado o beneficiario de pensi{oacento}n de
						sobrevivencia
					</span>
					<Field name="rut">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<MaskedInput
									{...field}
									mask={rutMask}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									name="rut"
									aria-describedby="rutAyuda"
									autoComplete="off"
								/>
								<small
									id="rutAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "Ej. 12.345.678-9."}
								</small>
							</div>
						)}
					</Field>
					<span className="label-text">Número de solicitud</span>
					<Field name="numerosolicitud">
						{({ field, meta }) => (
							<div className="form-group input-wrapper">
								<input
									{...field}
									type="text"
									className={`form-control form-control-lg ${
										meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
									}`}
									name="numerosolicitud"
									aria-describedby="numerosolicitudAyuda"
									autoComplete="off"
								/>
								<small
									id="numerosolicitudAyuda"
									className={`form-text ${
										meta.touched && meta.error ? "is-invalid" : ""
									}`}
								>
									{meta.touched && meta.error
										? meta.error
										: "El número que te entregamos cuando realizaste la solicitud. Ej: 1711034202103."}
								</small>
							</div>
						)}
					</Field>
					<div className="justify-content-center d-flex">
						{isLoading ? (
							<>
								<button
									type="button"
									className="btn-custom btn-lg btn-custom-primary"
									disabled
								>
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								</button>
							</>
						) : (
							<button
								type="submit"
								className="btn-custom btn-lg btn-custom-primary"
								disabled={!(formik.isValid && formik.dirty)}
							>
								Ver estado de solicitud
							</button>
						)}
					</div>
					<div className="row">
						<div className="col justify-content-center d-flex blue-a">
							<a href="/paet">Volver al paso anterior</a>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormConsultar;
