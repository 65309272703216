import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import { validationSchemaClaveSeguridad } from "../utils/validationSchemaForm";
import ClaveErrorModal from "./ClaveErrorModal.jsx";
import ClaveSeguridadModal from "./ClaveSeguridadModal.jsx";
import MaskedInput from "react-text-mask";
import { rutMask } from "../utils/inputMask";
import {oacento} from "../utils/caracteresUTF8";
import ButtonLoading from "./commons/buttonLoading";
import ButtonSubmit from "./commons/buttonSubmit";
import {initialValuesClaveSeguridad} from "../utils/initialValues/formClaveSeguridad";
import {handleSubmitClaveSeguridad} from "../utils/handleSubmit/formClaveSeguridad";
import ModalEjecutivo from "./ModalEjecutivo";

const FormClaveSeguridad = () => {

    let history = useHistory();
    
    const [modalShow, setModalShow] = useState(false);
    const [modalError, setmodalError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalEjecutivo, setShowModalEjecutivo] = useState(false)
    
    const handleClose = () => setModalShow(false);
    const handleError = () => setmodalError(false);
    const handleShow = () => setModalShow(true);
    const handleCloseE = () => setShowModalEjecutivo(false);

    function volver() {
        history.goBack();
    }

    const handleSubmit = (values) => {
        handleSubmitClaveSeguridad({
            values: values,
            changeLoading: setIsLoading,
            changeModal: setmodalError,
            showModalEjecutivo: setShowModalEjecutivo,
            history: history
        })
    }

    return (
        <Formik initialValues={initialValuesClaveSeguridad} validationSchema={validationSchemaClaveSeguridad} onSubmit={handleSubmit}>
            {(formik) => (
                <Form>
                    <div className="input-wrapper">
                        <Field name="rut">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <span>RUT del afiliado, pensionado o beneficiario de pensi{oacento}n de sobrevivencia</span>
                                    <MaskedInput
                                        {...field}
                                        mask={rutMask}
                                        type="text"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="rut"
                                        name="rut"
                                        aria-describedby="rutAyuda"
                                        placeholder=""
                                        autoComplete="off"/>
                                    <label htmlFor="rut" className="control-label">
                                        RUT
                                    </label>
                                    <small id="rutAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error ? meta.error : "Ej. 12.345.678-9."}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="clave">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <span> Clave de seguridad 
                                        <a className="text-decoration-none align-svg" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0d6efd"
                                                className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
                                            </svg>
                                        </a>
                                    </span>
                                    <input
                                        {...field}
                                        type="password"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="clave"
                                        name="clave"
                                        aria-describedby="claveAyuda"
                                        autoComplete="off" />
                                    <label htmlFor="clave" className="control-label">
                                        Clave Seguridad
                                    </label>
                                    <small id="claveAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error ? meta.error : "Esta es una segunda clave que debes haber creado previamente."}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="beneficiario">
                        {({ field, meta }) => (
                            <div className="form-check">
                                <span className="label-checkbox">Indícanos si eres beneficiario</span>
                                <label className="form-check-label mt-auto" htmlFor="beneficiario">
                                    <input
                                        type="checkbox"
                                        name="beneficiario"
                                        {...field}
                                        className="form-check-input"
                                        autoComplete="off"/>
                                    <small className="beneficiario">
                                        Soy beneficiario de una Pensión de Sobrevivencia.
                                    </small>
                                </label>
                                <small
                                    id="terminosAyuda"
                                    className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>{meta.touched && meta.error ? meta.error : ""}
                                </small>
                            </div>
                        )}
                    </Field>
                        <Field name="ejecutivoAfp">
                        {({ field, meta }) => (
                            <div className="form-check">
                                <span className="label-checkbox">Indícanos si eres ejecutivo de la AFP.</span>
                                <label className="form-check-label mt-auto" htmlFor="mandatario">
                                    <input
                                        type="checkbox"
                                        name="ejecutivoAfp"
                                        {...field}
                                        className="form-check-input"
                                        autoComplete="off"                                        
                                        />
                                    <small className="mandatario">
                                    Hago ingreso asistido de la solicitud como ejecutivo de AFP Modelo.
                                    </small>
                                </label>
                                <small
                                    id="mandatarioAyuda"
                                    className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>{meta.touched && meta.error ? meta.error : ""}
                                </small>
                            </div>
                        )}
                    </Field> 
                    </div>
                    <div className="justify-content-center d-flex mt-4">
                        {isLoading ? <ButtonLoading /> : <ButtonSubmit id_button={"claveSeguridad"} formik={formik} />}
                        <ClaveErrorModal show={modalError} onHide={handleError} />
                        <ClaveSeguridadModal show={modalShow} onHide={handleClose} />
                        <ModalEjecutivo show={showModalEjecutivo} onHide={handleCloseE}/>
                    </div>
                    <div className="row">
                        <div className="col justify-content-center d-flex">
                            <a className="volver" onClick={ volver }>Iniciar proceso con rut  y número de serie</a>
                        </div>
                    </div>   
                </Form>
            )}
        </Formik>
    );
};

export default FormClaveSeguridad;