import React from "react";
import "../assets/styles/header.css";

const Header = () => (
	<header className="sticky-top navbar-dark">
		<div className="bg-header-c">
			<div className="logo"></div>
		</div>
	</header>
);

export default Header;
