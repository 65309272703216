import { genericGet } from "../../../utils/genericApiCall";

export async function obtenerIndicadores(idPersona) {
    const url_endpoint = `/Bancos/obtenerIndicadores?idPersona=${idPersona}`;
    const message_info = 'Obteniendo indicadores...';
    const message_error = 'Error al obtener indicadores...';

    return await genericGet({
        url_endpoint: url_endpoint, 
        message_info: message_info, 
        message_error: message_error,
    });

}

export const traerBancos = async () => {
    
    const url_endpoint = '/Bancos/traerBancos';
    const message_info = 'Obteniendo lista de bancos...';
    const message_error = 'Error al obtener lista de bancos...';
    
    return await genericGet({
        url_endpoint: url_endpoint, 
        message_info: message_info, 
        message_error: message_error
    });

}