import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { validationSchemaCuentas } from "../utils/validationSchemaForm";
import MaskedInput from "react-text-mask";
import { dineroMask } from "../utils/inputMask";
import { Field, FieldArray, Form, Formik } from "formik";
import Swal from "sweetalert2";
import configuration from "../Configuration";
import "../assets/styles/claveSeguridad.css";
import addicon from "../assets/svg/add_plus.svg";
import trashIcon from "../assets/svg/trash.svg";
import { initialValuesFormCuentas } from "../utils/initialValues/formCuentas";
import { handleSubmitFormCuentas } from "../utils/handleSubmit/formCuentas";

const FormCuentas = () => {
	let history = useHistory();

	const Persona = {
		idPersona: localStorage.getItem("idPersona"),
		idCliente: localStorage.getItem("idCliente"),
	};

	const [instituciones, setInstituciones] = useState();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		localStorage.removeItem("productos");

		const obtenerDatosPrevisionales = async () => {
			axios
				.get(
					`${configuration.API_URL}/Beneficiarios/ObtenerTodosPorCliente?idCliente=${Persona.idCliente}
					&idPersona=${Persona.idPersona}`
				)
				.then((response) => {
					if (response.data.resultado.length === 0) {
						console.info("sin beneficiarios...");
					}
				})
				.catch((e) => {
					console.error(e);
				});
		};
		obtenerDatosPrevisionales();
	}, []);

	useEffect(() => {
		const obtenerInstituciones = async () => {
			await axios
				.get(`${configuration.API_URL}/Bancos/TraerInstitucionesFiltroModelo`)
				.then((response) => {
					setInstituciones(response.data.resultado);
					return;
				})
				.catch((e) => {
					console.error(e);
				});
		};
		obtenerInstituciones();
	}, []);

	function agregarCuenta(e, values, setValues, tipoCuenta) {
		switch (tipoCuenta) {
			case "cuentasapv":
				const cuentasapv = [...values.cuentasapv];
				cuentasapv.push({
					checkcuentasapv: "false",
					institucionapv: "",
					monto: "",
					total: "",
				});
				setValues({ ...values, cuentasapv });
				break;
			case "cuentasapv2":
				const cuentasapv2 = [...values.cuentasapv2];
				cuentasapv2.push({ institucionapv2: "", checkcuentasapv2: "false" });
				setValues({ ...values, cuentasapv2 });
				break;
			case "depositosconvenidos":
				const depositosconvenidos = [...values.depositosconvenidos];
				depositosconvenidos.push({
					institucionconvenidos: "",
					checkdepositosconvenido: "false",
				});
				setValues({ ...values, depositosconvenidos });
				break;
			case "cuentasapvcolectivo":
				const cuentasapvcolectivo = [...values.cuentasapvcolectivo];
				cuentasapvcolectivo.push({
					institucionapvcolectivo: "",
					checkcuentasapvcolectivo: "false",
				});
				setValues({ ...values, cuentasapvcolectivo });
				break;
		}
	}

	function eliminarCuenta(e, values, setValues, index, tipoCuenta, name) {
		Swal.fire({
			title: "¿Estas seguro de eliminar a esta cuenta?",
			text: "Si eliminas a esta cuenta, está ya no aparecerá en tu solicitud de pensión. ",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#72B500",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar.",
			cancelButtonText: `No, cancelar.`,
			allowOutsideClick: false,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				switch (tipoCuenta) {
					case "cuentasapv":
						const cuentasapv = [...values.cuentasapv];
						cuentasapv.splice(index, 1);
						setValues({ ...values, cuentasapv });
						break;
					case "cuentasapv2":
						const cuentasapv2 = [...values.cuentasapv2];
						cuentasapv2.splice(index, 1);
						setValues({ ...values, cuentasapv2 });
						break;
					case "depositosconvenidos":
						const depositosconvenidos = [...values.depositosconvenidos];
						depositosconvenidos.splice(index, 1);
						setValues({ ...values, depositosconvenidos });
						break;
					case "cuentasapvcolectivo":
						const cuentasapvcolectivo = [...values.cuentasapvcolectivo];
						cuentasapvcolectivo.splice(index, 1);
						setValues({ ...values, cuentasapvcolectivo });
						break;
				}

				Swal.fire({
					title: "Cuenta eliminada",
					text: "La cuenta fue eliminada correctamente.",
					icon: "success",
					allowOutsideClick: false,
				});
			}
		});
	}

	function volver() {
		history.goBack();
	}

	function handleSubmit(fields) {
		handleSubmitFormCuentas({
			fields: fields,
			Persona: Persona,
			history: history,
			changeLoading: setIsLoading,
		});
	}

	return (
		<Formik
			initialValues={initialValuesFormCuentas}
			validationSchema={validationSchemaCuentas}
			onSubmit={handleSubmit}
		>
			{(formik) => (
				<Form>
					<div className="form-group input-wrapper">
						{formik.values.cuentasapv.length === 0 && (
							<div className="mt-3">
								<a
									onClick={(e) =>
										agregarCuenta(
											e,
											formik.values,
											formik.setValues,
											"cuentasapv"
										)
									}
									className="text-decoration-none"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="18"
										fill="#0B7AC7"
										className="bi bi-plus-circle-fill"
										viewBox="0 0 16 16"
									>
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
									</svg>
									<span className="blue">
										Agregar otra cuenta Ahorro Voluntario
									</span>
								</a>
							</div>
						)}

						<FieldArray name="cuentasapv">
							{() =>
								formik.values.cuentasapv.map((cuentaapv, i) => {
									const cuentaapvErrors =
										(formik.errors.cuentasapv?.length &&
											formik.errors.cuentasapv[i]) ||
										{};
									const cuentaapvTouched =
										(formik.touched.cuentasapv?.length &&
											formik.touched.cuentasapv[i]) ||
										{};
									return (
										<div key={i} className="card mt-3">
											<div className="card-body">
												<Field name={`cuentasapv.${i}.checkcuentasapv`}>
													{({ field, meta }) => (
														<div className="form-group input-wrapper">
															<div className="form-check">
																<label
																	className="form-check-label"
																	htmlFor={`cuentasapv.${i}.checkcuentasapv`}
																>
																	<input
																		type="checkbox"
																		id={`cuentasapv.${i}.checkcuentasapv`}
																		name={`cuentasapv.${i}.checkcuentasapv`}
																		{...field}
																		className="form-check-input"
																		autoComplete="off"
																		onClick={(e) => {
																			document.getElementById(
																				`cuentasapv.${i}.checkcuentasapv`
																			).disabled = true;
																		}}
																	/>
																	<small
																		className={`cuentasapv.${i}.checkcuentasapv small-formCuentas`}
																	>
																		Solicito traspaso de mi cuenta de Ahorro
																		Previsional Voluntario (APV).
																	</small>
																</label>
																<small
																	id={`cuentasapv.${i}.checkcuentasapvAyuda`}
																	className={`form-text ${
																		cuentaapvTouched.checkcuentasapv &&
																		cuentaapvErrors.checkcuentasapv
																			? "is-invalid"
																			: ""
																	}`}
																>
																	{cuentaapvTouched.checkcuentasapv &&
																	cuentaapvErrors.checkcuentasapv
																		? cuentaapvErrors.checkcuentasapv
																		: ""}
																</small>
															</div>
														</div>
													)}
												</Field>
												{formik.values.cuentasapv[i].checkcuentasapv && (
													<>
														<h5 className="textoBeneficiario">{i + 1} . APV</h5>
														<Field name={`cuentasapv.${i}.institucionapv`}>
															{({ field, meta }) => (
																<div className="form-group input-wrapper select">
																	<label
																		htmlFor={`cuentasapv.${i}.institucionapv`}
																	>
																		Institución o AFP de origen:
																		<select
																			{...field}
																			className="form-control form-control-lg"
																			id={`cuentasapv.${i}.institucionapv`}
																			name={`cuentasapv.${i}.institucionapv`}
																		>
																			<option value="-1">
																				Seleccione una institución o AFP
																			</option>
																			{instituciones &&
																				instituciones.map(
																					(institucion, index) => (
																						<option
																							key={index}
																							value={
																								institucion.codInstFinanciera
																							}
																						>
																							{institucion.descripcion}
																						</option>
																					)
																				)}
																		</select>
																	</label>
																	<small
																		id={`cuentasapv.${i}.institucionapvAyuda `}
																		className={`form-text ${
																			cuentaapvTouched.institucionapv
																				? cuentaapvErrors.institucionapv
																					? "is-invalid"
																					: "is-valid"
																				: ""
																		}`}
																	>
																		{cuentaapvTouched.institucionapv &&
																		cuentaapvErrors.institucionapv
																			? cuentaapvErrors.institucionapv
																			: ""}
																	</small>
																</div>
															)}
														</Field>
														<span>Saldo a traspasar</span>
														<Field name={`cuentasapv.${i}.total`}>
															{({ field, meta }) => (
																<div className="form-group input-wrapper">
																	<div className="form-check form-check-inline">
																		<input
																			{...field}
																			className="form-check-input"
																			type="radio"
																			name={`cuentasapv.${i}.total`}
																			id="inlineRadio1"
																			value="1"
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="inlineRadio1"
																		>
																			Total
																		</label>
																	</div>
																	<div className="form-check form-check-inline">
																		<input
																			{...field}
																			className="form-check-input"
																			type="radio"
																			name={`cuentasapv.${i}.total`}
																			id="inlineRadio2"
																			value="2"
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="inlineRadio2"
																		>
																			Parcial
																		</label>
																	</div>
																</div>
															)}
														</Field>

														{formik.values.cuentasapv[i].total === "2" && (
															<>
																<span>Indica el monto (pesos)</span>
																<Field name={`cuentasapv.${i}.monto`}>
																	{({ field }) => (
																		<div className="form-group input-wrapper">
																			<MaskedInput
																				{...field}
																				mask={dineroMask}
																				type="text"
																				className={`form-control form-control-lg ${
																					cuentaapvTouched.monto
																						? cuentaapvErrors.monto
																							? "is-invalid"
																							: "is-valid"
																						: ""
																				}`}
																				name={`cuentasapv.${i}.monto`}
																				aria-describedby={`cuentasapv.${i}.montoAyuda`}
																				placeholder=""
																				autoComplete="off"
																			/>
																			<small
																				id={`cuentasapv.${i}.montoAyuda`}
																				className={`form-text ${
																					cuentaapvTouched.monto &&
																					cuentaapvErrors.monto
																						? "is-invalid"
																						: ""
																				}`}
																			>
																				{cuentaapvTouched.monto &&
																				cuentaapvErrors.monto
																					? cuentaapvErrors.monto
																					: ""}
																			</small>
																		</div>
																	)}
																</Field>
															</>
														)}
													</>
												)}

												<div className="row col-12">
													<div className="col-7">
														<a
															className="text"
															onClick={(e) =>
																agregarCuenta(
																	e,
																	formik.values,
																	formik.setValues,
																	"cuentasapv"
																)
															}
														>
															<img src={addicon}></img>
															<span className="blue">Agregar cuenta</span>
														</a>
													</div>
													<div className="col-5 text-right">
														<a
															className="text"
															onClick={(e) =>
																eliminarCuenta(
																	e,
																	formik.values,
																	formik.setValues,
																	i,
																	"cuentasapv"
																)
															}
														>
															<img src={trashIcon}></img>
															<span className="gray">Eliminar</span>
														</a>
													</div>
												</div>
											</div>
										</div>
									);
								})
							}
						</FieldArray>

						{formik.values.cuentasapv2.length === 0 && (
							<div className="mt-3">
								<a
									onClick={(e) =>
										agregarCuenta(
											e,
											formik.values,
											formik.setValues,
											"cuentasapv2"
										)
									}
									className="text-decoration-none"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="18"
										fill="#0B7AC7"
										className="bi bi-plus-circle-fill"
										viewBox="0 0 16 16"
									>
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
									</svg>
									<span className="blue">
										Agregar otra cuenta Ahorro Voluntario (Cuenta 2)
									</span>
								</a>
							</div>
						)}

						<FieldArray name="cuentasapv2">
							{() =>
								formik.values.cuentasapv2.map((cuentaapv2, i) => {
									const cuentaapv2Errors =
										(formik.errors.cuentasapv2?.length &&
											formik.errors.cuentasapv2[i]) ||
										{};
									const cuentaapv2Touched =
										(formik.touched.cuentasapv2?.length &&
											formik.touched.cuentasapv2[i]) ||
										{};
									return (
										<div key={i} className="card mt-3">
											<div className="card-body">
												<Field name={`cuentasapv2.${i}.checkcuentasapv2`}>
													{({ field, meta }) => (
														<div className="form-group input-wrapper">
															<div className="form-check">
																<label
																	className="form-check-label"
																	htmlFor={`cuentasapv2.${i}.checkcuentasapv2`}
																>
																	<input
																		type="checkbox"
																		id={`cuentasapv2.${i}.checkcuentasapv2`}
																		name={`cuentasapv2.${i}.checkcuentasapv2`}
																		{...field}
																		className="form-check-input"
																		autoComplete="off"
																		onClick={(e) => {
																			document.getElementById(
																				`cuentasapv2.${i}.checkcuentasapv2`
																			).disabled = true;
																		}}
																	/>
																	<small
																		className={`cuentasapv2.${i}.checkcuentasapv2 small-formCuentas`}
																	>
																		Solicito traspaso de mi Cuenta de Ahorro
																		Voluntario (Cuenta 2).
																	</small>
																</label>
																<small
																	id={`cuentasapv2.${i}.checkcuentasapv2Ayuda`}
																	className={`form-text ${
																		cuentaapv2Touched.checkcuentasapv &&
																		cuentaapv2Errors.checkcuentasapv
																			? "is-invalid"
																			: ""
																	}`}
																>
																	{cuentaapv2Touched.checkcuentasapv &&
																	cuentaapv2Errors.checkcuentasapv
																		? cuentaapv2Errors.checkcuentasapv
																		: ""}
																</small>
															</div>
														</div>
													)}
												</Field>

												{formik.values.cuentasapv2[i].checkcuentasapv2 && (
													<>
														<h5 className="textoBeneficiario">
															{i + 1} . Cuenta 2{" "}
														</h5>
														<Field name={`cuentasapv2.${i}.institucionapv2`}>
															{({ field, meta }) => (
																<div className="form-group input-wrapper select">
																	<label
																		htmlFor={`cuentasapv2.${i}.institucionapv2`}
																	>
																		Institución o AFP de origen:
																		<select
																			{...field}
																			className="form-control form-control-lg"
																			id={`cuentasapv2.${i}.institucionapv2`}
																			name={`cuentasapv2.${i}.institucionapv2`}
																		>
																			<option value="-1">
																				Seleccione una institución o AFP
																			</option>
																			{instituciones &&
																				instituciones.map(
																					(institucion, index) => (
																						<option
																							key={index}
																							value={
																								institucion.codInstFinanciera
																							}
																						>
																							{institucion.descripcion}
																						</option>
																					)
																				)}
																		</select>
																	</label>
																	<small
																		id={`cuentasapv2.${i}.institucionapv2Ayuda`}
																		className={`form-text ${
																			cuentaapv2Touched.institucionapv2
																				? cuentaapv2Errors.institucionapv2
																					? "is-invalid"
																					: "is-valid"
																				: ""
																		}`}
																	>
																		{cuentaapv2Touched.institucionapv2 &&
																		cuentaapv2Errors.institucionapv2
																			? cuentaapv2Errors.institucionapv2
																			: ""}
																	</small>
																</div>
															)}
														</Field>
													</>
												)}

												<div className="row col-12">
													<div className="col-7">
														<a
															className="text"
															onClick={(e) =>
																agregarCuenta(
																	e,
																	formik.values,
																	formik.setValues,
																	"cuentasapv2"
																)
															}
														>
															<img src={addicon}></img>
															<span className="blue">Agregar cuenta</span>
														</a>
													</div>

													<div className="col-5 text-right">
														<a
															className="text"
															onClick={(e) =>
																eliminarCuenta(
																	e,
																	formik.values,
																	formik.setValues,
																	i,
																	"cuentasapv2"
																)
															}
														>
															<img src={trashIcon}></img>
															<span className="gray">Eliminar</span>
														</a>
													</div>
												</div>
											</div>
										</div>
									);
								})
							}
						</FieldArray>

						{formik.values.depositosconvenidos.length === 0 && (
							<div className="mt-3">
								<a
									onClick={(e) =>
										agregarCuenta(
											e,
											formik.values,
											formik.setValues,
											"depositosconvenidos"
										)
									}
									className="text-decoration-none"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="18"
										fill="#0B7AC7"
										className="bi bi-plus-circle-fill"
										viewBox="0 0 16 16"
									>
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
									</svg>
									<span className="blue">
										Agregar otra cuenta de Depositos Covenidos
									</span>
								</a>
							</div>
						)}

						<FieldArray name="depositosconvenidos">
							{() =>
								formik.values.depositosconvenidos.map(
									(depositoconvenido, i) => {
										const depositoconvenidoErrors =
											(formik.errors.depositosconvenidos?.length &&
												formik.errors.depositosconvenidos[i]) ||
											{};
										const depositoconvenidoTouched =
											(formik.touched.depositosconvenidos?.length &&
												formik.touched.depositosconvenidos[i]) ||
											{};
										return (
											<div key={i} className="card mt-3">
												<div className="card-body">
													<Field
														name={`depositosconvenidos.${i}.checkdepositosconvenido`}
													>
														{({ field, meta }) => (
															<div className="form-group input-wrapper">
																<div className="form-check">
																	<label
																		className="form-check-label"
																		htmlFor={`depositosconvenidos.${i}.checkdepositosconvenido`}
																	>
																		<input
																			type="checkbox"
																			id={`depositosconvenidos.${i}.checkdepositosconvenido`}
																			name={`depositosconvenidos.${i}.checkdepositosconvenido`}
																			{...field}
																			className="form-check-input"
																			autoComplete="off"
																			onClick={(e) => {
																				document.getElementById(
																					`depositosconvenidos.${i}.checkdepositosconvenido`
																				).disabled = true;
																			}}
																		/>
																		<small
																			className={`depositosconvenidos.${i}.checkdepositosconvenido small-formCuentas`}
																		>
																			Solicito traspaso de mi cuenta de
																			Depósitos Convenidos.
																		</small>
																	</label>
																	<small
																		id={`depositosconvenidos.${i}.checkdepositosconvenidoAyuda`}
																		className={`form-text ${
																			depositoconvenidoTouched.checkdepositosconvenido &&
																			depositoconvenidoErrors.checkdepositosconvenido
																				? "is-invalid"
																				: ""
																		}`}
																	>
																		{depositoconvenidoTouched.checkdepositosconvenido &&
																		depositoconvenidoErrors.checkdepositosconvenido
																			? depositoconvenidoErrors.checkdepositosconvenido
																			: ""}
																	</small>
																</div>
															</div>
														)}
													</Field>
													{formik.values.depositosconvenidos[i]
														.checkdepositosconvenido && (
														<>
															<h5 className="textoBeneficiario">
																{i + 1} . Cuenta Depósito Convenido
															</h5>
															<Field
																name={`depositosconvenidos.${i}.institucionconvenidos`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper select">
																		<label
																			htmlFor={`depositosconvenidos.${i}.institucionconvenidos`}
																		>
																			Institución o AFP de origen:
																			<select
																				{...field}
																				className="form-control form-control-lg"
																				id={`depositosconvenidos.${i}.institucionconvenidos`}
																				name={`depositosconvenidos.${i}.institucionconvenidos`}
																			>
																				<option value="-1">
																					Seleccione una institución o AFP
																				</option>
																				{instituciones &&
																					instituciones.map(
																						(institucion, index) => (
																							<option
																								key={index}
																								value={
																									institucion.codInstFinanciera
																								}
																							>
																								{institucion.descripcion}
																							</option>
																						)
																					)}
																			</select>
																		</label>
																		<small
																			id={`depositosconvenidos.${i}.institucionconvenidosAyuda`}
																			className={`form-text ${
																				depositoconvenidoTouched.institucionconvenidos
																					? depositoconvenidoErrors.institucionconvenidos
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																		>
																			{depositoconvenidoTouched.institucionconvenidos &&
																			depositoconvenidoErrors.institucionconvenidos
																				? depositoconvenidoErrors.institucionconvenidos
																				: ""}
																		</small>
																	</div>
																)}
															</Field>
														</>
													)}
													<div className="row col-12">
														<div className="col-7">
															<a
																className="text"
																onClick={(e) =>
																	agregarCuenta(
																		e,
																		formik.values,
																		formik.setValues,
																		"depositosconvenidos"
																	)
																}
															>
																<img src={addicon}></img>
																<span className="blue">Agregar cuenta</span>
															</a>
														</div>
														<div className="col-5 text-right">
															<a
																className="text"
																onClick={(e) =>
																	eliminarCuenta(
																		e,
																		formik.values,
																		formik.setValues,
																		i,
																		"depositosconvenidos"
																	)
																}
															>
																<img src={trashIcon}></img>
																<span className="gray">Eliminar</span>
															</a>
														</div>
													</div>
												</div>
											</div>
										);
									}
								)
							}
						</FieldArray>

						{formik.values.cuentasapvcolectivo.length === 0 && (
							<div className="mt-3">
								<a
									onClick={(e) =>
										agregarCuenta(
											e,
											formik.values,
											formik.setValues,
											"cuentasapvcolectivo"
										)
									}
									className="text-decoration-none"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="18"
										fill="#0B7AC7"
										className="bi bi-person-plus-fill"
										viewBox="0 0 16 16"
									>
										<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
										<path
											fillRule="evenodd"
											d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
										></path>
									</svg>
									<span className="blue">Agregar otra cuenta APVC</span>
								</a>
							</div>
						)}

						<FieldArray name="cuentasapvcolectivo">
							{() =>
								formik.values.cuentasapvcolectivo.map(
									(cuentaapvcolectivo, i) => {
										const cuentaapvcolectivoErrors =
											(formik.errors.cuentasapvcolectivo?.length &&
												formik.errors.cuentasapvcolectivo[i]) ||
											{};
										const cuentaapvcolectivoTouched =
											(formik.touched.cuentasapvcolectivo?.length &&
												formik.touched.cuentasapvcolectivo[i]) ||
											{};
										return (
											<div key={i} className="card mt-3">
												<div className="card-body">
													<Field
														name={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo`}
													>
														{({ field, meta }) => (
															<div className="form-group input-wrapper">
																<div className="form-check">
																	<label
																		className="form-check-label"
																		htmlFor={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo`}
																	>
																		<input
																			type="checkbox"
																			id={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo`}
																			name={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo`}
																			{...field}
																			className="form-check-input"
																			autoComplete="off"
																			onClick={(e) => {
																				document.getElementById(
																					`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo`
																				).disabled = true;
																			}}
																		/>
																		<small
																			className={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivo small-formCuentas`}
																		>
																			Solicito traspaso de mi cuenta de Ahorro
																			Previsional Voluntario Colectivo (APVC).
																		</small>
																	</label>
																	<small
																		id={`cuentasapvcolectivo.${i}.checkcuentasapvcolectivoAyuda`}
																		className={`form-text ${
																			cuentaapvcolectivoTouched.checkcuentasapvcolectivo &&
																			cuentaapvcolectivoErrors.checkcuentasapvcolectivo
																				? "is-invalid"
																				: ""
																		}`}
																	>
																		{cuentaapvcolectivoTouched.checkcuentasapvcolectivo &&
																		cuentaapvcolectivoErrors.checkcuentasapvcolectivo
																			? cuentaapvcolectivoErrors.checkcuentasapvcolectivo
																			: ""}
																	</small>
																</div>
															</div>
														)}
													</Field>

													{formik.values.cuentasapvcolectivo[i]
														.checkcuentasapvcolectivo && (
														<>
															<h5 className="textoBeneficiario">
																{i + 1} . Cuenta de Ahorro Previsional
																Voluntario Colectivo (APVC)
															</h5>
															<Field
																name={`cuentasapvcolectivo.${i}.institucionapvcolectivo`}
															>
																{({ field, meta }) => (
																	<div className="form-group input-wrapper select">
																		<label
																			htmlFor={`cuentasapvcolectivo.${i}.institucionapvcolectivo`}
																		>
																			Institución o AFP de origen:
																			<select
																				{...field}
																				className="form-control form-control-lg"
																				id={`cuentasapvcolectivo.${i}.institucionapvcolectivo`}
																				name={`cuentasapvcolectivo.${i}.institucionapvcolectivo`}
																			>
																				<option value="-1">
																					Seleccione una institución o AFP
																				</option>
																				{instituciones &&
																					instituciones.map(
																						(institucion, index) => (
																							<option
																								key={index}
																								value={
																									institucion.codInstFinanciera
																								}
																							>
																								{institucion.descripcion}
																							</option>
																						)
																					)}
																			</select>
																		</label>
																		<small
																			id={`cuentasapvcolectivo.${i}.institucionapvcolectivoAyuda`}
																			className={`form-text ${
																				cuentaapvcolectivoTouched.institucionapvcolectivo
																					? cuentaapvcolectivoErrors.institucionapvcolectivo
																						? "is-invalid"
																						: "is-valid"
																					: ""
																			}`}
																		>
																			{cuentaapvcolectivoTouched.institucionapvcolectivo &&
																			cuentaapvcolectivoErrors.institucionapvcolectivo
																				? cuentaapvcolectivoErrors.institucionapvcolectivo
																				: ""}
																		</small>
																	</div>
																)}
															</Field>
														</>
													)}
													<div className="row col-12">
														<div className="col-7">
															<a
																className="text"
																onClick={(e) =>
																	agregarCuenta(
																		e,
																		formik.values,
																		formik.setValues,
																		"cuentasapvcolectivo"
																	)
																}
															>
																<img src={addicon}></img>
																<span className="blue">Agregar cuenta</span>
															</a>
														</div>
														<div className="col-5 text-right">
															<a
																className="text"
																onClick={(e) =>
																	eliminarCuenta(
																		e,
																		formik.values,
																		formik.setValues,
																		i,
																		"cuentasapvcolectivo",
																		`cuentasapvcolectivo.${i}.institucionapvcolectivo`
																	)
																}
															>
																<img src={trashIcon}></img>
																<span className="gray">Eliminar</span>
															</a>
														</div>
													</div>
												</div>
											</div>
										);
									}
								)
							}
						</FieldArray>

						<Field name="traspasoSaldo">
							{({ field, meta }) => (
								<div className="card mt-3 border border-danger rounded">
									<div className="card-body">
										<div className="form-group input-wrapper select">
											<small className="cuentasapv.0.checkcuentasapv small-formCuentas">
												Solicito traspaso de saldo desde la Administradora de
												Fondos de Cesantía. (Obligatorio)
											</small>
											<div className="form-group input-wrapper">
												<div className="form-check form-check-inline">
													<input
														{...field}
														className="form-check-input"
														type="radio"
														name="traspasoSaldo"
														id="inlineRadio1"
														value="si"
													/>
													<label
														className="form-check-label"
														htmlFor="inlineRadio1"
													>
														Si
													</label>
												</div>
												<div className="form-check form-check-inline">
													<input
														{...field}
														className="form-check-input"
														type="radio"
														name="traspasoSaldo"
														id="inlineRadio2"
														value="no"
													/>
													<label
														className="form-check-label"
														htmlFor="inlineRadio2"
													>
														No
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</Field>
					</div>

					<div className="row">
						<div className="col justify-content-center d-flex">
							{isLoading ? (
								<button
									className="btn-custom btn-lg btn-custom-primary"
									type="button"
									disabled
								>
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								</button>
							) : (
								<button
									type="submit"
									id="continuar"
									disabled={!(formik.isValid && formik.dirty)}
									className="btn-custom btn-lg btn-custom-primary"
								>
									Continuar
								</button>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center d-flex blue-a">
							<a onClick={volver}>Volver al paso anterior</a>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormCuentas;
