import React from "react";
import warning from "../assets/svg/exclamation-triangle.svg";
import success from "../assets/svg/check.svg";

const Alert = (props) => {

    function GetImage() {
        const image = props.image;
        
        if (image === "success") {
            return success;
        }
        if(image === "warning") {
            return warning;
        }
        
        return null;
    }

    return (
        <div className={`mt-3 alert ${props.className}`} role="alert">
            <div className="row">
                <div className="col-2 alert-svg">
                    <img src={GetImage()} width="42" height="32" />
                </div>
                <div className="col-10 avertical-align">
                    <div className="alerta">{props.message}</div>
                </div>
            </div>
        </div>
    )
}

export default Alert;