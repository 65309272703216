import React, { useEffect, useState } from "react";
import axios from 'axios';
import informationIcon from "../assets/svg/information.svg";
import { Formik, Form, Field, useFormikContext } from "formik";
import { validationSchemaDocumentosAfiliados } from "../utils/validationSchemaForm";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import GESModal from "./GesModal.jsx";
import ayuda from "../assets/img/ayuda.png";
import configuration from '../Configuration';
import { useHistory } from "react-router-dom";
import {initialValuesFormDocumentosAfiliados} from "../utils/initialValues/formDocumetosAfiliados";
import {validaExtension} from "../utils/helpers";

const FormDocumentosAfiliados = () => {

    let history = useHistory();
    
    const headers = {
        "Content-Type": "application/json"
    };

    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);
    const [isLoading, setIsLoading] = useState(false);

    const [servFolioSolicitud, setFolioSolicitud] = useState(localStorage.getItem('folioSolicitud'));
    const [idPersona, setIdPersona] = useState(localStorage.getItem("idPersona"));
    const [medicoFile, setMedicoFile] = useState("");
    const [gesFile, setGesFile] = useState("");
    const [cuidadosPaliativosFile, setCuidadosPaliativosFile] = useState("");
    const [anexoFile, setAnexoFile] = useState("");

    const [validarMedicoFile, setValidarMedicoFile] = useState(true)
    const [errorMedicoFile, setErrorMedicoFile] = useState(false);

    const [validarGesFile, setValidarGesFile] = useState(false);
    const [errorGesFile, setErrorGesFile] = useState(false);

    const [validarCuidadosPaliativos, setValidarCuidadosPaliativos] = useState(true);
    const [errorCuidadosPaliativosFile, setErrorCuidadosPaliativosFile] = useState(false);

    const [errorAnexoFile, setErrorAnexoFile] = useState(false);

    const [validaranexoFile, setValidarAnexoFile] = useState(false);

    const [count, setCount] = useState(0)
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if(count > 1){
            setDisabled(false);
        }
        else{
            setDisabled(true);
        }
    });

    const handleCuidadosPaliativosFile = event => {

        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarCuidadosPaliativos(false)
        }else{
            setValidarCuidadosPaliativos(true)
        }
        handleFile(archivo, 'CuidadosPaliativos', setErrorCuidadosPaliativosFile, setValidarCuidadosPaliativos, setCuidadosPaliativosFile);

    }

    const handleMedicoFile = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarMedicoFile(false)
        }else{
            setValidarMedicoFile(true)
        }
        handleFile(archivo, 'ConsejoMedico', setErrorMedicoFile, setValidarMedicoFile, setMedicoFile);

    };

    const handleFile = (archivo, tipoArchivo, setError, setValidarFile, setFile) => {
        if(archivo !== undefined){
            const extensionArchivo = archivo["name"] !== undefined ? archivo["name"].split("."):'';
            const isValido = validaExtension(extensionArchivo, setError);
        
            if(isValido){
                if(tipoArchivo === 'CuidadosPaliativos' || tipoArchivo === 'ConsejoMedico'){
                    setValidarFile(false);
                    setCount(count + 1);
                }
                setFile(archivo);
            }
            else{
                if(tipoArchivo === 'CuidadosPaliativos' || tipoArchivo === 'ConsejoMedico'){
                    if(count > 0){
                        setCount(count - 1);
                    }
                }
            }
        }
        else{
            if(tipoArchivo === 'CuidadosPaliativos' || tipoArchivo === 'ConsejoMedico'){
                if(count > 0){
                    setCount(count - 1);
                }
            }
        }
        

        
    }
    
    const validarExtencion = (path) => {   
        if(path === undefined){
            return false
        }

        if(path.type ==='application/pdf' || path.type ==='image/jpeg'){
            return true
        }else{
            return false
        }    
    }
    
    const handleGesFile = event => {

        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarGesFile(false)
        }else{
            setValidarGesFile(true)
        }
        handleFile(archivo, 'GES', setErrorGesFile, '', setGesFile);
    };

    const handleAnexoFile = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarAnexoFile(false)
        }else{
            setValidarAnexoFile(true)
        }
        handleFile(archivo, 'Anexo', setErrorAnexoFile, '', setAnexoFile);
    }

    const cleanGesFile = () => {
        document.getElementById("medicoFile").value = "";
        setGesFile(null);
    };

    const uploadFile = async (e) => {
                
        await axios({
            method: 'POST',
            url: `${configuration.API_URL}/UploadFiles/enviardocumento`,
            data: e
        }).then((response) => {
        }).catch(e => {
            console.error("/UploadFiles/enviardocumento: ", e);
        });

    }

    const tooltipCertificadoCuidadoPaliativo = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
            <div className="text-left">Este certificado debe ser entregado y firmado por el Jefe de la Unidad de Cuidados Paliativos del recinto de salud, donde se diagnosticó tu condición de enfermo terminal.</div>
        </Tooltip>
    );

    const tooltipAutorizacionGes = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
            <div className="text-left">Con este documento autorizas al Consejo Médico y al Consejo Médico de Apelación para que soliciten tus antecedentes y/o accedan a tus fichas clínicas con el fin de fundamentar la certificación de enfermo terminal.</div>
        </Tooltip>
    );

    const tooltipAntecedentes = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
        <div className="text-left">Puedes adjuntar los antecedentes que consideres relevantes que acréditen tu condición de enfermo terminal.</div>
        </Tooltip>
    ) 

    const handleSubmit = (values) => {
        setIsLoading(true);        

        if(cuidadosPaliativosFile){
            let archivoCuidados = new FormData();

            archivoCuidados.append("FolioSolicitud", servFolioSolicitud);
            archivoCuidados.append("IdPersona", idPersona);
            archivoCuidados.append("nombrearchivoMedico", cuidadosPaliativosFile.name)
            archivoCuidados.append("formFile", cuidadosPaliativosFile);
            archivoCuidados.append("TipoDocumento", 4);

            uploadFile(archivoCuidados);
            setCuidadosPaliativosFile(null);
        }

        if (medicoFile) {
            let archivoMedico = new FormData();

            archivoMedico.append("FolioSolicitud", servFolioSolicitud);
            archivoMedico.append("IdPersona", idPersona);
            archivoMedico.append("nombrearchivoMedico", medicoFile.name)
            archivoMedico.append("formFile", medicoFile);
            archivoMedico.append("TipoDocumento", 5);
            
            uploadFile(archivoMedico);
            setMedicoFile(null);

        }

        if (gesFile) {
            let archivoGes = new FormData();

            archivoGes.append("FolioSolicitud", servFolioSolicitud);
            archivoGes.append("IdPersona", idPersona);
            archivoGes.append("nombrearchivoGes", gesFile.name)
            archivoGes.append("formFile", gesFile);
            archivoGes.append("TipoDocumento", 6);

            uploadFile(archivoGes);
            setGesFile(null);
        }

        if(anexoFile) {
            let archivoAnexo = new FormData();

            archivoAnexo.append("FolioSolicitud", servFolioSolicitud);
            archivoAnexo.append("IdPersona", idPersona);
            archivoAnexo.append("nombrearchivoGes", anexoFile.name)
            archivoAnexo.append("formFile", anexoFile);
            archivoAnexo.append("TipoDocumento", 7);

            uploadFile(archivoAnexo);
            setAnexoFile(null);
        }

        history.push('/beneficiarios');
        
        setIsLoading(false);
    }

    return (
        <Formik initialValues={initialValuesFormDocumentosAfiliados} validationSchema={validationSchemaDocumentosAfiliados} onSubmit={handleSubmit}>
            {(formik) => (
                <Form>
                    <p> 1. Certificado emitido por el Jefe de
                        la Unidad de Cuidados Paliativos *&nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipCertificadoCuidadoPaliativo}>
                            <img src={informationIcon} alt="infoicon"  width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="cuidadosPaliativosFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">

                                <label htmlFor="cuidadosPaliativosFile" className="control-labelx">

                                    El documento debe estar en formato JPG o PDF.
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="cuidadosPaliativosFile"
                                        name="cuidadosPaliativosFile"
                                        onChange={handleCuidadosPaliativosFile}
                                        accept=".pdf, .jpg"
                                    />

                                    {errorCuidadosPaliativosFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}

                                </label>
                                <div>
                                    <a  className="delete-file" onClick={() => {
                                        setErrorCuidadosPaliativosFile(false)
                                        setValidarCuidadosPaliativos(true)
                                        document.getElementById("cuidadosPaliativosFile").value = null;
                                        setCuidadosPaliativosFile(null);

                                        if(count > 0){
                                            setCount(count - 1);
                                        }
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/retiros10porciento/Certificado_Medico.pdf" target="blank_">Haz click para descargar el formato del Certificado Médico.</a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p>  2. Autorización para los Consejos Médicos para la revisión de la ficha clínica activación GES *&nbsp;
                    <OverlayTrigger placement="top" overlay={tooltipAutorizacionGes}>
                    <img src={informationIcon} alt="infoicon" width="24" height="24" />
                </OverlayTrigger>
                    </p>
                    <Field name="medicoFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="medicoFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="medicoFile"
                                        name="medicoFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleMedicoFile}
                                    />
                                    {errorMedicoFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        setErrorMedicoFile(false)
                                        setValidarMedicoFile(true)
                                        document.getElementById("medicoFile").value = null;
                                        setMedicoFile(null);
                                        if(count > 0){
                                            setCount(count - 1);
                                        }
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/retiros10porciento/Autorizacion_Consejo_Medico.pdf" target="blank_">Haz click para descargar la autorización para el Consejo Médico.</a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p> 3. Certificado de activación GES (Opcional) &nbsp;
                        <a className="text-decoration-none align-svg" onClick={handleShow}>
                            <img className="icon ayuda" src={ayuda} alt="ayuda"/>
                        </a>
                    </p>
                    <Field name="gesFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="gesFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="gesFile"
                                        name="gesFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleGesFile}
                                    />
                                    {errorGesFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        setErrorGesFile(false)
                                        setValidarGesFile(false)
                                        document.getElementById("gesFile").value = null;
                                        setGesFile(null);
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/satelite/Formato-certificado-medico.pdf" target="blank_">Haz click para descargar el formulario AUGE-GES.</a>
                                </div>
                            </div>
                        )}
                    </Field>


                    <p>  4. Antecedentes que desee aportar (Opcional) &nbsp;
                    <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                    <img src={informationIcon} alt="infoicon" width="24" height="24" />
                </OverlayTrigger>
                    </p>
                    <Field name="anexoFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile"
                                        name="anexoFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile}
                                    />
                                    {errorAnexoFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        setErrorAnexoFile(false)
                                        setValidarAnexoFile(false)
                                        document.getElementById("anexoFile").value = null;
                                        setAnexoFile(null);
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>
                    <div className="d-flex justify-content-center">
                        <div className="col justify-content-center d-flex">
                            {isLoading
                                ? <>
                                    <button className="btn-custom btn-lg btn-custom-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                    </button>
                                </>
                                :
                                    <button type="submit" className="btn-custom btn-lg btn-custom-primary" disabled={ disabled }>
                                        Continuar
                                    </button>
                            }
                            <GESModal show={modalShow} onHide={handleClose} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col justify-content-center d-flex blue-a">
                            <a onClick={() => { history.goBack() }}>Volver al paso anterior</a>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
 
export default FormDocumentosAfiliados;
