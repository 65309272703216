import Swal from "sweetalert2";
import axios from "axios";
import configuration from "../../Configuration";
import { toNumberFromCurrency } from "../helpers";
import { obtenerSolicitud } from "../../services/api/solicitud";
import {
	eliminarSaldoExterno,
	ingresarSaldoExterno,
} from "../../services/api/saldos";

function actualizarSolicitud(fields, datos, history) {
	fields.cuentasapv.forEach(function (value) {
		if (value.checkcuentasapv) {
			let isCuentasApvModelo = value.institucionapv === "1034";

			datos.indCotvoluntariaExterna = isCuentasApvModelo ? "N" : "S";
			datos.indCotvoluntariaInterna = isCuentasApvModelo ? "S" : "N";
			datos.indSolTraspasoAhorroExt = isCuentasApvModelo ? "N" : "S";
		} else if (
			fields.cuentasapv.length === 1 &&
			value.checkcuentasapv === false
		) {
			datos.indSolTraspasoAhorroExt = "N";
			datos.indCotvoluntariaInterna = "N";
			datos.indCotvoluntariaExterna = "N";
		}
	});

	fields.cuentasapv2.forEach(function (value) {
		if (value.checkcuentasapv2) {
			let isCuenta2Modelo = value.institucionapv2 === "1034";
			datos.indSolicitaTraspasoAhorro = isCuenta2Modelo ? "N" : "S";
		} else if (
			fields.cuentasapv2.length === 1 &&
			value.checkcuentasapv2 === false
		) {
			datos.indSolicitaTraspasoAhorro = "N";
		}
	});

	fields.depositosconvenidos.forEach(function (value) {
		if (value.checkdepositosconvenido) {
			let isConvenidoModelo = value.institucionconvenidos === "1034";
			datos.indDepconvenidoExterno = isConvenidoModelo ? "N" : "S";
		} else if (
			fields.cuentasapv2.length === 1 &&
			value.checkcuentasapv2 === false
		) {
			datos.indDepconvenidoExterno = "N";
		}
	});

	fields.cuentasapvcolectivo.forEach(function (value) {
		if (value.checkcuentasapvcolectivo) {
			let isColectivoModelo = value.institucionapvcolectivo === "1034";
			datos.indCotVolCvcExterna = isColectivoModelo ? "N" : "S";
		} else if (
			fields.cuentasapvcolectivo.length === 1 &&
			value.checkcuentasapvcolectivo === false
		) {
			datos.indCotVolCvcExterna = "N";
		}
	});

	if (fields.traspasoSaldo === "si") {
		datos.indCuentaSeguroCesantia = "S";
		datos.tipoTraspCuentaSegCes = "T";
	} else {
		datos.indCuentaSeguroCesantia = "N";
		datos.tipoTraspCuentaSegCes = "N";
	}

	axios({
		method: "PUT",
		url: `${configuration.API_URL}/Solicitud/actualizarSolicitud`,
		data: datos,
		headers: configuration.JSON_HEADER,
	})
		.then((response) => {
			if (response.data.ok) {
				Swal.fire({
					title: "¡Datos de Cuentas ingresadas correctamente!",
					icon: "success",
					text: "Serás redirigido a un formulario donde debes registrar la forma de pago.",
					confirmButtonText: "Continuar",
					allowOutsideClick: false,
				}).then((result) => {
					if (result.value) {
						history.push("/formaPago");
					}
				});
			}
		})
		.catch((e) => {
			console.error(e);
		});
}

function agregarSaldos(
	institucion,
	producto,
	monto,
	tipo,
	Persona,
	folioSolicitud
) {
	let data = {
		idPersona: Persona.idPersona,
		idCliente: parseInt(Persona.idCliente),
		codAdmTraspasoExterno: parseInt(institucion),
		tipoFondo: "A",
		tipoMoneda: "P",
		tipoProducto: producto,
		valTraspasoExterno: parseInt(monto),
		tipoTraspaso: tipo,
		folioSolicitud: folioSolicitud,
	};

	const ingresar_saldo = ingresarSaldoExterno(data);

	return ingresar_saldo;
}

function Repeated(array, type) {
	if (type === "cuentasapv") {
		for (var i = 0; i < array.length; i++) {
			let a = array[i].institucionapv;

			for (var j = 0; j < array.length; j++) {
				let b = array[j].institucionapv;

				if (a === b && i !== j) {
					return true;
				}
			}
		}
	}

	if (type === "cuentasapv2") {
		for (var i = 0; i < array.length; i++) {
			let a = array[i].institucionapv2;

			for (var j = 0; j < array.length; j++) {
				let b = array[j].institucionapv2;

				if (a === b && i !== j) {
					return true;
				}
			}
		}
	}

	if (type === "depositosconvenidos") {
		for (var i = 0; i < array.length; i++) {
			let a = array[i].institucionconvenidos;

			for (var j = 0; j < array.length; j++) {
				let b = array[j].institucionconvenidos;

				if (a === b && i !== j) {
					return true;
				}
			}
		}
	}

	if (type === "cuentasapvcolectivo") {
		for (var i = 0; i < array.length; i++) {
			let a = array[i].institucionapvcolectivo;

			for (var j = 0; j < array.length; j++) {
				let b = array[j].institucionapvcolectivo;

				if (a === b && i !== j) {
					return true;
				}
			}
		}
	}

	return false;
}

export const handleSubmitFormCuentas = ({
	fields,
	Persona,
	history,
	changeLoading,
}) => {
	changeLoading(true);

	let repeated = true;

	let cuentasapv = Repeated(fields.cuentasapv, "cuentasapv");
	let cuentasapv2 = Repeated(fields.cuentasapv2, "cuentasapv2");
	let depositosconvenidos = Repeated(
		fields.depositosconvenidos,
		"depositosconvenidos"
	);
	let cuentasapvcolectivo = Repeated(
		fields.cuentasapvcolectivo,
		"cuentasapvcolectivo"
	);

	if (
		cuentasapv === false &&
		cuentasapv2 === false &&
		depositosconvenidos === false &&
		cuentasapvcolectivo === false
	) {
		repeated = false;
	}

	if (repeated) {
		Swal.fire({
			title: "Ingreso de cuentas.",
			text: "Posee cuentas repetidas, debe corregirlas para continuar.",
			icon: "warning",
			showCancelButton: false,
			confirmButtonColor: "#72B500",
			cancelButtonColor: "#d33",
			confirmButtonText: "Continuar",
			cancelButtonText: `Volver a Cuentas`,
			allowOutsideClick: false,
			reverseButtons: true,
		});

		changeLoading(false);
	} else {
		Swal.fire({
			title: "Confirmación de ingreso de cuentas.",
			text: "Por favor, antes de continuar asegurate de haber ingresado correctamente las cuentas.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#72B500",
			cancelButtonColor: "#d33",
			confirmButtonText: "Continuar",
			cancelButtonText: `Volver a Cuentas`,
			allowOutsideClick: false,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				obtenerSolicitud(Persona.idPersona)
					.then((response) => {
						if (response.data.ok) {
							let idSolicitud = response.data.resultado.folioSolicitud;

							eliminarSaldoExterno(idSolicitud);

							fields.cuentasapv.forEach(function (value) {
								if (value.checkcuentasapv) {
									agregarSaldos(
										value.institucionapv,
										"CCV",
										value.monto === "" ? 0 : toNumberFromCurrency(value.monto),
										value.total === "1" ? "T" : "P",
										Persona,
										idSolicitud
									);
								}
							});

							fields.cuentasapv2.forEach(function (value) {
								if (value.checkcuentasapv2) {
									agregarSaldos(
										value.institucionapv2,
										"CAV",
										"0",
										"T",
										Persona,
										idSolicitud
									);
								}
							});

							fields.cuentasapvcolectivo.forEach(function (value) {
								if (value.checkcuentasapvcolectivo) {
									agregarSaldos(
										value.institucionapvcolectivo,
										"CVC",
										"0",
										"T",
										Persona,
										idSolicitud
									);
								}
							});

							fields.depositosconvenidos.forEach(function (value) {
								if (value.checkdepositosconvenido) {
									agregarSaldos(
										value.institucionconvenidos,
										"CDC",
										"0",
										"T",
										Persona,
										idSolicitud
									);
								}
							});

							actualizarSolicitud(fields, response.data.resultado, history);
							changeLoading(false);
						}
					})
					.catch((e) => {
						console.error(e);
						changeLoading(false);
					});
			} else {
				changeLoading(false);
			}
		});

		changeLoading(false);
	}
};
