import React from "react";

const ButtonLoading = () => {

    return (
        <button className="btn-custom btn-lg btn-custom-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
        </button>
    );
};

export default ButtonLoading;