var ctaBancaria = new Map();
ctaBancaria.set("B", "Cuenta Corriente");
ctaBancaria.set("A", "Cuenta Ahorro");
ctaBancaria.set("P", "Cuenta Electronica" );
ctaBancaria.set("V", "Cuenta Vista");
class cuentaBancariaHelper {
  getCuenta (value) {    
    return ctaBancaria.get(value)
  };
 
}

export default new cuentaBancariaHelper();