import React from "react";
import Helmet from "react-helmet";
import { FormSolicitud } from "../../components/FormIngresoSolicitud";
export const Login = () => {
    return(
        <div className="container home">
        <Helmet>
        <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
        <meta name="description" content={``} />
        <meta name="robots" content="noindex, follow" />
    </Helmet>
    <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal</div>
                                    <span className="textoSecundario">Haz tu selección aquí</span>
                                    <div className="card-body">        
                                    <FormSolicitud />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}