export const initialValuesFormCuentas = {
    cuentasapv: [{
        institucionapv: '',
        monto: '',
        total: '',
        checkcuentasapv: false
    }],
    cuentasapv2: [{
        institucionapv2: '',
        checkcuentasapv2: false
    }],
    depositosconvenidos: [{
        institucionconvenidos: '',
        checkdepositosconvenido: false
    }],
    cuentasapvcolectivo: [{
        institucionapvcolectivo: '',
        checkcuentasapvcolectivo: false
    }],
    traspasoSaldo: ''
};