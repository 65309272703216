import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReCaptcha from "../components/FormNumeroSerie";
import Alert from "../utils/alert";
import configuration from "../Configuration";

export default function Home() {
	const [token, setToken] = useState("");

	useEffect(() => {
		const obtenerTokenInicial = async () => {
			const response = await axios.get(
				`${configuration.API_URL}/Token/GenerateToken`
			);
			setToken(response.data.key);
		};

		obtenerTokenInicial();
	}, []);

	return (
		<div className="container home">
			<Helmet>
				<title>
					Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos
					Terminales | AFP Modelo
				</title>
				<meta name="description" content={``} />
				<meta name="robots" content="noindex, follow" />
			</Helmet>
			<div>
				<section>
					<div className="row w-100 container-padre calculadora">
						<div className="row">
							<div className="bloque form">
								<div className="card formulario">
									<div className="card-title textoTitulo">
										Formulario de Retiro de Pensión Anticipada por Enfermedad
										Terminal
									</div>
									<span className="textoSecundario">
										Comienza tu solicitud aquí.
									</span>
									<div className="card-body">
										<ReCaptcha />

										<Alert
											image="warning"
											className="alert-secondary"
											message={[
												"Este portal es ",
												<strong key="s">
													exclusivo para los Afiliados, Pensionados AFP Modelo.
												</strong>,
												" Si no sabes a qué AFP estás afiliado, ",
												<a
													key="a"
													href="https://www.spensiones.cl/apps/certificados/formConsultaAfiliacion.php"
													target="blank_"
													className="green-a"
												>
													haz click aquí
												</a>,
												".",
											]}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
