import React, { useState, useEffect } from "react";
import axios from 'axios';
import informationIcon from "../assets/svg/information.svg";
import { Formik, Form, Field } from "formik";
import { validationSchemaDocumentosAfiliados } from "../utils/validationSchemaForm";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import GESModal from "./GesModal.jsx";
import ayuda from "../assets/img/ayuda.png";
import configuration from '../Configuration';
import { useHistory } from "react-router-dom";
import {initialValuesFormDocumentosAfiliados} from "../utils/initialValues/formDocumetosAfiliados";
import {convertDniToID, validaExtension} from "../utils/helpers";
import {obtenerSolicitud} from "../services/api/solicitud";

const FormDocumentosAfiliadosV2 = () => {

    let history = useHistory();
    
    const headers = {
        "Content-Type": "application/json"
    };

    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);
    const [isLoading, setIsLoading] = useState(false);

    const [servFolioSolicitud, setFolioSolicitud] = useState(localStorage.getItem('folioSolicitud'));
    const [idPersona, setIdPersona] = useState(localStorage.getItem("idPersona"));
    const [medicoFile, setMedicoFile] = useState("");
    const [gesFile, setGesFile] = useState("");
    const [cuidadosPaliativosFile, setCuidadosPaliativosFile] = useState("");
    const [anexoFile, setAnexoFile] = useState("");
    const [anexoFile2, setAnexoFile2] = useState("");
    const [anexoFile3, setAnexoFile3] = useState("");
    const [anexoFile4, setAnexoFile4] = useState("");
    const [anexoFile5, setAnexoFile5] = useState("");

    const [validarMedicoFile, setValidarMedicoFile] = useState(true)
    const [errorMedicoFile, setErrorMedicoFile] = useState(false);

    const [validarGesFile, setValidarGesFile] = useState(true);
    const [errorGesFile, setErrorGesFile] = useState(false);

    const [validarCuidadosPaliativos, setValidarCuidadosPaliativos] = useState(true);
    const [errorCuidadosPaliativosFile, setErrorCuidadosPaliativosFile] = useState(false);

    const [errorAnexoFile, setErrorAnexoFile] = useState(false);
    const [errorAnexoFile2, setErrorAnexoFile2] = useState(false);
    const [errorAnexoFile3, setErrorAnexoFile3] = useState(false);
    const [errorAnexoFile4, setErrorAnexoFile4] = useState(false);
    const [errorAnexoFile5, setErrorAnexoFile5] = useState(false);

    const [validarAnexo, setValidarAnexo]   = useState(true);
    const [validarAnexo2, setValidarAnexo2] = useState(true);
    const [validarAnexo3, setValidarAnexo3] = useState(true);
    const [validarAnexo4, setValidarAnexo4] = useState(true);
    const [validarAnexo5, setValidarAnexo5] = useState(true);

    const [errores, setErrores] = useState(1)
    const [disabled, setDisabled] = useState(true);

    const [count, setCount] = useState(0)

    const [extencionBotones, setExtencionBotones] = useState([true,true,true,true,true,true,true,true])

    const  cantidadDeDocs  =  []

    useEffect(() => {
        if(count > 0){
            setDisabled(false);
        }
        else{
            setDisabled(true);
        }
    });

    const validarBtn = () => {
        /*if(extencionBotones.includes(false)){
            setDisabled(true);
        }else{
            setDisabled(false);
        } */
    }

    const handleDelete = (setValidarFile,idFile,setFile,setErrorFile, errorFile, index ) => {
        var path = document.getElementById(idFile).value;
        
        if(path !== ""){
            document.getElementById(idFile).value = null;
            setFile(null);
            setErrorFile(false)
            extencionBotones[index] = true
            setValidarFile(true)
            
            /*if(extencionBotones.includes(false)){
                setDisabled(true);
            }else{
                setDisabled(false)
            }*/

            if(count > 0){
                setCount(count - 1);
            }

            let pos = cantidadDeDocs.indexOf(idFile)
            cantidadDeDocs.splice(pos, 1)
        }
        
    }

    const handleCuidadosPaliativosFile = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarCuidadosPaliativos(false)
            extencionBotones[0] = true
        }else{
            setValidarCuidadosPaliativos(true)
            extencionBotones[0] = false
        }
        handleFile(archivo, 'CuidadosPaliativos', setErrorCuidadosPaliativosFile, setValidarCuidadosPaliativos, setCuidadosPaliativosFile);
        validarBtn()
    }

    const handleMedicoFile = event => { 
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarMedicoFile(false)
            extencionBotones[1] = true
        }else{
            setValidarMedicoFile(true)
            extencionBotones[1] = false
        }
        handleFile(archivo, 'ConsejoMedico', setErrorMedicoFile, setValidarMedicoFile, setMedicoFile);
        validarBtn()
    };

    const handleFile = (archivo, tipoArchivo, setError, setValidarFile, setFile) => {        
        if(archivo !== undefined){
            const extensionArchivo = archivo["name"] !== undefined ? archivo["name"].split("."):'';        
            const isValido = validaExtension(extensionArchivo, setError);
            
            if(isValido){
                setFile(archivo);
                setCount(count + 1);
            }else{
                setErrores(errores + 1)

                if(count > 0){
                    setCount(count - 1);
                }
            }
        }
        else{
            if(count > 0){
                setCount(count - 1);
            }
        }
    }

    const validarExtencion = (path) => {           
        if(path === undefined){
            return false
        }

        if(path.type ==='application/pdf' || path.type ==='image/jpeg'){
            return true
        }else{
            return false
        }    
    }
    const handleGesFile = event => {
        
        const archivo = event.target.files[0]; 
        if(validarExtencion(archivo)){
            setValidarGesFile(false)
            extencionBotones[2] = true
            cantidadDeDocs[2] = "anexoFile"
        }else{
            setValidarGesFile(true)
            extencionBotones[2] = false
        }
        handleFile(archivo, 'GES', setErrorGesFile, '', setGesFile);
        validarBtn()
    };

    const handleAnexoFile = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarAnexo(false)
            extencionBotones[3] = true
            cantidadDeDocs[3] = "anexoFile1"
        }else{
            setValidarAnexo(true)
            extencionBotones[3] = false
        }
        handleFile(archivo, 'Anexo', setErrorAnexoFile, '', setAnexoFile);
        validarBtn()
    }

    const handleAnexoFile2 = event => {
        
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarAnexo2(false)
            cantidadDeDocs[4] = "anexoFile2";
            extencionBotones[4] = true;
        }else{
            setValidarAnexo2(true)
            extencionBotones[4] = false
        }
        handleFile(archivo, 'Anexo2', setErrorAnexoFile2, '', setAnexoFile2);
        validarBtn();
    }

    const handleAnexoFile3 = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarAnexo3(false);
            extencionBotones[5] = true;
            cantidadDeDocs[5] = "anexoFile3";
        }else{
            setValidarAnexo3(true)
            extencionBotones[5] = false
        }
        handleFile(archivo, 'Anexo3', setErrorAnexoFile3, '', setAnexoFile3);
        validarBtn();
    }

    const handleAnexoFile4 = event => {        
        const archivo = event.target.files[0];

        if(validarExtencion(archivo)){
            setValidarAnexo4(false);
            extencionBotones[6] = true;
            cantidadDeDocs[6] = "anexoFile4";
        }else{
            setValidarAnexo4(true);
            extencionBotones[6] = false;
        }
        handleFile(archivo, 'Anexo4', setErrorAnexoFile4, '', setAnexoFile4);
        validarBtn();
    }

    const handleAnexoFile5 = event => {
        const archivo = event.target.files[0];
        if(validarExtencion(archivo)){
            setValidarAnexo5(false);
            extencionBotones[7] = true;
            cantidadDeDocs[7] = "anexoFile5";
        }else{
            setValidarAnexo5(true);
            extencionBotones[7] = false;
        }
        handleFile(archivo, 'Anexo5', setErrorAnexoFile5, '', setAnexoFile5);
        validarBtn();
    }

    const cleanGesFile = () => {
        document.getElementById("medicoFile").value = "";
        setGesFile(null);
    };

    const uploadFile = async (e) => {
        
        await axios({
            method: 'POST',
            url: `${configuration.API_URL}/UploadFiles/enviardocumento`,
            data: e
        }).then((response) => {
        }).catch(e => {
            console.error("/UploadFiles/enviardocumento: ", e);
        });
    }

    const tooltipCertificadoCuidadoPaliativo = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
            <div className="text-left">Este certificado debe ser entregado y firmado por el Jefe de la Unidad de Cuidados Paliativos del recinto de salud, donde se diagnosticó tu condición de enfermo terminal.</div>
        </Tooltip>
    );
    const tooltipAutorizacionGes = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
            <div className="text-left">Con este documento autorizas al Consejo Médico y al Consejo Médico de Apelación para que soliciten tus antecedentes y/o accedan a tus fichas clínicas con el fin de fundamentar la certificación de enfermo terminal.</div>
        </Tooltip>
    );

    const tooltipAntecedentes = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
        <div className="text-left">Puedes adjuntar los antecedentes que consideres relevantes que acréditen tu condición de enfermo terminal.</div>
        </Tooltip>
    ) 

    const handleSubmit = async(values) => {
        setIsLoading(true);

        const solicitud = await obtenerSolicitud(idPersona);
        const idSolicitud = solicitud.data.resultado.folioSolicitud;
        const idPersonaSolicitud = solicitud.data.resultado.idPersona;

        if(cuidadosPaliativosFile){
            let archivoCuidados = new FormData();

            archivoCuidados.append("FolioSolicitud", idSolicitud);
            archivoCuidados.append("IdPersona", convertDniToID(idPersona));
            archivoCuidados.append("nombrearchivoMedico", cuidadosPaliativosFile.name)
            archivoCuidados.append("formFile", cuidadosPaliativosFile);
            archivoCuidados.append("TipoDocumento", 4);

            uploadFile(archivoCuidados);
            setCuidadosPaliativosFile(null);
        }

        if (medicoFile) {
            let archivoMedico = new FormData();

            archivoMedico.append("FolioSolicitud", idSolicitud);
            archivoMedico.append("IdPersona", convertDniToID(idPersona));
            archivoMedico.append("nombrearchivoMedico", medicoFile.name)
            archivoMedico.append("formFile", medicoFile);
            archivoMedico.append("TipoDocumento", 5);
            
            uploadFile(archivoMedico);
            setMedicoFile(null);
        }

        if (gesFile) {
            let archivoGes = new FormData();

            archivoGes.append("FolioSolicitud", idSolicitud);
            archivoGes.append("IdPersona", convertDniToID(idPersona));
            archivoGes.append("nombrearchivoGes", gesFile.name)
            archivoGes.append("formFile", gesFile);
            archivoGes.append("TipoDocumento", 6);

            uploadFile(archivoGes);
            setGesFile(null);
        }

        if(anexoFile) {
            let archivoAnexo = new FormData();

            archivoAnexo.append("FolioSolicitud", idSolicitud);
            archivoAnexo.append("IdPersona", convertDniToID(idPersona));
            archivoAnexo.append("nombrearchivoGes", anexoFile.name)
            archivoAnexo.append("formFile", anexoFile);
            archivoAnexo.append("TipoDocumento", 7);

            uploadFile(archivoAnexo);
            setAnexoFile(null);
        }

        if(anexoFile2) {
            let archivoAnexo2 = new FormData();

            archivoAnexo2.append("FolioSolicitud", idSolicitud);
            archivoAnexo2.append("IdPersona", convertDniToID(idPersona));
            archivoAnexo2.append("nombrearchivoGes", anexoFile2.name)
            archivoAnexo2.append("formFile", anexoFile2);
            archivoAnexo2.append("TipoDocumento", 7);

            uploadFile(archivoAnexo2);
            setAnexoFile2(null);
        }

        if(anexoFile3) {
            let archivoAnexo3 = new FormData();

            archivoAnexo3.append("FolioSolicitud", idSolicitud);
            archivoAnexo3.append("IdPersona", convertDniToID(idPersona));
            archivoAnexo3.append("nombrearchivoGes", anexoFile3.name)
            archivoAnexo3.append("formFile", anexoFile3);
            archivoAnexo3.append("TipoDocumento", 7);

            uploadFile(archivoAnexo3);
            setAnexoFile3(null);
        }

        if(anexoFile4) {
            let archivoAnexo4 = new FormData();

            archivoAnexo4.append("FolioSolicitud", idSolicitud);
            archivoAnexo4.append("IdPersona", convertDniToID(idPersona));
            archivoAnexo4.append("nombrearchivoGes", anexoFile4.name)
            archivoAnexo4.append("formFile", anexoFile4);
            archivoAnexo4.append("TipoDocumento", 7);

            uploadFile(archivoAnexo4);
            setAnexoFile4(null);
        }

        if(anexoFile5) {
            let archivoAnexo5 = new FormData();

            archivoAnexo5.append("FolioSolicitud", idSolicitud);
            archivoAnexo5.append("IdPersona", convertDniToID(idPersona));
            archivoAnexo5.append("nombrearchivoGes", anexoFile5.name)
            archivoAnexo5.append("formFile", anexoFile5);
            archivoAnexo5.append("TipoDocumento", 7);

            uploadFile(archivoAnexo5);
            setAnexoFile5(null);
        }

        history.push('/doc/resumen');
        
        setIsLoading(false);
    }

    return (
        <Formik
            initialValues={initialValuesFormDocumentosAfiliados}
            validationSchema={validationSchemaDocumentosAfiliados}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <Form>

                

                    <p> 1. Certificado emitido por el Jefe de
                        la Unidad de Cuidados Paliativos (Opcional)&nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipCertificadoCuidadoPaliativo}>
                            <img src={informationIcon} alt="infoicon"  width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="cuidadosPaliativosFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">

                                <label htmlFor="cuidadosPaliativosFile" className="control-labelx">

                                    El documento debe estar en formato JPG o PDF.
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="cuidadosPaliativosFile"
                                        name="cuidadosPaliativosFile"
                                        onChange={handleCuidadosPaliativosFile}
                                        accept=".pdf, .jpg"
                                    />

                                    {errorCuidadosPaliativosFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}

                                </label>
                                <div>
                                    <a href={() => false} className="delete-file" onClick={() => {
                                        handleDelete(setValidarCuidadosPaliativos,"cuidadosPaliativosFile",setCuidadosPaliativosFile,setErrorCuidadosPaliativosFile,errorCuidadosPaliativosFile,0)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/retiros10porciento/Certificado_Medico.pdf" target="blank_">Haz click para descargar el formato del Certificado Médico.</a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p>  2. Autorización para los Consejos Médicos para la revisión de la ficha clínica activación GES (Opcional)&nbsp;
                    <OverlayTrigger placement="top" overlay={tooltipAutorizacionGes}>
                    <img src={informationIcon} alt="infoicon" width="24" height="24" />
                </OverlayTrigger>
                    </p>
                    <Field name="medicoFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="medicoFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="medicoFile"
                                        name="medicoFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleMedicoFile}
                                    />
                                    {errorMedicoFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false} className="delete-file" onClick={() => {
                                        handleDelete(setValidarMedicoFile,"medicoFile",setMedicoFile,setErrorMedicoFile,errorMedicoFile,1)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/retiros10porciento/Autorizacion_Consejo_Medico.pdf" target="blank_">Haz click para descargar la autorización para el Consejo Médico.</a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p> 3. Certificado de activación GES (Opcional) &nbsp;
                        <a className="text-decoration-none align-svg" onClick={handleShow}>
                            <img className="icon ayuda" src={ayuda} alt="ayuda"/>
                        </a>
                    </p>
                    <Field name="gesFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="gesFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="gesFile"
                                        name="gesFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleGesFile}
                                    />
                                    {errorGesFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        handleDelete(setValidarGesFile,"gesFile",setGesFile,setErrorGesFile,errorAnexoFile,2)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a className="custom-a" href="https://modelo.blob.core.windows.net/satelite/Formato-certificado-medico.pdf" target="blank_">Haz click para descargar el formulario AUGE-GES.</a>
                                </div>
                            </div>
                        )}
                    </Field>
                    <p>  4.1 Antecedentes que desee aportar (Opcional) &nbsp;
                    <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                    <img src={informationIcon} alt="infoicon" width="24" height="24" />
                </OverlayTrigger>
                    </p>
                    <Field name="anexoFile">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile"
                                        name="anexoFile"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile}
                                    />
                                    {errorAnexoFile && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false} className="delete-file" onClick={() => {
                                        handleDelete(setValidarAnexo,"anexoFile",setAnexoFile,setErrorAnexoFile,errorAnexoFile,3)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>
                    <p>  4.2 Antecedentes que desee aportar (Opcional) &nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                            <img src={informationIcon} alt="infoicon" width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="anexoFile2">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile2" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile2"
                                        name="anexoFile2"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile2}
                                    />
                                    {errorAnexoFile2 && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        handleDelete(setValidarAnexo2,"anexoFile2",setAnexoFile2,setErrorAnexoFile2,errorAnexoFile2,4)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p>  4.3 Antecedentes que desee aportar (Opcional) &nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                            <img src={informationIcon} alt="infoicon" width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="anexoFile3">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile3" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile3"
                                        name="anexoFile3"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile3}
                                    />
                                    {errorAnexoFile3 && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        handleDelete(setValidarAnexo3,"anexoFile3",setAnexoFile3,setErrorAnexoFile3,errorAnexoFile3,5)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p>  4.4 Antecedentes que desee aportar (Opcional) &nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                            <img src={informationIcon} alt="infoicon" width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="anexoFile4">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile4" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile4"
                                        name="anexoFile4"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile4}
                                    />
                                    {errorAnexoFile4 && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={() => {
                                        handleDelete(setValidarAnexo4,"anexoFile4",setAnexoFile4,setErrorAnexoFile4,errorAnexoFile4,6)
                                    }}>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>

                    <p>  4.5 Antecedentes que desee aportar (Opcional) &nbsp;
                        <OverlayTrigger placement="top" overlay={tooltipAntecedentes}>
                            <img src={informationIcon} alt="infoicon" width="24" height="24" />
                        </OverlayTrigger>
                    </p>
                    <Field name="anexoFile5">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <label htmlFor="anexoFile5" className="control-labelx">
                                    El documento debe estar en formato JPG o PDF.

                                    <input
                                        type="file"
                                        className="file-input"
                                        id="anexoFile5"
                                        name="anexoFile5"
                                        accept=".pdf, .jpg"
                                        onChange={handleAnexoFile5}
                                    />
                                    {errorAnexoFile5 && <>
                                        <small className={`form-text is-invalid`}>
                                            Debe ingresar un archivo en formato válido.
                                        </small>
                                    </>}
                                </label>
                                <div>
                                    <a href={() => false}  className="delete-file" onClick={()=>{
                                        handleDelete(setValidarAnexo5,'anexoFile5',setAnexoFile5,setErrorAnexoFile5,errorAnexoFile5,7)
                                    }}
                                >
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </i> Eliminar
                                    </a>
                                </div>
                            </div>
                        )}
                    </Field>
                    <div className="d-flex justify-content-center">
                        <div className="col justify-content-center d-flex">
                            {isLoading
                                ? <>
                                    <button className="btn-custom btn-lg btn-custom-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                    </button>
                                </>
                                :
                                    <button type="submit" className="btn-custom btn-lg btn-custom-primary" disabled={ disabled }>
                                        Continuar
                                    </button>
                            }
                            <GESModal show={modalShow} onHide={handleClose} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col justify-content-center d-flex blue-a">
                            <a href={() => false}  onClick={() => { history.goBack() }}>Volver al paso anterior</a>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FormDocumentosAfiliadosV2;