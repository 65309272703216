import configuration from "../../Configuration";
import axios from "axios";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { convertirIdARut } from "../../utils/cleanInputMask";
import { clearPreloadedDate } from "../../utils/date.js";

const PreDataFormBeneficiarios = (props) => {
	const { values, setValues, setTouched } = useFormikContext();

	useEffect(async () => {
		const idCliente = localStorage.getItem("idCliente");
		const idPersona = localStorage.getItem("idPersona");
		const obtenerDatosPrevisionales = async () => {
			axios
				.get(
					`${configuration.API_URL}/Beneficiarios/ObtenerTodosPorCliente?idCliente=${idCliente}
					&idPersona=${idPersona}`
				)
				.then((response) => {
					let data = response.data;
					let beneficiarios = [...values.beneficiarios];
					let beneficiarios_touched = [];

					localStorage.setItem("nBeneficiarios", data.resultado.length);

					if (data.resultado.length === 0) {
						console.info("sin beneficiarios...");
					} else {
						data.resultado
							.sort(function (a, b) {								
								return new Date(b.createDate) - new Date(a.createDate);
							})
							.reverse()
							.forEach((beneficiario) => {
								let rut = convertirIdARut(beneficiario.idBeneficiario);

								let celular = beneficiario.celular.slice(2);
								let fecha =
									beneficiario.fecNacimiento === null
										? ""
										: clearPreloadedDate(beneficiario.fecNacimiento);
								beneficiarios.push({
									rut: rut,
									nombres: beneficiario.nombre,
									apellidoPaterno: beneficiario.apPaterno,
									apellidoMaterno: beneficiario.apMaterno,
									parentesco: beneficiario.codParentesco.toString(),
									hijoInvalido: "",
									fechaNacimiento: fecha,
									genero: beneficiario.sexo === "M" ? "0" : "1",
									indNuevoBeneficiario: "N",
									idBeneficiario: beneficiario.idBeneficiario,
									telefonoContacto: celular,
									correoElectronico: beneficiario.email,
								});

								beneficiarios_touched.push({
									rut: true,
									nombres: true,
									apellidoPaterno: true,
									apellidoMaterno: true,
									parentesco: true,
									hijoInvalido: "",
									fechaNacimiento: true,
									genero: true,
									telefonoContacto: true,
									correoElectronico: true,
								});
								setTouched({
									beneficiarios: beneficiarios_touched,
								});
							});

						setValues({ ...values, beneficiarios });
					}
				})
				.catch((e) => {
					console.error(e);
				});
		};
		obtenerDatosPrevisionales();
	}, []);

	return null;
};

export default PreDataFormBeneficiarios;
