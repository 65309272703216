import { validaFolio } from "../../services/api/valida";
import { convertDniToID } from "../helpers";

export const handleLoginSolicitud = async (values) => {
    localStorage.setItem("idPersona",values.rut)
    localStorage.setItem("folioSolicitud",values.solicitud)
    const idPersona = convertDniToID(values.rut);   
    const valida_folio =  await validaFolio({ rut: idPersona, folio: values.solicitud });

    if(valida_folio.status){
        values.history.push('/doc/documentos')
    }
}