import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../assets/styles/GesModal.css";

/* eslint react/prop-types: 0 */
export default function GesModal(props) {
    return (
        <div>
            <Modal {...props} dialogClassName="modal-50w" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title className="text-left textoTitulo">
                        Para activar el AUGE-GES es necesario que:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="body-ges">
                        <br />
                        <p>Para acceder al beneficio de Retiro de fondos de Enfermos Terminales, es necesario durante esta primera etapa (01 de Abril al 30 de Junio) contar con el certificado emitido por el jefe de la unidad de cuidados paliativos y estar registrado en la lista GES 4.</p>
                        <p><strong>Para activar este beneficio:</strong></p>
                        <p>1.	Los afiliados a Fonasa podrán ser atendidos por un médico en el establecimiento de salud donde se encuentren inscritos: Centro de Salud Familiar (CESFAM), consultorio, etc. Mientras que los afiliados a una Isapre deben acudir a un médico particular o utilizar el convenio de su plan de salud privado.</p>
                        <p>2.	El médico especialista debe confirmar el diagnóstico y <a className="active" target="_blank" href="https://modelo.blob.core.windows.net/satelite/Formato-certificado-medico.pdf">completar el formulario AUGE-GES</a> para dejar constancia de la condición del paciente.</p>
                        <p>3.	Según las indicaciones entregadas por su médico, los afiliados a Fonasa podrán acceder de inmediato a la cobertura. Mientras que los afiliados a una ISAPRE tendrán que hacer entrega del formulario y los exámenes que acrediten su condición (cuando corresponda) en la sucursal de su ISAPRE para activar las prestaciones.</p>
                        <p>Este formulario debe estar firmado por el prestador de salud y por la persona beneficiaria, e indicar el día y la hora de la notificación. Además, se entregará una copia de dicho formulario al beneficiario o beneficiaria.</p>
                        <p>Posteriormente, el formulario deberá ser presentado  en cualquiera de los canales de AFP Modelo para continuar con la solicitud del beneficio.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}