let Configuration = "";

if (process.env.NODE_ENV === "production") {
  Configuration = {
    //API_URL: "https://localhost:44319",
    //RED_URL:"https://localhost:44307/eld",  
    API_URL: "https://afpmodeloretapiprod.azurewebsites.net",
    RED_URL:"https://turetiroconmodelo.cl/eld",
    JSON_HEADER: { "Content-Type": "application/json" },
    AMB: process.env.NODE_ENV,
    RECAPTCHA_PUBLIC_KEY: "6LfN17UcAAAAAIwXSBsoC5nsngHXPxsyti24MQcT",
  };
}

if (process.env.NODE_ENV === "test") {
  Configuration = {
    //API_URL: "https://localhost:44319",
    //RED_URL:"https://localhost:44307/eld",
    //API_URL: "https://afpmodeloretapiqa.azurewebsites.net",
   // RED_URL: "https://afpmodeloretfrontqa.azurewebsites.net/eld",
    API_URL: "https://afpmodeloretapiprod.azurewebsites.net",
    RED_URL:"https://turetiroconmodelo.cl/eld",
    JSON_HEADER: { "Content-Type": "application/json" },
    AMB: process.env.NODE_ENV,
    RECAPTCHA_PUBLIC_KEY: "6LfN17UcAAAAAIwXSBsoC5nsngHXPxsyti24MQcT",
  };
}

if (process.env.NODE_ENV === "development") {
  Configuration = {
    //API_URL: "https://localhost:44319",
    //RED_URL:"https://localhost:44307/eld",
    //API_URL: "https://afpmodeloretapiqa.azurewebsites.net",
    //RED_URL: "https://afpmodeloretfrontqa.azurewebsites.net/eld",
    API_URL: "https://afpmodeloretapiprod.azurewebsites.net",
    RED_URL:"https://turetiroconmodelo.cl/eld",
    JSON_HEADER: { "Content-Type": "application/json" },
    AMB: process.env.NODE_ENV,
    RECAPTCHA_PUBLIC_KEY: "6LfN17UcAAAAAIwXSBsoC5nsngHXPxsyti24MQcT",
  };
}

export default Configuration;
