import { cleanRut } from "../cleanInputMask";
import axios from "axios";
import configuration from "../../Configuration";
import Swal from "sweetalert2";

export const handleSubmitClaveSeguridad = ({
	values,
	changeLoading,
	history,
	changeModal,
}) => {
	changeLoading(true);

	const body = {
		rut: cleanRut(values.rut),
		pass: btoa(values.clave),
		esBeneficiarioPensionSobrevivencia: values.beneficiario,
	};

	axios
		.post(`${configuration.API_URL}/Valida/autenticar`, body, {
			headers: configuration.JSON_HEADER,
		})
		.then((response) => {
			let data = response.data;

			if (response.status === 200) {
				if (data.ok) {
					localStorage.setItem("idPersona", data.resultado.solicitud.idPersona);
					localStorage.setItem("idCliente", data.resultado.solicitud.idCliente);
					history.push("/datosAfiliados");
				} else {
					/*** AFILIADO YA TIENE SOLICITUD EN CURSO ***/
					if (
						data.mensaje === "El afiliado ya tiene una solicitud en trámite"
					) {
						Swal.fire({
							title: "¡Ya tienes una solicitud de pensión en curso!",
							icon: "error",
							html: '<p class="text-left"> No puedes ingresar una nueva solicitud mientras la anterior se encuentre en proceso de revisión.</p> <p class="text-left">Para conocer el estado de tu solicitud <a href="">haz click aquí</a>. O puedes llamar al <a href="tel:800025001">800 025 001.</a></p>',
							confirmButtonText: "Aceptar",
							allowOutsideClick: false,
							customClass: {
								confirmButton: "btn-custom btn-lg btn-custom-primary",
								title: "font-weight-bold text-left",
								footer: "swal-footer",
							},
							buttonsStyling: false,
						});
						changeLoading(false);
					} else {
						changeModal(true);
						changeLoading(false);
					}
				}
			} else if (!data.resultado) {
				changeLoading(false);
			}
		})
		.catch((e) => {
			console.error(e);
			changeLoading(false);
		});
};