import React from "react";
import { Helmet } from "react-helmet";
import "../assets/styles/datosAfiliados.css";
import FormDatosAfiliados from "../components/FormDatosAfiliados";

export default function Demo() {
    
    return (
        <div className="container home">
            <Helmet>
                <title>Ingresa Datos Beneficiario | Retiro de Pensiones | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">

                        <div className="row">
                        <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de<br />Pensión Anticipada por<br />Enfermedad Terminal</div>

                                    <span className="textoSecundario">Ingresar datos del Afiliado, Pensionado o Beneficiario de sobrevivencia</span>

                                    <div className="card-body">

                                        <FormDatosAfiliados />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}