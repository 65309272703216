import React, {useState} from "react";
import { Helmet } from "react-helmet";
import FormBeneficiarios from "../components/FormBeneficiarios";
import ayuda from "../assets/img/ayuda.png";
import BeneficiarioModal from "../components/BeneficiarioModal";


export default function Comprobante() {

    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);

    return (

        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">

                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de
                                        Pensión Anticipada por
                                        Enfermedad Terminal</div>

                                    <span className="textoSecundario">Ingresar datos de Beneficiarios
                                        <a className="text-decoration-none align-svg" onClick={handleShow}>
                                            <img className="icon ayuda" src={ayuda} alt="ayuda" />
                                        </a>
                                    </span>
                                    <span className="textoTerciario">En esta sección podrás modificar, agregar o eliminar tus beneficiarios.</span>
                                    <div className="card-body card-header-margin">
                                        
                                        <FormBeneficiarios />

                                        <BeneficiarioModal show={modalShow} onHide={handleClose} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}