import React from "react";
import Helmet from "react-helmet";
import Alert from "../../utils/alert";
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { Comprobantev2 } from "../../components/FormComprobanteV2";
import { ComprobanteFinal } from "../../components/ComprobanteFinal";

export default function comprobante() {

    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div id="pdfdiv" className="">
                                        <div className="card-title textoTitulo">
                                        Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal
                                        </div>
                                        <span className="text-green">
                                        ¡Tus documentos han sido enviados!
                                        </span>
                                        <div className="card-body">
                                            <ComprobanteFinal />                                            
                                        </div>
                                       
                                    </div>
                                    

                                    <div className="comprobante-botones">
                                     <div className="form-group">
                                            <Alert image="success"
                                                 className="alert-custom-dark"
                                                message="Tu selección ya ha sido ingresada. Te enviaremos una copia con el detalle a tu correo electrónico. " />
                                        </div>
                                        <div className="justify-content-center d-flex">                                           
                                            <button type="button" className="btn-custom btn-lg btn-custom-primary" onClick={()=>{
                                                const input = document.getElementById('pdfdiv');
                                                const pdf = new jsPDF();
                                                if (pdf) {
                                                    domtoimage.toPng(input).then(imgData => {
                                                        pdf.addImage(imgData, 'PNG', 40, 10);
                                                        pdf.save(`${localStorage.getItem("folioSolicitud")}.pdf`);
                                                    });
                                                }
                                            }}>
                                                Descargar comprobante
                                            </button>
                                        </div>                    
                                        <div className="justify-content-center d-flex">
                                            <button
                                                className="btn-custom btn-lg btn-custom-default"
                                                type="button"
                                                onClick={()=>{ window.location.href = '/doc/login'; }}>
                                                    Volver al inicio
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}