import React, { useEffect, useState } from "react"
import { obtenerSolicitud } from "../services/api/solicitud";
import { formatDate, getToday } from "../utils/date";
import { handleSubmit } from "../utils/handleSubmit/formResumenDoc";
import { formatDNI, removeZeros } from "../utils/helpers";

export const ComprobanteFinal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [datos, setDatos] = useState({
        rut: "",
        nombre: "",
        fecha: "",
        numeroSolicitud: 0,

        fechaSeleccion: ""
    });
    useEffect(async () => {
        const idPersona =localStorage.getItem("idPersona")
        const obtener_solicitud = await obtenerSolicitud(idPersona);
        const resultado  = obtener_solicitud.data.resultado;

            setDatos({
                rut: formatDNI(removeZeros(resultado.idPersona)),
                nombre: `${resultado.nombreAfi} ${resultado.apPaternoAfi} ${resultado.apMaternoAfi}`,
                fecha: formatDate(resultado.createDate),
                numeroSolicitud:localStorage.getItem('folioSolicitud'),
                fechaSeleccion: formatDate(getToday())
            });
    }, []);
 const date =() => {
       

        return formatDate(new Date().toLocaleDateString())
    }

  
return(

    <form>
    <div className="form-group"> 
    <p className="comprobante-label">Número de solicitud</p>
    <p className="comprobante-detalle">{datos.numeroSolicitud || 'vacio'}</p>
    </div>
    <div className="form-group">
        <p className="comprobante-label">Fecha de modificación documentos</p>
        <p className="comprobante-detalle">{formatDate(getToday())}</p>
    </div>
    <div className="form-group">
        <p className="comprobante-label">RUT</p>
        <p className="comprobante-detalle">{datos.rut || 'vacio'}</p>
    </div>
    <div className="form-group">
        <p className="comprobante-label">Nombre afiliado o pensionado</p>
        <p className="comprobante-detalle">{datos.nombre || 'vacio'}</p>
    </div>
    
    <div className="form-group">
        <p className="comprobante-label">Fecha de solicitud PAET</p>
        <p className="comprobante-detalle">{datos.fecha || 'vacio'}</p>
    </div>

</form>
)
}
