import {genericPost, genericDelete} from "../../../utils/genericApiCall";

export async function ingresarSaldoExterno(body) {
    const url_endpoint = '/Saldos/AgregarSaldoExterno';
    const message_info = 'Ingresando saldo externo...';
    const message_error = 'Error en ingresar saldo externo...';

    return await genericPost({
        url_endpoint: url_endpoint,
        body: body,
        message_info: message_info,
        message_error: message_error
    });
}

export async function eliminarSaldoExterno(idSolicitud) {
    const endpoint = `/Saldos/EliminarSaldosExternos?folioSolicitud=${idSolicitud}`;
    const message_info = 'Eliminando saldos externos...';
    const message_error = 'Error al eliminar saldos externos...';

    return await genericDelete({
        url_endpoint:endpoint,
        message_info: message_info,
        message_error: message_error
    });
}
