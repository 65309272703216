
import {genericPost} from "../../../utils/genericApiCall"

export async function validaCelula(body) {
    const url_endpoint = '/Valida/validaCedula';
    const message_info = 'Validando Cedula...';
    const message_error = 'Error en la validacion de la cedula...';
    
    return await genericPost({
        url_endpoint: url_endpoint,
        body: body,
        message_info: message_info,
        message_error: message_error
    })
}

export async function validaFolio(body) {

    const url_endpoint = '/Valida/validaFolio';
    const message_info = 'Validando folio...';
    const message_error = 'Error en la validacion del folio...';

    return await genericPost({
        url_endpoint: url_endpoint,
        body: body,
        message_info: message_info,
        message_error: message_error
    })
}

export async function SaveLog(body) {

    const url_endpoint = '/LogEld/Guardar';
    const message_info = 'Validando...';
    const message_error = 'Error...';

    return await genericPost({
        url_endpoint: url_endpoint,
        body: body,
        message_info: message_info,
        message_error: message_error
    })
}

export async function ingresoEjecutivo(body) {

    const url_endpoint = '/IngresoSolicitudEjecutivoWeb/IngresarSolicitudCanalEjecutivoWeb';
    const message_info = 'Ingresando ejecutivo...';
    const message_error = 'Error al ingresar ejecutivo...';

    return await genericPost({
        url_endpoint: url_endpoint,
        body: body,
        message_info: message_info,
        message_error: message_error
    })
    
}