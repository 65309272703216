import { cleanRut } from "../cleanInputMask";
import Swal from "sweetalert2";
import { validaCelula } from "../../services/api/valida/index";

const MySwal = Swal.mixin({
	customClass: {
		cancelButton: "btn btn-cerrar",
		confirmButton: "btn-custom btn-lg btn-custom-primary",
		title: "font-weight-bold text-left",
		footer: "swal-footer",
	},
	allowOutsideClick: false,
	buttonsStyling: false,
});

export const handleSubmitFormNumeroSerie = async ({
	values,
	changeLoading,
	history,
	showModalEjecutivo,
}) => {
	changeLoading(true);
	localStorage.clear();

	const body = {
		rut: cleanRut(values.rut),
		serie: values.numeroserie,
		esMandatario: values.mandatario,
		esBeneficiarioPensionSobrevivencia: values.beneficiario,
		token:values.token,
	};

	const valida_celula = await validaCelula(body);

	if (
		valida_celula.status === 200 &&
		valida_celula.data.mensaje === "afiliado no encontrado."
	) {
		await MySwal.fire({
			title: "¡No eres afiliado o pensionado de AFP Modelo!",
			icon: "error",
			text: "Los datos ingresados no coinciden con nuestros registros.",
			confirmButtonText: "Aceptar",
			showCancelButton: false,
		});
	} else if (valida_celula.status === 200) {
		localStorage.setItem("informante", values.mandatario ? "M" : "A");

		if (valida_celula.data.resultado === null) {
			await MySwal.fire({
				title: "¡No hemos podido validar tu identidad!",
				icon: "error",
				confirmButtonText: "Ingresar con clave de seguridad",
				confirmButtonColor: "#72b500",
				showCancelButton: true,
				cancelButtonText: "Cerrar",
				html:
					'<p class="text-left">No se pudieron validar los datos ingresados, verifica tu información y <span class="font-weight-bold">vuelve a intentarlo</span> o puedes <span class="green">ingresar con tu clave de seguridad</span>.</p>' +
					'<p class="text-left">Recuerda que tu Clave de Seguridad es una segunda clave.</p>',
			}).then((result) => {
				if (result.value) {
					history.push(`/claveSeguridad`);
				}
			});
		} else {
			let tieneSolicitud = valida_celula.data.resultado.indTieneTramite;

			if (valida_celula.data.ok) {
				let data = valida_celula.data.resultado.solicitud;
				localStorage.setItem("idPersona", data.idPersona);
				localStorage.setItem("idCliente", data.idCliente);
				localStorage.setItem("numFolio", data.folioSolicitud);
			}

			if (
				valida_celula.data.ok &&
				values.mandatario &&
				!values.ejecutivoAfp &&
				tieneSolicitud === "N"
			) {
				await MySwal.fire({
					title: "¡Tu identidad fue validada correctamente!",
					icon: "success",
					text: "Serás redirigido a un formulario donde debes registrar tus datos como mandatario.",
					confirmButtonText: "Continuar",
				}).then((result) => {
					if (result.value) {
						history.push(`/mandatario`);
					}
				});
			} else if (
				valida_celula.data.ok &&
				!values.mandatario &&
				!values.ejecutivoAfp &&
				tieneSolicitud === "N"
			) {
				await MySwal.fire({
					title: "¡Tu identidad fue validada correctamente!",
					icon: "success",
					confirmButtonText: "Continuar",
					text: "Serás redirigido a un formulario donde debes registrar tus datos como Afiliado, Pensionado o Beneficiario de pensión de sobrevivencia.",
				}).then((result) => {
					if (result.value) {
						history.push(`/datosAfiliados`);
					}
				});
			} else if (tieneSolicitud === "S") {
				await MySwal.fire({
					title: "¡Ya tienes una solicitud de pensión en curso!",
					icon: "error",
					html: '<p class="text-left"> No puedes ingresar una nueva solicitud mientras la anterior se encuentre en proceso de revisión.</p> <p class="text-left">Para conocer el estado de tu solicitud puedes llamar al <a class="blue-a" href="tel:800025001">800 025 001.</a></p>',
					confirmButtonText: "Aceptar",
				});
			} else if (valida_celula.data.ok && values.ejecutivoAfp) {
				showModalEjecutivo(true);
			} else if (valida_celula.data.ok === false) {
				/*** AFILIADO YA TIENE SOLICITUD EN CURSO ***/
				if (
					valida_celula.data.mensaje ===
					"El afiliado ya tiene una solicitud en trámite"
				) {
					await MySwal.fire({
						title: "¡Ya tienes una solicitud de pensión en curso!",
						icon: "error",
						html: '<p class="text-left"> No puedes ingresar una nueva solicitud mientras la anterior se encuentre en proceso de revisión.</p> <p class="text-left">Para conocer el estado de tu solicitud puedes llamar al <a class="blue-a" href="tel:800025001">800 025 001.</a></p>',
						confirmButtonText: "Aceptar",
					});
				} else {
					await MySwal.fire({
						title: "¡No hemos podido validar tu identidad!",
						icon: "error",
						confirmButtonText: "Ingresar con clave de seguridad",
						confirmButtonColor: "#72b500",
						showCancelButton: true,
						cancelButtonText: "Cerrar",
						html:
							'<p class="text-left">No se pudieron validar los datos ingresados, verifica tu información y <span class="font-weight-bold">vuelve a intentarlo</span> o puedes <span class="green">ingresar con tu clave de seguridad</span>.</p>' +
							'<p class="text-left">Recuerda que tu Clave de Seguridad es una segunda clave.</p>',
					}).then((result) => {
						if (result.value) {
							history.push(`/claveSeguridad`);
						}
					});
				}
			}
		}
	}

	changeLoading(false);
};
