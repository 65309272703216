export const formatCelular = (string) => {
    //var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var options = string;
    if (options.length < 10) {
        options = "(+56) " + string.substr(0, 1) + ' ' + string.substr(1, 4) + ' ' + string.substr(5, 4);
    } else {
        options = "(+" + string.substr(0, 2)+ ") " + string.substr(2, 1) + ' ' + string.substr(3, 4) + ' ' + string.substr(7, 4);
    }
    return options;
};

export const toNumberFromCurrency = (val) => {
    let replacedValue =  val.replace("$", "").replace(".", "").replace(".", "").replace(".", "").replace(".", "");
    return parseInt(replacedValue);
}
export const parseUF =(val) =>{
 
    val = val.replace(/\./g, '');    
    val = val.replace(',', '.');  
    return parseFloat(val);
}
export const convertToUF = (val, valUF, decimals) => {
    let value = toNumberFromCurrency(val);    
    return (value / valUF).toLocaleString(undefined, {maximumFractionDigits: decimals});
}

export const convertUFToCLP = (uf, valorUF) => {
    return uf * valorUF;
}

export const sueldoFormateador = (value) => {

    const formatterPeso = new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP"
    }).format(value);

    if (formatterPeso.length === 5) {
        let aux_array_pesos = formatterPeso.split('$');
        let aux_pesos = aux_array_pesos[1];
        let final = '$' + aux_pesos[0] + '.' + aux_pesos[1] + aux_pesos[2] + aux_pesos[3];

        return final;
    } else {
        return formatterPeso;
    }
};

export const convertDniToID = (value) => {
    const identifier = value.replace(/\./g, '');
    const concatenate = identifier.length === 9 ? '000000' : '000000';
    const auxSplit = identifier.split('-');

    let aux = "";
    if(auxSplit[1] !== undefined){
        aux =  concatenate + auxSplit[0] + auxSplit[1];
    } else {
        aux =  concatenate  +auxSplit[0];
    }

    if(aux.length === 15) {
        return aux;
    } else if(aux.length === 14){
        return "0" + aux;
    }
}

export const removeZeros= (rut) => {    
    let entero = rut.substring(0, rut.length - 1);
    let dv = rut.substring(rut.length - 1, rut.length);
    
    let numberOutput = Math.abs(entero);
    
    return `${numberOutput}-${dv}`;
}

export const formatDNI = (value) => {
    let dni = value;
    dni = dni.replace(/\./g, "").replace("-", "");

    if (dni.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
        dni = dni.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    } else if (dni.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
        
        dni = dni.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
    } else if (dni.match(/^(\d)(\d{3})(\d{0,2})$/)) {
        dni = dni.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
    } else if (dni.match(/^(\d)(\d{0,2})$/)) {
        dni = dni.replace(/^(\d)(\d{0,2})$/, "$1.$2");
    }

    return dni;
};

export const validaExtension = (extension_archivo, setErrorArchivo) => {
    const allowedExtensions = /(.jpg|.JPG|.pdf|.PDF)$/i;
    if(!allowedExtensions.exec(extension_archivo)){
        console.info("archivo erroneo")
        setErrorArchivo(true);
        return false;
    }else {
        setErrorArchivo(false);
        return true
    }
}