var tipoCuenta = new Map();
tipoCuenta.set("P", "Cuenta principal de pago");
tipoCuenta.set("S", "Cuenta alternativa de pago");

class tipoCuentaUsoHelper {
  getTipoCuenta (value) {    
    return tipoCuenta.get(value)
  }
}

export default new tipoCuentaUsoHelper()