import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../assets/styles/claveSeguridadModal.css";

/* eslint react/prop-types: 0 */
export default function claveSeguridadModal(props) {
    return (
        <div>
            <Modal
                {...props}
                dialogClassName="modal-50w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ¿Cuál es mi clave de seguridad?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>La Clave de Seguridad (SACU) es una segunda clave de AFP <span className="font-weight-bold">diferente</span> a la que te permite ingresar al sitio web de tu AFP actual.</p>
                    <p>Es especial, te permite hacer; cambio o distribución de fondos, giros desde cuentas de ahorro voluntario o <span className="font-weight-bold">cambio de AFP.</span></p>
                    <p>Si no la tienes <span className="font-weight-bold">debes solicitarla</span> <a className="active" href="https://www.afpmodelo.cl/AFP/Acceso-mi-cuenta/Acceso-a-mi-Cuenta.aspx">ingresando a tu cuenta aquí.</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn-custom btn-lg btn-custom-primary" onClick={props.onHide}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}