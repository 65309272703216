import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import informationIcon from "../../assets/svg/information.svg";
import {Field, Form, Formik} from "formik";
import {validationSchemaLoginELD} from "../../utils/validationSchemaForm";
import {rutMask} from "../../utils/inputMask";
import {oacento} from "../../utils/caracteresUTF8";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { handleSubmit } from "../../utils/handleSubmit/formIndexELD";
import { initialValues } from "../../utils/initialValues/formIndexELD";

const FormLogin = () => {

    const [isLoading, setIsLoading] = useState(false);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className="">
            <div className="text-left">Ingresa el número que te entregamos cuando realizaste tu solicitud</div>
        </Tooltip>
    );

    const onSubmit = (values) => {
        handleSubmit({
            values: values,
            changeLoading: setIsLoading            
        });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchemaLoginELD} onSubmit={onSubmit}>
            {(formik) => (                
                <Form>
                    <Field name="rut">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <span>RUT del afiliado, pensionado o beneficiario de pensi{oacento}n de sobrevivencia</span>
                                <MaskedInput
                                    {...field}
                                    name="rut"
                                    type="text"
                                    mask={rutMask}
                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                    aria-describedby="rutAyuda"
                                    autoComplete="off" />
                                <small id="rutAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                    {meta.touched && meta.error ? meta.error : "Ej. 12.345.678-9."}
                                </small>
                            </div>
                        )}
                    </Field>
                    <Field name="numeroSolicitud">
                        {({ field, meta }) => (
                            <div className="form-group input-wrapper">
                                <span className="vertical-align">Número de solicitud o trámite {' '}
                                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                                        <img src={informationIcon} width="24" height="24" />
                                    </OverlayTrigger>
                                </span>
                                <input
                                    {...field}
                                    type="text"
                                    className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                    name="numeroSolicitud"
                                    aria-describedby="numeroSolicitudAyuda"
                                    autoComplete="off"/>
                                <small id="numeroSolicitudAyuda" className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                    {meta.touched && meta.error ? meta.error : "El número de tu solicitud. Ej: 1711034202103."}
                                </small>
                            </div>
                        )}
                    </Field>
                    <div className="d-flex justify-content-center mt-4">
                        {isLoading
                            ? <>
                                <button className="btn-custom btn-lg btn-custom-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </>
                            :
                            <button className="btn-custom btn-lg btn-custom-primary" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                                Continuar
                            </button>
                        }                        
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default FormLogin;
