import { validationSchemaLogin } from "../utils/validationSchemaForm";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import MaskedInput from "react-text-mask";
import { rutMask } from "../utils/inputMask";
import React, { useState } from "react";
import DocumentoModal from "./DocumentoModal";
import { oacento } from "../utils/caracteresUTF8";
import { initialValuesFormNumeroSerie } from "../utils/initialValues/formNumeroSerie";
import { handleSubmitFormNumeroSerie } from "../utils/handleSubmit/formNumeroSerie";
import ButtonLoading from "./commons/buttonLoading";
import ButtonSubmit from "./commons/buttonSubmit";
import ModalEjecutivo from "./ModalEjecutivo";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Configuration from "../Configuration";

const FormNumeroSerie = () => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalEjecutivo, setShowModalEjecutivo] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handleCloseE = () => setShowModalEjecutivo(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (values) => {
    if (!executeRecaptcha) {
      console.error("executeRecaptcha no está disponible");
      return;
    }
    const token = await executeRecaptcha("login");
	
    handleSubmitFormNumeroSerie({
      values:  { ...values, token },
      changeLoading: setIsLoading,
      history: history,
      showModalEjecutivo: setShowModalEjecutivo,
    }).then((r) => console.info("Datos enviados..."));
  };

  return (
    <Formik
      initialValues={initialValuesFormNumeroSerie}
      validationSchema={validationSchemaLogin}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <span>
            RUT del afiliado, pensionado o beneficiario de pensi{oacento}n de
            sobrevivencia
          </span>
          <Field name="rut">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <MaskedInput
                  {...field}
                  mask={rutMask}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  name="rut"
                  aria-describedby="rutAyuda"
                  placeholder=""
                  autoComplete="off"
                />
                <label htmlFor="rut" className="control-label">
                  RUT
                </label>
                <small
                  id="rutAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej. 12.345.678-9."}
                </small>
              </div>
            )}
          </Field>
          <span>
            Número de serie o de documento{" "}
            <a onClick={handleShow} className="text-decoration-none align-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0d6efd"
                className="bi bi-question-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
              </svg>
            </a>
          </span>

          <Field name="numeroserie">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  name="numeroserie"
                  aria-describedby="numeroserieAyuda"
                  placeholder=""
                  autoComplete="off"
                />
                <label htmlFor="numeroserie" className="control-label">
                  Numero de Serie
                </label>
                <small
                  id="numeroserieAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: A0000000000 o 000.000.000 dependiendo de tu cédula de ID."}
                </small>
              </div>
            )}
          </Field>
          <span>Confirmar número de serie o documento </span>
          <Field name="numeroserieCompare">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  name="numeroserieCompare"
                  aria-describedby="numeroserieCompareAyuda"
                  placeholder=""
                  autoComplete="off"
                />
                <label htmlFor="numeroserieCompare" className="control-label">
                  Numero de Serie
                </label>
                <small
                  id="numeroserieCompareAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: A0000000000 o 000.000.000 dependiendo de tu cédula de ID."}
                </small>
              </div>
            )}
          </Field>
          <Field name="beneficiario">
            {({ field, meta }) => (
              <div className="form-check mb-4">
                <span className="label-checkbox">
                  Indícanos si eres beneficiario
                </span>
                <label
                  className="form-check-label mt-auto"
                  htmlFor="beneficiario"
                >
                  <input
                    type="checkbox"
                    name="beneficiario"
                    {...field}
                    className="form-check-input"
                    autoComplete="off"
                  />
                  <small className="beneficiario">
                    Soy beneficiario de una Pensión de Sobrevivencia.
                  </small>
                </label>
                <small
                  id="terminosAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <Field name="mandatario">
            {({ field, meta }) => (
              <div className="form-check">
                <span className="label-checkbox">
                  Indícanos si eres mandatario
                </span>
                <label
                  className="form-check-label mt-auto"
                  htmlFor="mandatario"
                >
                  <input
                    type="checkbox"
                    name="mandatario"
                    {...field}
                    className="form-check-input"
                    autoComplete="off"
                  />
                  <small className="mandatario">
                    Soy mandatario y estoy facultado para realizar la solicitud.
                  </small>
                </label>
                <small
                  id="mandatarioAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <br />
          <Field name="ejecutivoAfp">
            {({ field, meta }) => (
              <div className="form-check">
                <span className="label-checkbox">
                  Indícanos si eres ejecutivo de la AFP.
                </span>
                <label
                  className="form-check-label mt-auto"
                  htmlFor="mandatario"
                >
                  <input
                    type="checkbox"
                    name="ejecutivoAfp"
                    {...field}
                    className="form-check-input"
                    autoComplete="off"
                  />
                  <small className="mandatario">
                    Hago ingreso asistido de la solicitud como ejecutivo de AFP
                    Modelo.
                  </small>
                </label>
                <small
                  id="mandatarioAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <div className="justify-content-center d-flex mt-4">
            {isLoading ? (
              <ButtonLoading />
            ) : (
              <ButtonSubmit id_button={"numeroserie"} formik={formik} />
            )}
          </div>
          <ModalEjecutivo show={showModalEjecutivo} onHide={handleCloseE} />
          <DocumentoModal show={modalShow} onHide={handleClose} />
        </Form>
      )}
    </Formik>
  );
};
const ReCaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={Configuration.RECAPTCHA_PUBLIC_KEY}>
      <FormNumeroSerie />
    </GoogleReCaptchaProvider>
  );
};

export default ReCaptcha;
