import { genericGet } from "../../../utils/genericApiCall";

export async function obtenerOcupacionuOficio() {
    const url_endpoint = '/Ocupacion/ListarTodos';
    const message_info = 'Obteniendo ocupaciones...';
    const message_error = 'Error al obtener ocupaciones...';

    return await genericGet({
        url_endpoint: url_endpoint,
        message_info: message_info,
        message_error: message_error
    });
}