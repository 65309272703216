import React from "react";
import { Helmet } from "react-helmet";
import PagoBancaria from "../components/FormFormaPago";
import {aacento} from "../utils/caracteresUTF8";

export default function formaPago() {

    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Solicitud de Retiro Enfermos Terminales | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">Formulario de Retiro de Pensión Anticipada por Enfermedad Terminal</div>
                                    <span className="textoSecundario">1- Ingresa la forma de pago principal(Obligatoria)</span>                                    
                                    <div className="card-body">
                                        <span className="">La cuenta bancaria debe pertenecer al afiliado o beneficiario de sobrevivencia. Si eres mandatario y est{aacento}s autorizado para realizar el cobro, puedes ingresar tu cuenta bancaria.</span><br/><br/>
                                        <PagoBancaria />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}