import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import configuration from "../Configuration";
import { formatDate } from "../utils/date";
import { formatearRut, formatearRutCeros } from "../utils/validationRut";

const FormComprobanteEstadoSolicitud = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [datos, setDatos] = useState({});

	const Persona = {
		idPersona: localStorage.getItem("idPersona"),
	};

	function printDocument() {
		setIsLoading(true);
		const input = document.getElementById("pdfdiv");
		html2canvas(input).then((canvas) => {
			var imgWidth = 180;
			var imgHeight = (canvas.height * imgWidth) / canvas.width;
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("p", "mm", "a4");
			var position = 0;
			pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
			pdf.save("download.pdf");
			setIsLoading(false);
		});
	}
	useEffect(() => {
		const ObtenerDatos = async () => {
			await axios
				.get(
					`${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${Persona.idPersona}`,
					{
						headers: configuration.JSON_HEADER,
					}
				)
				.then((response) => {
					if (response.data.ok) {
						let resultado = response.data.resultado;

						setDatos({
							numero: `${resultado.folioSolicitud}${resultado.correlAfpFecha}`,
							fecha: formatDate(resultado.createDate),
							rutSolicitante: formatearRut(
								formatearRutCeros(resultado.idPersona)
							),
							estadoSolicitud: resultado.codEstado,
							fechaPago: "",
						});
					}
				})
				.catch((e) => {
					console.error(e);
					setIsLoading(false);
				});
		};
		ObtenerDatos();
	}, []);
	return (
		<Formik onSubmit={() => {}}>
			{() => (
				<Form id="pdfdiv">
					<div className="form-group">
						<span className="label-text">Número de solicitud: </span>
						<span className="form-text">{datos.numero}</span>
					</div>
					<div className="form-group">
						<span className="label-text">Fecha de solicitud: </span>
						<span className="form-text ">{datos.fecha}</span>
					</div>
					<div className="form-group">
						<span className="label-text">RUT: </span>
						<span className="form-text ">{datos.rutSolicitante}</span>
					</div>
					<div className="form-group">
						<span className="label-text">Estado de solicitud: </span>
						<span className="form-text ">{datos.estadoSolicitud}</span>
					</div>
					<div className="form-group">
						<span className="label-text">Fecha de pago: </span>
						<span className="form-text">Pendiente</span>
					</div>
					<div className="row">
						<div className="col justify-content-center d-flex">
							{isLoading ? (
								<>
									<button
										type="button"
										className="btn-custom btn-lg btn-custom-primary"
										disabled
									>
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
									</button>
								</>
							) : (
								<button
									type="button"
									className="btn-custom btn-lg btn-custom-primary"
									onClick={printDocument}
								>
									Descargar comprobante
								</button>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center d-flex">
							<button
								className="btn-custom btn-lg btn-custom-default"
								type="button"
								onClick={() => {
									window.location.href = "/";
								}}
							>
								Volver al inicio
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
export default FormComprobanteEstadoSolicitud;
