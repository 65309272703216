import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../assets/styles/style.css";
const PaetModal = (props) => {
  return (
    <div>
      <Modal
        className="modalStyle"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className="titleModal">
            Información importante para realizar tu solicitud:
          </div>
        </Modal.Header>
        <Modal.Body>
          <span className="parrafosModal">
            <strong> 1.</strong> Al suscribir el citado formulario está
            iniciando su trámite para certificar su calidad de enfermo terminal,
            el que podrá ser apelado ante el Consejo Médico de apelación.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 2.</strong> Si se encuentra tramitando una pensión de vejez
            o vejez anticipada,{" "}
            <strong>dicho trámite se deberá suspender </strong>, para proceder
            con el trámite de pensión para enfermo terminal. En caso de ser
            rechazada su certificación de enfermo terminal, la solicitud de
            pensión de vejez o vejez anticipada se reanudará.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 3.</strong> En caso de no estar pensionado y estar cubierto
            por el SIS o para acceder a beneficios complementarios, se
            solicitará su calificación de invalidez.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 4. </strong> La evaluación o reevaluación de invalidez que
            este en trámite, continuará aun cuando se hubiese negado la
            certificación de enfermo terminal por parte del consejo Médico de la
            Superintendencia.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 5. </strong> El objetivo de la solicitud de pensión de
            invalidez es que, en caso de aprobarse, se realice el pago del
            aporte adicional por parte de las Compañías de seguros de vida
            correspondientes o que se pueda acceder a otras prestaciones como
            pensionado por invalidez, como la liquidación del Bono de
            Reconocimiento o el acceso al pilar solidario.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 6. </strong> El detalle del trámite completo de la
            certificación y de la calificación de invalidez en carácter
            prioritario, y sus posibles resultados.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 7. </strong> Si el dictamen del Consejo Médico declara su
            calidad de enfermo terminal tendrá derecho a percibir{" "}
            <strong> una renta temporal por un periodo de doce meses </strong>,
            la que se calculará dejando una reserva para pagar pensiones de
            sobrevivencia y la cuota mortuoria y, si cuenta con fondos
            suficientes, a retirar excedente de libre disposición, lo que
            eventualmente podrá agotar el saldo de la cuenta obligatoria.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong>8. </strong> En ningún caso, una vez certificado como
            enfermo terminal, podrá optar por una pensión bajo la modalidad de
            renta vitalicia.
          </span>
          <br />
          <br />
          <span className="parrafosModal">
            <strong> 9. </strong>Los antecedentes entregados serán
            evaluados por los Médicos del Consejo Médico de la Superintendencia
            y, además, si corresponde, para la calificación de la invalidez, por
            integrantes de la Comisión Medica Regional aun cuando podrán no ser
            determinantes por si solos y formaran parte del correspondiente
            expediente de invalidez y de certificación de enfermo terminal.
          </span>
          <br />
          <br />
          <span>
            <strong>10. </strong> La solicitud presentada debe complementarse
            con <strong> el certificado Médico </strong> exigido por Ley para
            estos efectos,{" "}
            <strong>
              {" "}
              firmado por el Médico tratante y el director Médico{" "}
            </strong>{" "}
            o su equivalente de la institución de salud pública o privada en la
            que se ha tratado o se está tratando.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <Button
              className="btn-modal btn-custom btn-lg btn-custom-primary"
              onClick={function () {
                window.location = "/index";
              }}
            >
              Comenzar solicitud PAET
            </Button>
          </div>
        </Modal.Footer>
        <div className="col justify-content-center d-flex blue-a ">
          <a href="/"> Volver</a>
        </div>
        <div className="space"></div>
      </Modal>
    </div>
  );
};
export default PaetModal;
