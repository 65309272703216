export const initialValuesFormFormaPago = {
    institucionP: "",
    tipoCuentaP: "",
    numeroCuentaP: "",
    radioButtonP:"",
    rutP:"",
    institucionS: "",
    tipoCuentaS: "",
    numeroCuentaS: "",
    radioButtonS:"",
    rutS:"",
};