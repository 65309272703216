import Swal from "sweetalert2";

const MySwal = Swal.mixin({
	customClass: {
		cancelButton: "btn btn-cerrar",
		confirmButton: "btn-custom btn-lg btn-custom-primary",
		title: "font-weight-bold text-left",
		footer: "swal-footer",
	},
	allowOutsideClick: false,
	buttonsStyling: false,
});

export const handleSubmit = async ({ changeLoading }) => {
	changeLoading(true);

	MySwal.fire({
		title: "Enviar solicitud",
		icon: "warning",
		text: "Los nuevos documentos serán enviados, para ser revisados por la AFP. Si necesitas revisarlos puedes volver atrás.",
		showCancelButton: true,
		confirmButtonColor: "#72B500",
		cancelButtonColor: "#d33",
		confirmButtonText: "Confirmar",
		cancelButtonText: "Volver al paso anterior",
		allowOutsideClick: false,
	}).then((result) => {
		changeLoading(false);
		if (result.isConfirmed) {
			window.location = "doc/comprobante";
		}
	});
};
