import React from "react";
import { Modal, Button } from "react-bootstrap";
import documento from "../assets/img/NumeroDocumento.png";

/* eslint react/prop-types: 0 */
export default function claveSeguridadModal(props) {
    return (
        <div>
            <Modal 
                {...props}
                dialogClassName="modal-50w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ¿Cúal es mi número de documento?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>El <span className="font-weight-bold">“Número de documento”</span> o <span className="font-weight-bold">“Número de Serie”</span> corresponden a <span className="font-weight-bold">números únicos</span>.</p>
                    <p>Estos se encuentran impresos en el costado derecho en cédulas nuevas y en el costado izquierdo de la parte posterior de las cédulas antiguas.</p>
                    <img
                        className="icon documento d-flex mx-auto"
                        src={documento}
                        alt="documento"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="" onClick={props.onHide}>Volver atrás</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}