import React from "react";
import { Helmet } from "react-helmet";
import FormIndex from "../../components/eld/FormIndex";

export default function loginELD() {
    return (
        <div className="container home">
            <Helmet>
                <title>Retiro Enfermos Terminales AFP Modelo | Formulario Selección Modalidad con Excedente de Libre disposición (ELD) | AFP Modelo</title>
                <meta name="description" content={``} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="row">
                            <div className="bloque form">
                                <div className="card formulario">
                                    <div className="card-title textoTitulo">
                                        Formulario Selección Modalidad con Excedente de Libre disposición (ELD)
                                    </div>
                                    <span className="textoSecundario">
                                        Haz tu selección aquí
                                    </span>
                                    <div className="card-body">
                                        <FormIndex />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}