import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const rutMask = (rut) => {
    let formateado = rut.replace(/\./g, "").replace("-", "");
    
    let Mask = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\w/];
    
    if (formateado.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
        Mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\w/];
    } else if (formateado.match(/^(\d)(\d{3}){2}(\w{1})$/)) {        
        Mask = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\w/];
    } else if (formateado.match(/^(\d)(\d{3}){2}$/)) {        
        Mask = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
    } else if (formateado.match(/^(\d)(\d{3})(\d{2})$/)) {        
        Mask = [/\d/, '.', /\d/, /\d/, /\d/,'.', /\d/, /\d/];
    } else if (formateado.match(/^(\d)(\d{3})(\d{1})$/)) {        
        Mask = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/];
    } else if (formateado.match(/^(\d)(\d{3})$/)) {        
        Mask = [/\d/, '.', /\d/, /\d/, /\d/];
    } else if (formateado.match(/^(\d)(\d{2})$/)) {
        Mask = [/\d/, '.', /\d/, /\d/];
    } else if (formateado.match(/^(\d)(\d{1})$/)) {
        Mask = [/\d/, '.', /\d/];
    } else if (formateado.match(/^(\d)$/)) {        
        Mask = [/\d/];
    }

    return Mask;
};

export const dineroMask = createNumberMask({
    prefix: '$',
    thousandsSeparatorSymbol: '.',
});

export const fechaMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const celularMask = ['(', '+', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
export const celularMaskNew = ['(', '+', '5', '6', ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
