import Swal from "sweetalert2";

const MySwal = Swal.mixin({
	customClass: {
		cancelButton: "btn btn-cerrar",
		confirmButton: "btn-custom btn-lg btn-custom-primary",
		title: "font-weight-bold text-left",
		footer: "swal-footer",
	},
	allowOutsideClick: false,
	buttonsStyling: false,
});

export const handleSubmit = async ({ changeLoading }) => {
	changeLoading(true);

	MySwal.fire({
		title: "Enviar solicitud con la modalidad de pago seleccionda.",
		icon: "warning",
		text: "Si tienes dudas sobre tu elección puedes volver atrás. De lo contrario, continua.",
		showCancelButton: true,
		confirmButtonColor: "#72B500",
		cancelButtonColor: "#d33",
		confirmButtonText: "Si, enviar.",
		cancelButtonText: "No, volver.",
		allowOutsideClick: false,
		reverseButtons: true,
	}).then((result) => {
		changeLoading(false);
		if (result.isConfirmed) {
			window.location = "eld/comprobante";
		}
	});
};
