import React from "react";

const ButtonSubmit = (props) => {

    return (
        <button
            type="submit"
            id={props.id_button}
            className="btn-custom btn-lg btn-custom-primary"
            disabled={!(props.formik.isValid && props.formik.dirty)}>
            Continuar
        </button>
    );
};

export default ButtonSubmit;