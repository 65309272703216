import { genericGet } from "../../../utils/genericApiCall";

export async function obtenerParametrosReposo() {
    const url_endpoint = '/ParReposo/ObtenerTodo';
    const message_info = 'Obteniendo parametros de reposo...';
    const message_error = 'Error al obtener datos de parametros de reposo...';

    return await genericGet({
        url_endpoint: url_endpoint, 
        message_info: message_info, 
        message_error: message_error
    });

}