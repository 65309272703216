import * as Yup from "yup";
import { formatearRut, rutValidador } from "./validationRut";
import { aacento, eacento, iacento, oacento, uacento } from "./caracteresUTF8";
import { validDate, validDateCeseFunciones, validDateFinLicencia } from "./date";
import { sueldoFormateador } from "../utils/helpers";

export const validarFormSolicitud = Yup.object({
    solicitud: Yup.string()
        .required('Ingresa el número de solicitud de tu comprobante')
        .min(10, "Debes ingresar al menos 10 numeros"),
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido.`,
            function (value) {
                return rutValidador(value);
            }),
})

export const validarRutEjecutivo = Yup.object({
    rutEjecutivo: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido.`,
            function (value) {
                return rutValidador(value);
            }),
    idTipoIngresoCanal: Yup
        .string()
        .required()
})

export const validationSchemaLogin = Yup.object({
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido.`,
            function (value) {
                return rutValidador(value);
            }),
    numeroserie: Yup
        .string().uppercase()
        .matches(
            /^[Aa0-9][0-9]{8,10}$/,
            ` Ingresa un numero de serie v${aacento}lido.`)
        .required(`Ingrese el n${uacento}mero de serie que aparece en su c${eacento}dula de identidad.`),
    numeroserieCompare: Yup
        .string()
        .uppercase()
        .matches(
            /^[Aa0-9][0-9]{8,10}$/,
            ` Ingresa un numero de serie v${aacento}lido.`)
        .required(`Ingrese el n${uacento}mero de serie que aparece en su c${eacento}dula de identidad.`)
        .oneOf([Yup.ref("numeroserie")], `El n${uacento}mero ingresado debe coincidir con el campo anterior`),
    mandatario: Yup
        .boolean(),
    beneficiario: Yup
        .boolean(),
    ejecutivoAfp: Yup
        .boolean(),
});

export const validationSchemaLoginELD = Yup.object({
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido.`,
            function (value) {
                return rutValidador(value);
            }),
    numeroSolicitud: Yup
        .string()
        .matches(/^[0-9]{11,15}$/, `Ingresa un n${uacento}mero de solicitud v${aacento}lido.`)
        .required(`Ingresa un n${uacento}mero de solicitud v${aacento}lido.`)
});

export const validationSchemaModalidadELD = Yup.object({
    radioButton: Yup
        .string(),
    montoLibreDisposicion: Yup
        .string()
        .when('radioButton', {
            is: "4",
            then: Yup
                .string()
                .required(`Por favor indique monto.`)
                .transform(value => value.replace(/[^\d]/g, ''))
                .test('ELDMaximo', `El monto no debe superar al excedente máximo disponible de ${sueldoFormateador(localStorage.getItem("ELDMaximo"))}.`,
                    function (value) {
                        
                        if (parseInt(value) > parseInt(localStorage.getItem("ELDMaximo"))) {
                            return false;
                        }
                        return true;
                    }),
            otherwise: Yup.string().default(''),
        }),
    montoRentaTemporal: Yup
        .string()
        .when('radioButton', {
            is: "5",
            then: Yup
                .string()
                .required(`Por favor indique monto.`)
                .transform(value => value.replace(/[^\d]/g, ''))
                .test('RTMaximo', `El monto no debe superar tu renta temporal máxima de ${sueldoFormateador(localStorage.getItem("RTMaximo"))}.`,
                    function (value) {
                        let val = parseInt(value);
                        let RTMaximo = parseInt(localStorage.getItem("RTMaximo"));

                        if (val > RTMaximo) {
                            return false;
                        }
                        return true;
                    })
                .test('menorPBS', `El monto no debe ser menor al PBS Vigente de $169.649.`,
                    function (value) {
                        if (parseInt(value) < parseInt(localStorage.getItem("PBS"))) {
                            return false;
                        }
                        return true;
                    }),
            otherwise: Yup.string().default('')
        })
});

export const validationSchema = Yup.object({
    nombre: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Ingresa tu nombre sin n${uacento}meros ni caracteres.`)
        .required('Ingresa al menos un nombre y apellido.'),
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
            function (value) {
                let rut = value;
                if (value && value.length === 9) {
                    rut = formatearRut(value)
                }
                return rutValidador(rut);
            }),
    correo: Yup
        .string()
        .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
        .required('Por favor ingrese su correo.'),
    celular: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[9]{1}[0-9]{8}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),
    sueldo: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{6,8}$/, `Ingrese un sueldo l${iacento}quido entre $100.000 y $10.000.000.`)
        .test('Sueldo-validacion', `Ingrese un sueldo l${iacento}quido entre $100.000 y $10.000.000.`, function (value) {
            //let amount = formik.values.sueldo;
            return (value >= 100000 && value <= 10000000)
        })
        .required('Por favor ingrese su sueldo l�quido.'),
    afp: Yup
        .string()
        .notOneOf(['99', '-1'], `Debe seleccionar una AFP.`),
    terminosycondiciones: Yup
        .boolean()
        .oneOf([true], `Debe aceptar las condiciones del servicio y la pol${iacento}tica de privacidad.`)
});

export const validationSchemaMandatario = Yup.object({
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
            function (value) {
                
                let rut = value;
                
                if (value && value.length === 9) {
                    rut = formatearRut(value)
                }
                return rutValidador(rut);
            }),
    numeroserie: Yup
        .string()
        .matches(
            /^[Aa0-9][0-9]{8,10}$/,
            ` Ingresa un numero de serie v${aacento}lido.`)
        .required(`Ingrese el n${uacento}mero de serie que aparece en su c${eacento}dula de identidad.`),
    numeroserieCompare: Yup
        .string()
        .matches(
            /^[Aa0-9][0-9]{8,10}$/,
            ` Ingresa un numero de serie v${aacento}lido.`)
        .required(`Ingrese el n${uacento}mero de serie que aparece en su c${eacento}dula de identidad.`)
        .oneOf([Yup.ref("numeroserie")], `El n${uacento}mero ingresado debe coincidir con el campo anterior`),
    nombres: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu nombre sin n${uacento}meros ni caracteres.`)
        .required('Indícanos tu nombre.'),
    apellidoPaterno: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu apellido patarno sin n${uacento}meros ni caracteres.`)
        .required('Indícanos tu apellido paterno.'),
    apellidoMaterno: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu apellido materno sin n${uacento}meros ni caracteres.`)
        .notRequired('Indícanos tu apellido materno.'),
    telefonoPrincipal: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),
    telefonoSecundario: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`),
    correo: Yup
        .string()
        .lowercase()
        .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
        .required('Por favor ingrese su correo.'),
    correoConfirmar: Yup
        .string()
        .lowercase()
        .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
        .required('Por favor ingrese su correo.')
        .test('Correo-validacion', `El correo ingresado debe ser identico al anterior.`, function (value) {
            const correo = this.parent.correo;
            return correo === value;
        })
});

export const validationSchemaCuentas = Yup.object().shape({

    cuentasapv: Yup.array().of(
        Yup.object().shape({
            checkcuentasapv: Yup.boolean(),
            institucionapv: Yup.string()
                .when('checkcuentasapv', {
                    is: true,
                    then: Yup.string().notOneOf(['-1', undefined, ""], `Debe indicar una instituci${oacento}n.`),
                    otherwise: Yup.string().default('')
                }),
            total: Yup
                .string()
                .when('checkcuentasapv', {
                    is: true,
                    then: Yup.string(),
                    otherwise: Yup.string().default('')
                }),
            monto: Yup
                .string()
                .when('checkcuentasapv', {
                    is: true,
                    then: Yup.string()
                        .transform(value => value.replace(/[^\d]/g, ''))
                        .test('Sueldo-validacion', `Por favor ingrese un monto a retirar`, function (value) {
                            if (this.parent.total === "1") {
                                return true;
                            } else return this.parent.total === "2" && value > 0 && value <= 100000000;
                        }),
                    otherwise: Yup.string().default('')
                }),
        })
    ),
    cuentasapv2: Yup.array().of(
        Yup.object().shape({
            checkcuentasapv2: Yup
                .boolean(),
            institucionapv2: Yup
                .string()
                .when('checkcuentasapv2', {
                    is: true,
                    then: Yup.string().notOneOf(['-1', undefined], `Debe indicar una instituci${oacento}n.`),
                    otherwise: Yup.string().default('')
                })
        })
    ),
    depositosconvenidos: Yup.array().of(
        Yup.object().shape({
            checkdepositosconvenido: Yup
                .boolean(),
            institucionconvenidos: Yup
                .string()
                .when('checkdepositosconvenido', {
                    is: true,
                    then: Yup.string().notOneOf(['-1', "", undefined], `Debe indicar una instituci${oacento}n.`),
                    otherwise: Yup.string().default('')
                }) 
        })
    ),
    cuentasapvcolectivo: Yup.array().of(
        Yup.object().shape({
            checkcuentasapvcolectivo: Yup
                .boolean(),
            institucionapvcolectivo: Yup
                .string()
                .when('checkcuentasapvcolectivo', {
                    is: true,
                    then: Yup.string().notOneOf(['-1', "", undefined], `Debe indicar una instituci${oacento}n.`),
                    otherwise: Yup.string().default('')
                })
        })
    ),
    traspasoSaldo: Yup.string().test('validar', 'campos no seleccionados', (value) => {
        return value !== ''
    }).required()
});


export const validationSchemaClaveSeguridad = Yup.object({
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
            function (value) {
                return rutValidador(value);
            }),
    clave: Yup
        .string()
        .required('Por favor ingrese su clave correctamente.')
});

export const validationSchemaClaveError = Yup.object({
    nombre: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Ingresa tu nombre sin n${uacento}meros ni caracteres.`)
        .required('Ingresa al menos un nombre y apellido.'),
    celular: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[5][6][0-9]{9}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),
    correo: Yup
        .string()
        .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
        .required('Por favor ingrese su correo.'),
});

export const validationSchemaDatosAfiliados = Yup.object({
    nombres: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu nombre sin n${uacento}meros ni caracteres.`)
        .required('Debes ingresar al menos un nombre válido.')
        .test('nombres-validacion', `Debes ingresar al menos un nombre válido.`, function (value) {            
            return (value !== "");
        }),
    apellidoPaterno: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu apellido patarno sin n${uacento}meros ni caracteres.`)
        .required('Indícanos tu apellido paterno.'),
    apellidoMaterno: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos tu apellido materno sin n${uacento}meros ni caracteres.`),
    fechaNacimiento: Yup.string()
        .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
            return validDate(value);
        })
        .required('Ingrese la fecha de nacimiento del afiliado.'),
    checkCorreo: Yup
        .boolean()
    ,
    correo: Yup
        .string()
        .when('checkCorreo', {
            is: false,
            then: Yup.string()
                .lowercase()
                .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
                .required('Por favor ingrese su correo.'),
            otherwise: Yup.string().default('')
        }),
    correoConfirmar: Yup
        .string()
        .when('checkCorreo', {
            is: false,
            then: Yup.string()
                .lowercase()
                .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
                .required('Por favor ingrese su correo.')
                .test('Correo-validacion', `El correo ingresado debe ser identico al anterior.`, function (value) {
                    const correo = this.parent.correo;
                    return correo === value;
                }),
            otherwise: Yup.string().default('')
        }),
    direccion: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,70}$/,
            `Indica una direcci${oacento}n v${aacento}lida`)
        .required(`Por favor ingrese su direcci${oacento}n.`),
    numero: Yup
        .string()
        .matches(
            /^[0-9]{1,7}$/,
            `Indica un n${uacento}mero v${aacento}lido`)
        .required(`Por favor ingrese su n${uacento}mero.`),
    depto: Yup
        .string()
        .matches(
            /^[A-Za-z]{0,1}([0-9]{1,7})$/,
            `Indica una direcci${oacento}n v${aacento}lida`),
    villa: Yup
        .string()
        .matches(
            /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
            `Indícanos un nombre de villa.`),

    telefonoPrincipal: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),

    telefonoConfirmar: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .test('telefono-validacion', `El n${uacento}mero telef${oacento}nico ingresado debe coincidir con el anterior.`, function (value) {

            const telefono = this.parent.telefonoPrincipal;
            return telefono === value;
        })
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),
    telefonoSecundario: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`),
    genero: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar un género.`),
    prevision: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar una previsi${oacento}n de Salud.`),
    region: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar una region.`)
        .test('ciudad-valAfilidado', 'Debe seleccionar una region.', function (value) {
            return !isNaN(parseInt(value));
        })
        .required(`Debe seleccionar una region.`),
    ciudad: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar una ciudad.`)
        .test('ciudad-valAfilidado', 'Debe seleccionar una ciudad.', function (value) {
            return !isNaN(parseInt(value));
        })
        .required(`Debe seleccionar una ciudad.`),
    comuna: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar una comuna.`)
        .test('ciudad-valAfilidado', 'Debe seleccionar una comuna.', function (value) {
            return !isNaN(parseInt(value));
        })
        .required(`Debe seleccionar una comuna.`),
    lugarReposo: Yup
        .string()
        .notOneOf(['99'], `Debe indicar su lugar actual de reposo.`)
        .required('Debe indicar su lugar actual de reposo.'),
    recintoHospitalario: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "2",
            then: Yup
                .string()
                .matches(/^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,200}$/,
                    `Indica una direcci${oacento}n v${aacento}lida`).required(`Por favor ingrese un recinto Hospitalario.`),
            otherwise: Yup.string().default('')
        }),
    direccionReposo: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .matches(
                    /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,70}$/,
                    `Indica una direcci${oacento}n v${aacento}lida`)
                .required(`Por favor ingrese su direcci${oacento}n.`),
            otherwise: Yup.string().default(''),
        }),
    numeroReposo: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .matches(
                    /^[0-9]{1,10}$/,
                    `Indica un n${uacento}mero v${aacento}lido`)
                .required(`Por favor ingrese su n${uacento}mero.`),
            otherwise: Yup.string().default(''),
        }),
    deptoReposo: Yup
        .string()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .matches(
                    /^[A-Za-z]{0,1}([0-9]{1,10})$/,
                    `Indica una direcci${oacento}n v${aacento}lida`),
            otherwise: Yup.string().default(''),
        }),
    villaReposo: Yup
        .string()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .matches(
                    /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/,
                    `Indícanos un nombre de villa.`),
            otherwise: Yup.string().default(''),
        }),

    regionReposo: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .notOneOf(['99'], `Debe seleccionar una region.`)
                .test('ciudad-valAfilidado', 'Debe seleccionar una region.', function (value) {
                    return !isNaN(parseInt(value));
                })
                .required(`Debe seleccionar una region.`),
            otherwise: Yup.string().default('99'),
        }),
    ciudadReposo: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .notOneOf(['99'], `Debe seleccionar una ciudad.`)
                .test('ciudad-valAfilidado', 'Debe seleccionar una ciudad.', function (value) {
                    return !isNaN(parseInt(value));
                })
                .required(`Debe seleccionar una ciudad.`),
            otherwise: Yup.string().default('99'),
        }),


    comunaReposo: Yup
        .string()
        .ensure()
        .when("lugarReposo", {
            is: "3",
            then: Yup
                .string()
                .notOneOf(['99'], `Debe seleccionar una comuna.`)
                .test('ciudad-valAfilidado', 'Debe seleccionar una comuna.', function (value) {
                    return !isNaN(parseInt(value));
                })
                .required(`Debe seleccionar una comuna.`),
            otherwise: Yup.string().default('99'),
        }),

    nivelEducacional: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar su nivel educacional.`)
        .required(`Debe seleccionar su nivel educacional.`),
    profesion: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar como realiza su profesión.`)
        .required(`Debe seleccionar como realiza su profesión.`),
    antecedentesLaborales: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar su situacion laboral.`)
        .required(`Debe seleccionar su situacion laboral.`),
    fechaInicioDesempleoPrivado: Yup
        .string()
        .when(['profesion', 'antecedentesLaborales'], {
            is: (profesion, antecedentesLaborales) => {
                return (profesion === '2' || profesion === '3') && antecedentesLaborales === '2'
            },
            then: Yup
                .string()
                .test('fechaDistintaDefault', `Ingrese una fecha valida`, function (value) {
                    return value !== '01/01/2000'
                })
                .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
                    return validDate(value);
                }),
            otherwise: Yup.string().default('01/01/2000'),
        }),
    fechaInicioDesempleoPublico: Yup
        .string()
        .when(['profesion', 'antecedentesLaborales'], {
            is: (profesion) => {
                return (profesion === '4' || profesion === '5');
            },
            then: Yup
                .string()
                .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
                    return value === '01/01/2000' || validDateCeseFunciones(value);
                })
                .required('Ingrese la fecha en que inicio su periodo de cese de funciones.'),
            otherwise: Yup.string().default('01/01/1900'),
        }),

    fechaInicioLicenciaMedica: Yup
        .string()
        .when('antecedentesLaborales', {
            is: "3",
            then: Yup
                .string()
                .ensure()
                .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
                    return validDate(value);
                })
                .required('Ingrese la fecha en que inicio su licencia medica.'),
            otherwise: Yup.string().default('01/01/1900'),
        }),

    fechaFinLicenciaMedica: Yup
        .string()
        .when('antecedentesLaborales', {
            is: "3",
            then: Yup
                .string()
                .ensure()
                .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
                    let fecha_inicio_licencia = this.parent.fechaInicioLicenciaMedica;
                    return validDateFinLicencia(fecha_inicio_licencia, value);
                })
                .required('Ingrese la fecha en que finaliza su licencia medica.'),
            otherwise: Yup.string().default('01/01/1900'),
        }),
    afiliadoPensionado: Yup
        .string()
        .notOneOf(['99'], `Debe seleccionar si se encuentra pensionado o activo laboralmente.`)
        .required(`Debe seleccionar si se encuentra pensionado o activo laboralmente.`),


});

export const validationSchemaDocumentosAfiliados = Yup.object({
    documentoUCP: Yup
        .string(),
    documentoACM: Yup
        .string(),
    documentoGES: Yup
        .string(),
});

export const validationSchemaBancaria = Yup.object({
    radioButton: Yup
        .string(),
    rutP: Yup
        .string()
        .lowercase()
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string().required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        })
        .when("radioButtonP", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        })
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
            function (value) {
                if (value) {
                    return rutValidador(value);
                }
                return true;
            }),
    institucionP: Yup
        .string()
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string()
                .test(`instucion-valida`, `Por favor seleccione una Entidad Financiera`,
                    function (value) {
                        return value !== 99;
                    })
                .required(`Seleccione una entidad Financiera`),
            otherwise: Yup.string().default('99')
        }),
    tipoCuentaP: Yup
        .string()
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string().required(`Seleccione una tipo de cuenta`)
        })
        .when("radioButtonP", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        })
        .test('tipo-validacion',
            `Por favor seleccione un tipo de cuenta.`,
            function (value) {
                return value !== 99;
            }),
    numeroCuentaP: Yup
        .string()
        .matches(
            /^\d+?$/,
            ` Ingresa un numero de cuenta v${aacento}lido.`)
        .notRequired(`Ingresa un numero de cuenta v${aacento}lido.`)
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string().required(`Ingresa un numero de cuenta v${aacento}lido.`)
        })
        .when("radioButtonP", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        }),
    numeroCuentaPConfirmar: Yup
        .string()
        .matches(
            /^\d+?$/,
            ` Ingresa un numero de cuenta v${aacento}lido.`)
        .notRequired(`Ingresa un numero de cuenta v${aacento}lido.`)
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string()
                .required(`Ingresa un numero de cuenta v${aacento}lido.`)
                .oneOf([Yup.ref("numeroCuentaP")], `Debes ingresar un n${uacento}mero de la cuenta bancaria.`),
        })
        .when("radioButtonP", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        }),
    radioButtonS: Yup
        .string()
        .when("radioButtonP", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        })
        .when("radioButtonP", {
            is: (val) => val === "0",
            then: Yup.string().required()
        }),

    rutS: Yup
        .string()
        .lowercase()
        .when("radioButtonS", {
            is: (val) => val === "0",
            then: Yup
                .string()
                .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        })
        .when("radioButtonS", {
            is: (val) => val === "1",
            then: Yup.string().notRequired()
        })
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
            function (value) {
                if (value) {
                    return rutValidador(value);
                }
                return true;
            }),
    institucionS: Yup
        .string()
        .when("radioButtonS", {
            is: (val) => val === "0",
            then: Yup.string()
                .test(`instucion-valida`, `Por favor seleccione una Entidad Financiera`,
                    function (value) {
                        return value !== 99;
                    })
                .required(`Seleccione una entidad Financiera`),
            otherwise: Yup.string().default('')
        }),
    tipoCuentaS: Yup
        .string()
        .when("radioButtonS", {
            is: (val) => val === "0",
            then: Yup.string().required(`Seleccione una tipo de cuenta`)
                .test('tipo-validacion',
                    `Por favor seleccione un tipo de cuenta.`,
                    function (value) {
                        return value !== 99;
                    }),
            otherwise: Yup.string().default('')
        })
        .when("radioButtonS", {
            is: (val) => val === "1",
            then: Yup.string().default('')
        }),
    numeroCuentaS: Yup
        .string()
        .when("radioButtonS", {
            is: (val) => val === "1",
            then: Yup.string()
                .notRequired()
        })
        .matches(
            /^\d+?$/,
            ` Ingresa un numero de cuenta v${aacento}lido.`)
        .notRequired(`Ingresa un numero de cuenta v${aacento}lido.`)
        .when('radioButtonS', {
            is: (val) => val === "0",
            then: Yup
                .string()
                .required(`Ingresa un numero de cuenta v${aacento}lido.`)
        }),
    numeroCuentaSConfirmar: Yup
        .string()
        .matches(
            /^\d+?$/,
            ` Ingresa un numero de cuenta v${aacento}lido.`)
        .notRequired(`Ingresa un numero de cuenta v${aacento}lido.`)
        .when('radioButtonS', {
            is: (val) => val === "0",
            then: Yup
                .string()
                .required(`Ingresa un numero de cuenta v${aacento}lido.`)
                .oneOf([Yup.ref("numeroCuentaS")], `El n${uacento}mero ingresado debe coincidir con el campo anterior.`),
        })
        .when('radioButtonS', {
            is: (val) => val === "1",
            then: Yup
                .string()
                .notRequired()
        }),
    // cuentaVista: Yup
    //     .boolean()
    //     .notRequired(""),
});

export const validationSchemaBeneficiarios = Yup.object().shape({
    checkBeneficiario: Yup
        .boolean(),
    beneficiarios: Yup.array().of(
        Yup.object().shape({
            rut: Yup
                .string()
                .lowercase()
                .ensure()
                .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
                .test('Rut-validacion',
                    `Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`,
                    function (value) {
                        if (this.from[1].value.checkBeneficiario === true) {
                            return true
                        } else {
                            let rut = value;
                            if (value && value.length === 9) {
                                rut = formatearRut(value)
                            }
                            return rutValidador(rut);
                        }

                    }),
            nombres: Yup
                .string()
                .test('nombresBen-matches', `Ingresa el nombre del beneficiario sin n${uacento}meros ni caracteres especiales.`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {
                        return /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/.test(value);
                    }
                })
                .required('Ingresa el nombre del beneficiario.'),
            apellidoPaterno: Yup
                .string()
                .test('apellidoPatBen-matches', `Ingresa el apellido paterno del beneficiario sin n${uacento}meros ni caracteres especiales.`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {                        
                        return /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/.test(value);
                    }
                })
                .required('Ingresa el apellido paterno del beneficiario.'),
            apellidoMaterno: Yup
                .string()
                .test('apellidoMatBen-matches', `Ingresa el apellido materno del beneficiario sin n${uacento}meros ni caracteres especiales.`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {
                        return /^[A-Za-z \u00C1\u00E1\u00C9\u00E9\u00CD\u00ED\u00D3\u00F3\u00DA\u00FA\u00DC\u00FC\u00F1\u00D1]{3,60}$/.test(value);
                    }
                })
                .notRequired(),
            telefonoContacto: Yup
                .string()
                .transform(value => value.replace(/[^\d]/g, ''))
                .matches(/^[0-9]{11}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
                .required("Ingresa un teléfono de contacto de 9 dígitos."),

            correoElectronico: Yup
                .string()
                .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
                .required('Por favor ingrese su correo.'),
            parentesco: Yup
                .string()
                .test('parentescoBen-matches', `Debe indicar un parentesco.`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {
                        return value !== '-1';
                    }
                })
                .required('Debe indicar un parentesco.'),
            hijoInvalido: Yup
                .boolean()
                .notRequired()
            ,
            fechaNacimiento: Yup.string()
                .ensure()
                .test('fechaNacBen-matches', `Ingrese una fecha valida`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {
                        return validDate(value);
                    }
                })
                .required('Ingrese la fecha de nacimiento del beneficiario.'),

            genero: Yup
                .string()
                .test('generoBen-matches', `Debe indicar un genero`, function (value) {
                    if (this.from[1].value.checkBeneficiario === true) {
                        return true
                    } else {
                        return value !== '99';
                    }
                })
                .required('Debe indicar un genero.'),
            idBeneficiario: Yup.string().notRequired()
        })
    )
});

export const validationSchemaPaet = Yup.object({
    rut: Yup
        .string()
        .lowercase()
        .required(`Por favor ingrese un RUT v${aacento}lido sin puntos y con gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido.`,
            function (value) {
                return rutValidador(value);
            }),
    numerosolicitud: Yup
        .string()
        .required(`Ingrese el n${uacento}mero de solicitud.`)
        .matches(/^[Aa0-9][0-9]{8,10}$/, ` Ingresa el n${uacento}mero de solicitud.`),
});